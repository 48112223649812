import React, { useRef, useCallback, useState, useEffect } from 'react';
import { AirbnbRating } from 'react-native-elements';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ScrollView } from 'react-native';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import useBackKey from '../../hooks/backKey';
import Input from '../../components/Input';
import FabButton from '../../components/FabButton';
import Loading from '../../components/Loading';
import { AlertaSimples } from '../../components/Alert';

import api from '../../services/apiNossaDefensoria';
import getValidationErrors from '../../utils/getValidationErrors';
import { getMessageErroSistemaCustom } from '../../utils/getShowMessage';

import { maskPhone } from '../../utils/masks';
import { CAMPO_OBRIGATORIO } from '../../constants/Validation';
import { Background, TextContainer } from '../../styles/globals';
import { ContainerRating, ObservacoesContainer, ObservacoesTexto } from './styles';

interface formData {
  nome: string;
  telefone: string;
  email: string;
  mensagem: string;
}

const Feedback: React.FC = () => {
  const route = useRoute();
  const { goBack } = useNavigation();
  const formRef = useRef<FormHandles>(null);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ showAlert, setShowAlert ] = useState<boolean>(false);
  const [ nota, setNota ] = useState<number>(3);
  const [ localParam, setLocalParam ] = useState<number>(1);

  useBackKey(goBack);

  useEffect(() => {
    if(route.params) {
      const { local } = route.params;
      if(local !== undefined) {
        setLocalParam(local);
      }
    }
  }, [route])

  const handleForm = useCallback(
    async (data: formData) => {

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          mensagem: Yup.string().required(CAMPO_OBRIGATORIO)
        });

        await schema.validate(data, { abortEarly: false });
        const params = {
          ...data,
          local:localParam,
          nota: nota
        }

        console.log(params)

        setIsLoading(true);
        await api.post('/app-assistido/api/feedback/', params)
          .then(() => setShowAlert(true))
          .catch((err) => {
            if(err?.response?.status === 500) {
              getMessageErroSistemaCustom('Erro!', 'Não foi possivel enviar o feedback. Tente novamente')
            }
          })
          .finally(() => setIsLoading(false))

      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    }, [nota, localParam]);

  const aplicarMascara = (campo:string, texto:string) => {
    formRef.current?.setFieldValue(campo, texto);
  };

  return (
    <Background>
      <ScrollView>
        <TextContainer>  
          <Form ref={formRef} onSubmit={handleForm}>
            <ContainerRating>
              <AirbnbRating
                count={5}
                reviews={["Péssimo", "Ruim", "Regular", "Bom", "Ótimo"]}
                defaultRating={3}
                reviewColor="#292929"
                selectedColor="#f1cc8c"
                size={30}
                onFinishRating={(rating) =>  setNota(rating)}
              />
            </ContainerRating>
            <Input
              name="mensagem"
              placeholder="Mensagem*"
              isTextArea
              numberOfLines={7}
              multiline={true}
            />
            <ObservacoesContainer>
              <ObservacoesTexto>
                Foi muito bom atender você! Avalie os nossos serviços para que possamos sempre melhorar.   
              </ObservacoesTexto>
              <ObservacoesTexto>
                Sua identificação não é obrigatória, mas pode nos ajudar caso tenhamos dúvidas sobre sua sugestão.
                Obrigado pela confiança.
              </ObservacoesTexto>
            </ObservacoesContainer>
            <Input
              returnKeyType="next"
              autoCorrect={false}
              autoCapitalize="none"
              name="nome"
              placeholder="Nome Completo"
              maxLength={200}
            />
            <Input
              returnKeyType="next"
              autoCorrect={false}
              autoCapitalize="none"
              name="telefone"
              keyboardType='number-pad'
              placeholder="Telefone"
              maxLength={14}
              onChangeText={(text) => aplicarMascara('telefone', maskPhone(text))}
            />
            <Input
              returnKeyType="next"
              autoCorrect={false}
              autoCapitalize="none"
              name="email"
              placeholder="Email"
              onChangeText={(text) => aplicarMascara('email', text.trim())}
            />
           
          </Form>
        </TextContainer>
      </ScrollView>
      <FabButton iconName='check' onPress={() => formRef.current?.submitForm()} />
      <Loading show={isLoading}/>
      <AlertaSimples
        show={showAlert}
        titulo='Feedback'
        mensagem='Recebemos o seu feedback. Obrigado!'
        onConfirm={() => {
          setShowAlert(false);
          goBack();
        }}
      />
    </Background>
  );
}

export default Feedback;