import styled from 'styled-components/native';
import { RectButton } from 'react-native-gesture-handler';
import Theme from '../../../../constants/Theme';
import DataCommon from '../../../../constants/DataCommon';

interface Props {
  acao: number;
}

interface Arquivo {
  arquivo: string;
  link: string;
  id:number;
}

const obterFundo = (acao:number) => {
  const cor = {
    [DataCommon.ACAO_DOCUMENTO.SOLICITAR_AO_ASSITIDO]: Theme.COLORS.BG_CARD_CINZA,
    [DataCommon.ACAO_DOCUMENTO.ANEXADO_PELO_ASSISTIDO]: Theme.COLORS.BG_CARD_DOURADO,
    [DataCommon.ACAO_DOCUMENTO.ANEXADO_PELA_DPGE]: Theme.COLORS.BG_CARD_VERDE,
    [DataCommon.ACAO_DOCUMENTO.APROVADO]: Theme.COLORS.BG_CARD_VERDE,
    [DataCommon.ACAO_DOCUMENTO.REPROVADO]: Theme.COLORS.BG_CARD_VERMELHO,
  }
  return cor[acao] || cor[1]
}

export const Container = styled.View<Props>`
  min-height: 160px;
  margin: 7px 0px;
  border-radius: 10px;
  background-color: ${props => obterFundo(props.acao)};
  padding: 15px;
  justify-content: space-between;
`;

export const Cabecalho = styled.Text`
  font-family: 'roboto-bold';
  font-size: 16px;
  color: ${Theme.COLORS.TXT_LEITURA_BRANCO};
`;

export const Titulo = styled.Text`
  font-family: 'roboto-regular';
  font-size: 12px;
  color: ${Theme.COLORS.TXT_LEITURA_BRANCO};
`;

export const TipoDocumento = styled.Text`
`;

export const Topo = styled.View`
`;

export const Rodape = styled.View`
`;

export const ContainerAcao = styled.View`
  justify-content: center;
  align-items: flex-end;
  flex: 1;
`;
export const ViewTextoTopo = styled.View`
  margin-bottom: 5px;
`;

export const TextoAcao = styled.Text<Props>`
  font-family: 'roboto-bold';
  font-size: 14px;
  color: ${props => props.acao === DataCommon.ACAO_DOCUMENTO.SOLICITAR_AO_ASSITIDO ? Theme.COLORS.TXT_LEITURA_DOURADO : Theme.COLORS.TXT_LEITURA_BRANCO};
`;

export const LabelAcao = styled.Text`
  font-family: 'roboto-bold';
  font-size: 14px;
  color: ${Theme.COLORS.TXT_LEITURA_BRANCO};
`;

export const Botao = styled(RectButton)`
  width: 130px;
  height: 40px;
  background: ${Theme.COLORS.BG_BTN_DEFAULT};
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`;

export const ContainerBotoes = styled.View`
  align-items: flex-end;
  flex-direction: row;
`;

export const BlocoBotao = styled.View`
  align-items:center;
  margin-right: 5px;
`;

export const BotaoTexto = styled.Text`
  font-family: 'roboto-regular';
  color: ${Theme.COLORS.TXT_BTN_HOME};
  font-size: 14px;
`;

export const TextoTipos = styled.Text`
  font-family: 'roboto-thin';
  color: ${Theme.COLORS.TXT_BTN_HOME};
  font-size: 10px;
`;