import React, { useEffect, useCallback, useRef } from 'react';
import Autocomplete from 'react-native-autocomplete-input';
import { TextInputProps, StyleSheet } from 'react-native';
import { useField } from '@unform/core';

import Theme from '../../constants/Theme';
import { Container, MessageErro, Footer } from './styles';

interface InputProps extends TextInputProps {
  name: string;
  rawValue?: any;
  data: Array<any>;
  fundoEscuro?: boolean;
  indexPosition?: number;
  renderListItem: Function;
};

interface InputValueReference {
  value: string;
};

const AutoCompleteInput: React.FC<InputProps> = ({
  name,
  data,
  onChangeText,
  rawValue,
  renderListItem,
  indexPosition,
  fundoEscuro,
  ...rest
}) => {
  const { registerField, defaultValue = '', fieldName, error } = useField(name);

  const inputElementRef = useRef<any>(null);

  const inputValueRef = useRef<InputValueReference>({ value: defaultValue });

  const handleOnChange = useCallback(
    text => {
      if (inputValueRef.current) inputValueRef.current.value = text;
      if (onChangeText) onChangeText(text);
    },
    [onChangeText],
  );

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputValueRef.current,

      setValue(ref: any, value) {
        inputValueRef.current.value = value;
      },
      getValue(ref) {
        return rawValue || ref.value;
      },
      clearValue() {
        inputValueRef.current.value = '';
        inputElementRef.current.clear();
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Container index={indexPosition} fundoEscuro={fundoEscuro} >
          <Autocomplete
            ref={inputElementRef}
            style={styles.autoCompleteInput}
            inputContainerStyle={{ borderWidth: 0, }}
            placeholderTextColor={Theme.COLORS.PLACE_DEFAULT_INPUT}
            autoComplete="new-password"
            containerStyle={{flex: 1}}
            data={data}
            value={rawValue}
            onChangeText={handleOnChange}
            flatListProps={{
              keyExtractor: (_, idx) => idx,
              renderItem: ({ item }) => renderListItem(item)
            }}
            {...rest}
          />
      </Container>
      <Footer>
        <MessageErro>{error}</MessageErro>
      </Footer>
    </>
  );
};

const styles = StyleSheet.create({
  autoCompleteInput: {
    fontFamily: 'roboto-regular',
    fontSize: 16,
    color: Theme.COLORS.TXT_DEFAULT_INPUT,
  }
});

export default AutoCompleteInput;
