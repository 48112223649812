import React from 'react';
import { ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import useBackKey from '../../hooks/backKey';
import { Background, TextContainer } from '../../styles/globals';
import { Texto, Rodape } from './styles';

const TermosDeUso: React.FC = () => {
  
  const { goBack } = useNavigation();
  useBackKey(goBack);
  
  return (
    <Background>
      <ScrollView>
        <TextContainer>
          <Texto destacado>Política de Privacidade - Aplicativo Defensoria Pública Geral do Estado do Ceará</Texto>
          <Texto destacado>1.Informações Gerais</Texto>
          <Texto>
            A presente Política de Privacidade contém informações a respeito do modo como tratamos, total ou parcialmente, 
            de forma automatizada ou não, os dados pessoais dos usuários que acessam nosso aplicativo. Seu objetivo é esclarecer 
            os interessados acerca dos tipos de dados que são coletados dos motivos da coleta e da forma como o usuário poderá atualizar, 
            gerenciar ou excluir estas informações. 
          </Texto>
          <Texto>
            Esta Política de Privacidade foi elaborada em conformidade com a Lei Federal 12.965/2014 (Marco Civil da Internet) 
            e com a Lei Federal 13.709/2018 (Lei Geral de Proteção de Dados Pessoais).
          </Texto>
          <Texto>
            Esta Política de Privacidade poderá ser atualizada em decorrência de eventual atualização normativa, razão pela qual 
            se convida o usuário a consultar periodicamente esta seção. 
          </Texto>
          <Texto destacado>2. Direitos do Usuário</Texto>
          <Texto>
            O aplicativo se compromete a cumprir as normas previstas na LGPD em respeito aos seguintes princípios:
          </Texto>
          <Texto>
            (i) Os dados pessoais do usuário serão processados de forma lícita, leal e transparente. 
          </Texto>
          <Texto>
            (ii) Os dados pessoais serão apenas coletados para as finalidades determinadas e explicitadas no presente documento.
          </Texto>
          <Texto>
            (iii) Os dados do usuário serão coletados de forma adequada e limitadas às necessidades do objetivo para o qual estão 
            sendo processados. 
          </Texto>
          <Texto>
            (iv) Os dados pessoais serão exatos e atualizados conforme requisição do usuário. 
          </Texto>
          <Texto>
            (vi) O usuário terá direito de acesso aos dados que estão sendo tratados a seu respeito.
          </Texto>
          <Texto destacado>3. Tipos de Dados Coletados</Texto>
          <Texto>
            3.1	Dados de Identificação do usuário para realização do cadastro
          </Texto>
          <Texto>
            A utilização, pelo usuário, de funcionalidades relacionadas à navegação inicial 
            do Aplicativo e consulta de processos somente poderá ser realizada com o devido cadastro.
          </Texto>
          <Texto>
            No entanto, a utilização de determinadas funcionalidades do aplicativo, como agendamento ou atendimento 
            personalizado dependerá de cadastro, sendo armazenados os seguintes dados, inicialmente:
          </Texto>
          <Texto></Texto>
          <Texto>- Nome completo</Texto>
          <Texto>- CPF</Texto>
          <Texto>- E-mail</Texto>
          <Texto>- Data de nascimento</Texto>
          <Texto>- Telefone</Texto>
          <Texto>- CEP</Texto>
          <Texto>- Endereço (município e bairro)</Texto>
          <Texto></Texto>
          <Texto>3.2 Dados informados no formulário</Texto>
          <Texto>
            Os dados eventualmente informados pelo usuário que utilizar o formulário de contato disponibilizado no aplicativo, 
            incluindo o teor da mensagem enviada, serão coletados e armazenados. 
          </Texto>
          <Texto>Os dados a serem coletados dependerão do processo a que o usuário está relacionado.</Texto>
          <Texto>
            A depender do conteúdo do processo a ser assistido pela Defensoria, diferentes documentos poderão ser solicitados.
          </Texto>
          <Texto>3.3 Dados sensíveis</Texto>
          <Texto>
            O aplicativo poderá coletar dados sensíveis dos usuários, a depender do conteúdo do processo judicial. 
            O usuário será informado sobre a coleta, caso necessário.
          </Texto>
          <Texto>
            De acordo com a Lei Geral de Proteção de Dados, compreende-se por dados pessoais sensíveis (art. 5º, II) 
            “dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a 
            organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, 
            dado genético ou biométrico, quando vinculado a uma pessoa natural”.
          </Texto>
          <Texto destacado>4. Fundamento Jurídico para o tratamento de dados pessoais</Texto>
          <Texto>
            Ao utilizar os serviços do aplicativo, o usuário está consentindo com a presente Política de Privacidade. 
            O usuário tem o direito de retirar o seu consentimento a qualquer momento.
          </Texto>
          <Texto>
            O consentimento dos relativamente ou absolutamente incapazes (menores de 16 anos) somente poderá ser feito se devidamente 
            assistidos ou representados por seus responsáveis judiciais. 
          </Texto>
          <Texto>
            O atendimento virtual da Defensoria Pública é opcional, podendo o usuário titular dos dados optar pelo atendimento 
            presencial ou realizar o agendamento através do contato telefônico.
          </Texto>
          <Texto>4.1 Finalidades do tratamento de dados pessoais</Texto>
          <Texto>
            A Lei Geral de Proteção de Dados (Art. 7º) estabelece 10 finalidades para o tratamento de dados pessoais. 
          </Texto>
          <Texto>As finalidades adequadas às atividades exercidas são:</Texto>
          <Texto>4.1.1 Para o cumprimento de obrigação legal ou regulatória pelo controlador:</Texto>
          <Texto>
            Os endereços e telefones para contato serão armazenados para que seja possível contactar a pessoa interessada, 
            bem como para recebimento de notificações / intimações judiciais. 
          </Texto>
          <Texto>4.1.2 Para o exercício regular de direitos em Processo Judicial</Texto>
          <Texto>
            Informações específicas sobre o processo serão coletadas para o exercício do devido processo legal. 
          </Texto>
          <Texto>4.1.3 Legítimo interesse do controlador</Texto>
          <Texto>
            Informações relacionadas a navegação serão coletadas para aprimoramento dos serviços oferecidos no aplicativo.
          </Texto>
          <Texto>4.1.4	Consentimento</Texto>
          <Texto>
            O usuário poderá consentir para o recebimento de notificações para o acompanhamento do processo judicial.
          </Texto>
          <Texto>
            Para a realização de estudos por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais.
          </Texto>
          <Texto>
            Os dados coletados poderão ser utilizados para fins de pesquisa sobre o sistema judiciário e análise dos serviços da 
            Defensoria Pública. Em caso de publicidade, os dados serão anonimizados. 
          </Texto>
          <Texto destacado>5.	Prazo para a conservação dos dados pessoais</Texto>
          <Texto>
            Os dados pessoais do usuário serão conservados por um período não superior ao exigido para cumprir os objetivos 
            em razão dos quais eles são processados. 
          </Texto>
          <Texto>
            O período de conservação dos dados são definidos de acordo com o critério para estrito cumprimento de obrigação legal.
          </Texto>
          <Texto>
            No caso de notificação através do aplicativo, esta poderá ser suspendida a qualquer tempo, 
            através de configuração no aparelho por parte do usuário. 
          </Texto>
          <Texto destacado>6.	Armazenamento dos dados pessoais</Texto>
          <Texto>
            O armazenamento ocorrerá na base de dados internas da Defensoria Pública do Ceará.
          </Texto>
          <Texto destacado>7.	Compartilhamento de dados pessoais</Texto>
          <Texto>
            Para sua segurança e tranquilidade, consideramos todos os dados coletados como confidenciais, tratando e armazenando 
            todas as informações conforme estabelecido nesta Política de Privacidade, sempre em observância ao disposto na legislação 
            brasileira e utilizando as melhores práticas de segurança disponíveis no mercado (inclusive conexão segura e criptografada). 
          </Texto>
          <Texto>
            Assim, as informações efetivamente coletadas pelo aplicativo Nossa Defensoria são utilizadas para a adequada prestação 
            dos serviços do aplicativo, seu aprimoramento e correção, bem como para fins estatísticos, nos termos ora descritos.
          </Texto>
          <Texto>
            Os dados coletados dos Usuários poderão ser utilizados para as seguintes finalidades:
          </Texto>
          <Texto>Identificação, autenticação e autorização;</Texto>
          <Texto>Atender adequadamente às solicitações e dúvidas;</Texto>
          <Texto>Manter atualizados cadastros para fins de contato por telefone, correio eletrônico, SMS, mala direta ou por outros meios de comunicação;</Texto>
          <Texto>Aperfeiçoar o uso e a experiência interativa durante navegação nos sites, aplicativos e serviços prestados pelo Nossa Defensoria;</Texto>
          <Texto>
            Efetuar estatísticas, estudos, pesquisas e levantamentos pertinentes às atividades e comportamentos do Usuário ao utilizar os sites, aplicativos e serviços prestados pelo Nossa Defensoria, 
            realizando tais operações de forma anonimizada com dados anonimizados;
          </Texto>
          <Texto>
            Promover os serviços do aplicativo Nossa Defensoria, além de informar sobre novidades, funcionalidades, conteúdos, 
            notícias e demais informações relevantes para a manutenção do relacionamento com o Nossa Defensoria;
          </Texto>
          <Texto>
            Resguardar o aplicativo Nossa Defensoria de direitos e obrigações relacionadas ao uso dos sites, 
            aplicativos e serviços prestados pelo Nossa Defensoria;
          </Texto>
          <Texto>
            Colaborar e/ou cumprir ordem judicial ou requisição por autoridade administrativa;
          </Texto>
          <Texto>
            Gerenciar riscos e detectar, prevenir e/ou remediar fraudes ou outras atividades potencialmente ilegais ou proibidas, 
            além de violações de políticas ou termos de uso aplicáveis;
          </Texto>
          <Texto>
            A fim de viabilizar a comunicação, o Usuário autoriza, por meio do aceite à Política de Privacidade, o recebimento de 
            e-mails institucionais por parte do aplicativo Nossa Defensoria. Contudo, nos preocupamos com suas preferências e iremos 
            respeitá-las. Assim, o usuário pode suspender o recebimento de tais e-mails a qualquer momento, seja por meio das opções 
            indicadas na própria mensagem a ser recebida, seja enviando sua solicitação por e-mail para centraldeservicos@defensoria.ce.def.br;
          </Texto>
          <Texto>
            Ao aceitar a Política de Privacidade, você está ciente de que o aplicativo Nossa Defensoria poderá compartilhar os dados 
            coletados por meio da plataforma nas seguintes situações: (i) se fundamental à prestação dos serviços pelo Nossa Defensoria; 
            (ii) a fim de proteger os interesses do Nossa Defensoria, em qualquer tipo de conflito, incluindo ações judiciais; 
            (iii) mediante ordem judicial ou pelo requerimento de autoridades administrativas que detenham competência legal para sua requisição; 
            ou, ainda, para (iv) utilização para fins públicos de tomada de decisão analítica;
          </Texto>
          <Texto destacado>8. Do tratamento dos dados pessoais</Texto>
          <Texto>8.1 Do responsável pelo tratamento de dados pessoais</Texto>
          <Texto>
            O controlador, responsável pelo tratamento dos dados pessoais do usuário, é a pessoa física ou jurídica, 
            a autoridade pública que individualmente ou em conjunto com outras, determina as finalidades e os meios de 
            tratamento de dados pessoais. 
          </Texto>
          <Texto>
            Neste aplicativo, o responsável pelo tratamento dos dados pessoais coletados é a Defensoria Pública do Ceará, 
            que poderá ser contactado pelo e-mail centraldeservicos@defensoria.ce.def.br.   
          </Texto>
          <Texto>8.2 Do encarregado</Texto>
          <Texto>
            É o profissional encarregado por informar, aconselhar o responsável pelo tratamento de dados pessoais.
          </Texto>
          <Texto>
            Neste aplicativo o encarregado de proteção de dados é a Defensoria Pública do Ceará, 
            que poderá ser contactado pelo e-mail centraldeservicos@defensoria.ce.def.br.
          </Texto>
          <Texto destacado>9.	Segurança no tratamento de dados pessoais pelo usuário</Texto>
          <Texto>
            O aplicativo se compromete a aplicar medidas técnicas e organizativas aptas para proteger os dados pessoais de acessos 
            não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados pessoais. 
          </Texto>
          <Texto>
            Para a garantia da segurança serão adotadas soluções que levem em consideração: as técnicas adequadas, 
            os custos de aplicação, a natureza, o âmbito, o contexto e as finalidades do tratamento; e os riscos para 
            os direitos e liberdades do usuário. 
          </Texto>
          <Texto>
            No entanto, o aplicativo se exime de responsabilidade por culpa exclusiva de terceiro, como em caso de ataque 
            de hackers ou crackers, ou culpa exclusiva do usuário, caso ele transfira dados a terceiro.
          </Texto>
          <Texto>
            O aplicativo se compromete ainda, a comunicar o usuário em prazo adequado caso ocorra um tipo de violação da 
            segurança de seus dados pessoais que possa lhe causar um alto risco para seus direitos e liberdades pessoais.
          </Texto>
          <Texto destacado>10. Dados de navegação (cookies)</Texto>
          <Texto>
            Cookies são pequenos arquivos de texto enviados pelo aplicativo ao computador do usuário e que nele ficam armazenados 
            com informações relacionadas à navegação do aplicativo.
          </Texto>
          <Texto>
            Por meio dos cookies, pequenas quantidades de informação são armazenadas pelo navegador do usuário para que nosso 
            servidor possa lê-las posteriormente. Podem ser armazenados, por exemplo, dados sobre o dispositivo utilizado pelo usuário, 
            bem como seu local e horário de acesso ao aplicativo. 
          </Texto>
          <Texto>
            Nem todo cookie permite a identificação do usuário, sendo que determinados tipos de cookies podem ser empregados 
            simplesmente para que o aplicativo seja carregado corretamente ou para que suas funcionalidades funcionem de acordo 
            com o esperado. 
          </Texto>
          <Texto>
            Cookies do aplicativo: são aqueles enviados ao computador ou dispositivo do usuário e administrador exclusivamente 
            pelo aplicativo. As informações coletadas por meio desses cookies são utilizadas para melhorar e personalizar a 
            experiência do usuário: Serão coletadas informações como hora de conexão e IP.
          </Texto>
          <Texto>
            O usuário poderá se opor ao registro de cookies pelo aplicativo, bastando que desative esta opção no seu próprio aparelho. 
            A desativação dos cookies, no entanto, pode afetar a disponibilidade de algumas ferramentas e funcionalidades do aplicativo, 
            comprometendo seu correto e esperado funcionamento.
          </Texto>
          <Texto destacado>11. Das alterações e atualizações</Texto>
          <Texto>
            O editor se reserva o direito de modificar, a qualquer momento, o aplicativo, as presentes normas, especialmente 
            para adaptá-las às evoluções do aplicativo "Nossa Defensoria", seja pela disponibilização de novas funcionalidades, 
            seja pela supressão ou modificação daquelas já existentes.
          </Texto>
          <Texto>
            O usuário será explicitamente notificado em caso de alteração desta política. 
          </Texto>
          <Texto destacado>12. Do Direito Aplicável e do Foro</Texto>
          <Texto>
            Para a solução das controvérsias decorrentes do presente instrumento, será aplicado integralmente o Direito brasileiro. 
          </Texto>
          <Texto>
            Os eventuais conflitos serão apresentados no foro de Fortaleza-CE.
          </Texto>
          <Rodape></Rodape>
        </TextContainer>
      </ScrollView>
    </Background>
  );
};


export default TermosDeUso;




