import styled from 'styled-components/native';
import { RectButton } from 'react-native-gesture-handler';
import Theme from '../../../../constants/Theme';

interface BotaoProps {
  btnSecundario?: boolean;
}

interface ContainerProps {
  ativo?: boolean;
}

export const Container = styled.View<ContainerProps>`
  min-height: 160px;
  margin: 7px 0px;
  border-radius: 10px;
  background-color: ${props => props.ativo ? Theme.COLORS.BG_CARD_VERDE : Theme.COLORS.BG_CARD_CINZA};;
  padding: 15px;
  justify-content: space-between;
`;

export const Principal = styled.View``;

export const Label = styled.Text`
  font-family: 'roboto-bold';
  font-size: 14px;
  color: ${Theme.COLORS.TXT_LEITURA_BRANCO};
`;

export const Descricao = styled.Text`
  font-family: 'roboto-regular';
  font-size: 14px;
  color: ${Theme.COLORS.TXT_LEITURA_BRANCO};
`;

export const ContainerTexto = styled.View`
  margin-bottom: 5px;
`;

export const ContainerTextoTopo = styled.View`
  margin-bottom: 5px;
`;

export const Topo = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

export const Meio = styled.View``;

export const Rodape = styled.View`
  margin-top: 10px;
  flex-direction: row;
`;

export const ContainerBotao = styled(RectButton)<BotaoProps>`
  width: 100px;
  height: 40px;
  background: ${props => props.btnSecundario ? 'transparent' : Theme.COLORS.BG_BTN_DEFAULT};
  border-radius: 30px;
  border: ${props => props.btnSecundario ? '1px solid #fff' : 'none'};
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

export const BotaoTexto = styled.Text`
  font-family: 'roboto-regular';
  color: ${Theme.COLORS.TXT_BTN_HOME};
  font-size: 14px;
`;
