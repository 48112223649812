const VERDE_ESCURO = '#01714A';
const VERDE_CLARO = '#007B50';
const AMARELO = '#d3bc8a';
const AMARELO_ESCURO = '#f1c232';
const CINZA_CLARO = '#F1F1F1';
const CINZA_ESCURO = '#A1A1A1';
const BRANCO = '#ffffff';
const BLACK = '#212529';
const VERMELHO = '#DE3E3E';
const DOURADO = '#E0C387';
const VERDE_BEBE = '#87E0CB';

interface Theme {
  COLORS: Colors;
  SIZES: Sizes;
}

interface Colors {
  TXT_LINK_CHECK: string;
  ACTIVITY: string;
  BG_DEFAULT: string;
  BG_INICIO: string;
  BG_LEITURA: string;
  TXT_LEITURA: string;
  BORDER_BG_LEITURA: string;
  BG_BTN_HOME: string;
  BG_BTN_SECONDARY: string;
  TXT_BTN_HOME: string;
  BG_BTN_HOME_DISABLED: string;
  TXT_BTN_HOME_NEW: string;
  BG_TAB_ACTIVE: string;
  BG_TAB_INACTIVE: string;
  TXT_TAB_ACTIVE: string;
  TXT_TAB_INACTIVE: string;
  BG_HEADER: string;
  TXT_HEADER: string;
  BG_BTN_IN_TEXT: string;
  TXT_BTN_IN_TEXT: string;
  TXT_LINK_BG: string;
  BG_LINK_BG: string;
  BG_BTN_DEFAULT: string;
  TXT_BTN_DEFAULT: string;
  BG_DEFAULT_INPUT: string;
  BG_WHITE_INPUT: string;
  BORDER_DEFAULT_INPUT: string;
  TXT_DEFAULT_INPUT: string;
  PLACE_DEFAULT_INPUT: string;
  BORDER_FOCUS_INPUT: string;
  PLACE_FOCUS_INPUT: string;
  BORDER_ERROR_INPUT: string;
  MESSAGE_ERROR_INPUT: string;
  MESSAGE_ERROR_INPUT_LIGHT: string;
  TITLE_LIST: string;
  BG_CONT_LIST: string;
  TXT_INFO_LIST: string;
  ICON_NEW_LIST: string;
  TXT_META_LIST: string;
  ICON_META_LIST: string;
  CHECK_ANEXO: string;
  TXT_PROFILE_NAME: string;
  TXT_PROFILE_CITY: string;
  BG_PROFILE_NAME: string;
  TXT_PROFILE_META_LABEL: string;
  TXT_PROFILE_META_INFO: string;
  TXT_LEITURA_BRANCO: string;
  BG_CARD_VERDE: string;
  BG_CARD_CINZA: string;
  BG_CARD_CINZA_CLARO: string;
  BG_CARD_VERMELHO: string;
  BG_CARD_DOURADO: string;
  BG_CARD_VERDE_BEBE: string;
  BG_VERDE_ESCURO: string;
  TXT_LEITURA_DOURADO: string;
}

interface Sizes {
  PD_SCREEN_DEFAULT: string;
  MG_SCREEN_DEFAULT: string;
  PD_SCREEN_INICIO: string;
}

const Theme: Theme = {
  COLORS: {
    TXT_LINK_CHECK: VERDE_ESCURO,

    ACTIVITY: VERDE_ESCURO,
    BG_DEFAULT: BRANCO,
    BG_INICIO: VERDE_CLARO,
    BG_LEITURA: BRANCO,
    TXT_LEITURA: BLACK,
    BORDER_BG_LEITURA: AMARELO,

    BG_BTN_HOME: VERDE_CLARO,
    TXT_BTN_HOME: BRANCO,
    BG_BTN_HOME_DISABLED: CINZA_ESCURO,
    TXT_BTN_HOME_NEW: AMARELO,
    TXT_LEITURA_BRANCO: BRANCO,
    TXT_LEITURA_DOURADO: DOURADO,
    
    BG_TAB_ACTIVE: VERDE_ESCURO,
    BG_TAB_INACTIVE: VERDE_CLARO,
    TXT_TAB_ACTIVE: BRANCO,
    TXT_TAB_INACTIVE: CINZA_ESCURO,
    
    BG_HEADER: VERDE_ESCURO,
    TXT_HEADER: BRANCO,
    
    BG_BTN_IN_TEXT: VERDE_ESCURO,
    TXT_BTN_IN_TEXT: BRANCO,
    
    TXT_LINK_BG: VERDE_ESCURO,
    BG_LINK_BG: AMARELO,
    
    BG_BTN_DEFAULT: DOURADO,
    BG_BTN_SECONDARY: VERDE_CLARO,
    TXT_BTN_DEFAULT: BRANCO,

    BG_DEFAULT_INPUT: CINZA_CLARO,
    BG_WHITE_INPUT: BRANCO,
    BORDER_DEFAULT_INPUT: BLACK,
    TXT_DEFAULT_INPUT: BLACK,
    PLACE_DEFAULT_INPUT: CINZA_ESCURO,
    BORDER_FOCUS_INPUT: AMARELO,
    PLACE_FOCUS_INPUT: AMARELO,
    BORDER_ERROR_INPUT: VERMELHO,
    MESSAGE_ERROR_INPUT: VERMELHO,
    MESSAGE_ERROR_INPUT_LIGHT: DOURADO,

    TITLE_LIST: VERDE_ESCURO,
    BG_CONT_LIST: CINZA_ESCURO,
    TXT_INFO_LIST: BLACK,
    ICON_NEW_LIST: VERMELHO,
    TXT_META_LIST: VERDE_CLARO,
    ICON_META_LIST: VERDE_CLARO,

    CHECK_ANEXO: VERDE_CLARO,

    TXT_PROFILE_NAME: VERDE_ESCURO,
    TXT_PROFILE_CITY: BLACK,
    BG_PROFILE_NAME: AMARELO,
    TXT_PROFILE_META_LABEL: VERDE_CLARO,
    TXT_PROFILE_META_INFO: BLACK,

    BG_CARD_VERDE: VERDE_CLARO,
    BG_CARD_CINZA: CINZA_ESCURO,
    BG_CARD_CINZA_CLARO: CINZA_CLARO,
    BG_CARD_VERMELHO: VERMELHO,
    BG_CARD_DOURADO: DOURADO,
    BG_CARD_VERDE_BEBE: VERDE_BEBE,
    BG_VERDE_ESCURO: VERDE_ESCURO,
  },
  SIZES: {
    PD_SCREEN_DEFAULT: '20px',
    MG_SCREEN_DEFAULT: '20px',
    PD_SCREEN_INICIO: '20px 40px',
  },
};

export default Theme;