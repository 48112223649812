import React, { useCallback, useEffect, useState } from 'react';
import Routes from './src/routes/index';
import { NavigationContainer } from '@react-navigation/native';
import FlashMessage from 'react-native-flash-message';
import * as Font from 'expo-font';
import { AuthProvider } from './src/contexts/auth';
import { DocumentProvider } from './src/contexts/document';
import { NotificationProvider } from './src/contexts/notification';
import { SafeAreaProvider, initialWindowMetrics } from 'react-native-safe-area-context';
import { patchFlatListProps } from 'react-native-web-refresh-control';

export default function App() {
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  patchFlatListProps();
  
  const fetchFonts = useCallback( 
    async() => {
      await Font.loadAsync({
          'roboto-bold': require('./assets/fonts/Roboto-Bold.ttf'),
          'roboto-thin': require('./assets/fonts/Roboto-Thin.ttf'),
          'roboto-regular': require('./assets/fonts/Roboto-Regular.ttf')
        });
      setDataLoaded(true);
  }, []);

  useEffect(() => {
    if(!dataLoaded) fetchFonts();
  },[dataLoaded] )

  if(!dataLoaded) {
    return null
  }

  return (
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
      <NavigationContainer>
        <AuthProvider>
          <DocumentProvider>
            <NotificationProvider>
              <Routes />
              <FlashMessage position="top" />
            </NotificationProvider>
          </DocumentProvider>
        </AuthProvider>
      </NavigationContainer>
    </SafeAreaProvider>
  )
}
