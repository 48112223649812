import styled from 'styled-components/native';
import Theme from '../../constants/Theme';
import IconButton from 'react-native-vector-icons/Feather';

interface TituloFormProps {
  destacado?: boolean;
}

export const ContainerForm = styled.View``;

export const BackButton = styled.TouchableWithoutFeedback``;

export const Icon = styled(IconButton)`
 z-index: 10;
`;

export const ContainerTituloForm = styled.View`
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 10px;
`;

export const TituloForm = styled.Text<TituloFormProps>`
  font-size: 25px;
  font-family: ${props => props.destacado ? 'roboto-bold' : 'roboto-thin'};
  color: ${Theme.COLORS.TXT_LEITURA_BRANCO};
`;

export const ContainerTextObservacao = styled.View`
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

export const TextObservacao = styled.Text`
  font-size: 14px;
  font-family: 'roboto-thin';
  color: ${Theme.COLORS.TXT_LEITURA_BRANCO};
  text-align: center;
`;

export const ContainerLogo = styled.View`
  margin-top: -25px;
`;