import React from 'react';
import { View, Dimensions } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Badge } from 'react-native-elements';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Feather } from '@expo/vector-icons';
import DashboardScreen from '../modules/Dashboard/index';
import ProcessoScreen from '../modules/Processos/pages/index';
import AgendaScreen from '../modules/Agenda/pages/listaAgendamentos/index';
import NovoAgendamentoScreen from '../modules/Agenda/pages/novoAgendamento';
import ListaSolicitacoesFinalizadasScreen from '../modules/Agenda/pages/listaSolicitacoesFinalizadas'
import RevisarAgendamentoScreen from '../modules/Agenda/pages/revisarAgendamento';
import RemarcarAgendamentoScreen from '../modules/Agenda/pages/remarcarAgendamento';
import ListaDocumentosScreen from '../modules/Documentos/pages/listaDocumentos/index';
import AnexarDocumentosScreen from '../modules/Documentos/pages/anexarDocumentos/index';
import NotificacaoScreen from '../modules/Notificacao/pages/index';
import AjudaScreen from '../pages/Ajuda';
import MudarSenhaScreen from '../pages/MudarSenha';
import PerfilScreen from '../pages/Perfil';
import TermosDeUsoScreen from '../pages/TermosDeUso';
import FeedbackScreen from '../pages/Feedback';
import SobreScreen from '../pages/Sobre';
import Theme from '../constants/Theme';
import { useNotification } from '../contexts/notification';

const Tab = createBottomTabNavigator();
const AppStack = createStackNavigator();

function DashboardTabs() {
  const { naoLidaCount } = useNotification();
  const insets = useSafeAreaInsets();
  const { width } = Dimensions.get('screen');

  return (
    <Tab.Navigator
      tabBarOptions={{
        activeTintColor: Theme.COLORS.TXT_TAB_ACTIVE,
        inactiveTintColor: 'rgba(255,255,255, .5)',
        activeBackgroundColor: Theme.COLORS.BG_TAB_ACTIVE,
        inactiveBackgroundColor: Theme.COLORS.BG_TAB_INACTIVE,
        style: {
          height: 70,
          position: 'absolute',
          bottom: insets.bottom
        },
        tabStyle: {
          height: 70,
        },
        labelStyle: {
          fontFamily: 'roboto-regular', 
          fontSize: 14,
          marginBottom: (width && width >= 768) ? 0 : 10,
        },
        keyboardHidesTabBar: true,
      }}
    >
      <Tab.Screen
        options={{
          tabBarIcon: ({ color }) => <Feather name="home" size={20} color={color} />,
        }}
        name="Inicio"
        component={DashboardScreen}
      />
      <Tab.Screen
        options={{
          tabBarIcon: ({ color }) => (
            <View>
              <Feather name="bell" size={20} color={color} />
              {naoLidaCount && naoLidaCount > 0 ? (
                <Badge 
                  status="error"
                  containerStyle={{ position: 'absolute', top: -5, right: (width && width >= 768) ? 20 : -20 }}
                  value={naoLidaCount}
                />
               ) : null }
            </View>
          ),
        }}
        name="Notificações"
        component={NotificacaoScreen}
      />
      <Tab.Screen
        options={{
          tabBarIcon: ({ color }) => <Feather name="user" size={20} color={color} />,
        }}
        name="Perfil"
        component={PerfilScreen}
      />
    </Tab.Navigator>
  );
}

const LoggedRoutes: React.FC = () => {
  return (
    <AppStack.Navigator
      screenOptions={{
        headerTitleAllowFontScaling: false,
        headerStyle: {
          backgroundColor: Theme.COLORS.BG_HEADER,
        },
        headerTintColor: Theme.COLORS.TXT_HEADER,
        headerTitleStyle: {
          fontFamily: 'roboto-bold',
          alignSelf: 'center',
          marginLeft: -45
          },
        }}
      >
      <AppStack.Screen 
        name="Dashboard" 
        component={DashboardTabs}
        options={{
          headerShown: false,
        }} 
      />
      <AppStack.Screen
        name="Documentos"
        component={ListaDocumentosScreen}
        options={{
          title: 'Meus Documentos',
        }}
      />
      <AppStack.Screen
        name="AnexarDocumentos"
        component={AnexarDocumentosScreen}
        options={{
          title: 'Anexar Documentos',
        }}
      />
      <AppStack.Screen
        name="Processos"
        component={ProcessoScreen}
        options={{
          title: 'Meus Processos',
        }}
      />
      <AppStack.Screen
        name="Agenda"
        component={AgendaScreen}
        options={{
          title: 'Meus Atendimentos',
        }}
      />
      <AppStack.Screen
        name="NovoAgendamento"
        component={NovoAgendamentoScreen}
        options={{
          title:'Novo Atendimento',
        }}
      />
      <AppStack.Screen
        name="RemarcarAgendamento"
        component={RemarcarAgendamentoScreen}
        options={{
          title:'Remarcar Atendimento',
        }}
      />
      <AppStack.Screen
        name="RevisarAgendamento"
        component={RevisarAgendamentoScreen}
        options={{
          title:'Revisar Atendimento',
        }}
      />
      <AppStack.Screen
        name="SolicitacoesFinalizadas"
        component={ListaSolicitacoesFinalizadasScreen}
        options={{
          title:'Solicitações finalizadas',
        }}
      />
      <AppStack.Screen 
        name="Perfil" 
        options={{ headerShown: true, title: 'Perfil' }} 
        component={PerfilScreen} 
      />
      <AppStack.Screen
        name="Ajuda"
        component={AjudaScreen}
        options={{
          title:'Ajuda',
        }}
      />
      <AppStack.Screen
        name="MudarSenha"
        component={MudarSenhaScreen}
        options={{
          title:'Alterar senha',
        }}
      />
      <AppStack.Screen
        name="TermosDeUso"
        component={TermosDeUsoScreen}
        options={{
          title:'Política de Privacidade',
        }}
      />
      <AppStack.Screen
        name="Sobre"
        component={SobreScreen}
        options={{
          title:'Sobre',
        }}
      />
      <AppStack.Screen
        name="Feedback"
        component={FeedbackScreen}
        options={{
          title:'Avalie o nosso aplicativo',
        }}
      />
    </AppStack.Navigator>
  );
};

export default LoggedRoutes;
