import styled from 'styled-components/native';

export const Container = styled.View`
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 10001;
  justify-content: center;
  background-color: rgba(0,0,0,.5);
  flex-direction: column;
  align-items: center;
`;

export const Titulo = styled.Text`
  font-family: 'roboto-regular';
  margin-top: 5px;
  color: #fff;
`;
