import styled from 'styled-components/native';
import { RectButton } from 'react-native-gesture-handler';
import Theme from '../../../../constants/Theme';

export const Container = styled.View`
  margin: 0px 15px 7px 15px;
  border-radius: 10px;
  background-color: ${Theme.COLORS.BG_CARD_VERMELHO};
  padding: 15px 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Descricao = styled.Text`
  font-family: 'roboto-regular';
  font-size: 14px;
  color: ${Theme.COLORS.TXT_LEITURA_BRANCO};
`;

export const ContainerTexto = styled.View`
  flex: 1.7;
`;

export const ContainerBotao = styled(RectButton)`
  width: 90px;
  height: 40px;
  background: ${Theme.COLORS.BG_BTN_DEFAULT};
  border-radius: 30px;
  justify-content: center;
  align-items: center;
`;

export const BotaoTexto = styled.Text`
  font-family: 'roboto-regular';
  color: ${Theme.COLORS.TXT_BTN_HOME};
  font-size: 14px;
`;