import React, { useCallback, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import useBackKey from '../../../hooks/backKey';
import api from '../../../services/apiNossaDefensoria';
import { useAuth } from '../../../contexts/auth';
import { getMessageErroSistemaCustom, getMessageSucessSaveCustom } from '../../../utils/getShowMessage';
import { AlertaConfirmacao } from '../../../components/Alert';

import ListaVazia from '../../../components/ListaVazia';
import ItemProcesso from '../components/ItemProcesso'
import Loading from '../../../components/Loading';

import { Background } from '../../../styles/globals';
import { ProcessoList } from './styles';
import Theme from '../../../constants/Theme';

export interface Processo {
  id: number;
  num_processo: string;
  num_protocolo: string;
  acao_tipo_fk: {
    titulo:string;
  };
  possui_solicitacao: {
    codigo: number;
  }
};

const Processo: React.FC = () => {
  const { user } = useAuth();
  const { navigate, goBack } = useNavigation();
  const [processos, setProcessos] = useState<Processo[]>([]);
  const [loading, setLoading ] = useState<boolean>(false);
  const [loadingMore, setLoadingMore ] = useState<boolean>(false);
  const [page, setPage ] = useState<number>(1);
  const [showAlertaSolicitar, setShowAlertaSolicitar] = useState<boolean>(false);
  const [idProcesso, setIdProcesso] = useState<number>();
  const [ refreshLoading, setRefreshLoading ] = useState<boolean>(false);
  
  useBackKey(goBack);
  
  const fetchInitialProcessos = (refreshReload: boolean) => {
    
    refreshReload ? setRefreshLoading(true) : setLoading(true);

    api.get('/atendimento/api/processo/', {
      params: {
        users_app__id__in: user?.id
      }
    })
    .then(response => {
      const {data: { results, next } } = response;
      next ? setPage(page + 1) : setPage(0)
      setProcessos(results);
    })
    .catch(err => {
      if(err?.response?.status === 500) {
        getMessageErroSistemaCustom('Erro', 'Falha ao obter processos.')
      }
    })
    .finally(() => {
      setLoading(false)
      setRefreshLoading(false)
    });
  };

  const fetchMaisProcessos = () => {
    setLoadingMore(true)
    api.get('/atendimento/api/processo/', {
      params: {
        users_app__id__in: user?.id,
        page:page
      }
    })
    .then(response => {
      const {data: { results, next } } = response;
      next ? setPage(page + 1) : setPage(0)
      setProcessos([...processos, ...results]);
    })
    .catch(err => {
      if(err?.response?.status === 500) {
        getMessageErroSistemaCustom('Erro', 'Falha ao obter mais processos.')
      }
    })
    .finally(() =>  setLoadingMore(false));
  };

  useFocusEffect(useCallback(() => fetchInitialProcessos(false), []));
  
  const solicitarAtendimento = () => {
    setShowAlertaSolicitar(false);
    const processo = processos.find((p) => p.id === idProcesso );

    if(processo?.possui_solicitacao.codigo === 2) {
      setLoading(true);
      api.post(`/atendimento/api/processo/${idProcesso}/criar_agendamento_retorno/?criado_por=${user?.id}`)
      .then(() => {
        getMessageSucessSaveCustom('Solicitação de agendamento enviada!');
        setProcessos([])
        fetchInitialProcessos(false) 
      })
      .catch((err) => {
        if(err?.response?.status === 500) {
          getMessageErroSistemaCustom('Erro', 'Falha ao solicitar agendamento desse processo.');
        }
      })
      .finally(() => setLoading(false))
    } else {
      navigate('NovoAgendamento', { 
        numProcesso: processo?.num_processo,
        processoId: processo?.id,
      })
    }
  };

  return (
    <Background>
      {processos.length ? (
        <ProcessoList
          data={processos}
          keyExtractor={processo => String(processo.id)}
          refreshing={refreshLoading}
          onRefresh={() => fetchInitialProcessos(true)}
          renderItem={({ item: processo }) => (
            <ItemProcesso 
              id={processo.id}
              numeroProcesso={processo.num_processo}
              numeroProtocolo={processo.num_protocolo}
              acaoTipo={processo.acao_tipo_fk.titulo}
              possuiSolicitacaoCod={processo.possui_solicitacao.codigo}
              handleSolicitar={(id:number) => {
                setIdProcesso(id);
                setShowAlertaSolicitar(true);
              }}
            />
          )}
          onEndReached={() => {
            if(page !== 0 && !loadingMore) fetchMaisProcessos();
          }}
          onEndReachedThreshold={0.5}
          ListFooterComponent={() => (
            loadingMore ? 
              <ActivityIndicator 
                size="large"
                color={Theme.COLORS.TXT_LEITURA_DOURADO} 
              /> : null
          )}
        />
      ) : (
      !loading && 
        <ListaVazia 
          descricao="A Defensoria Pública não identificou nenhum processo vinculado a você." 
        />
      )}
      <AlertaConfirmacao 
        show={showAlertaSolicitar}
        titulo="Solicitar Atendimento"
        mensagem="Você tem certeza que deseja solicitar atendimento para esse processo?"
        onCancel={() => setShowAlertaSolicitar(false)}
        onConfirm={solicitarAtendimento}
      />
      <Loading show={loading} />
    </Background>
  );
};

export default Processo;
