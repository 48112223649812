import styled from 'styled-components/native';

interface TitleProps {
  bold?: boolean;
}

export const Container = styled.View`
  align-items: center;
  width: 100%;
  justify-content: center;
  flex: 1;
  background-color: #f1f1f1;
`;

export const Title = styled.Text<TitleProps>`
  font-family: ${props => props.bold ?  'roboto-bold' : 'roboto-regular'};
  font-size: 20px;
  text-align: center;
`;

export const Subtitle = styled.Text`
  font-family: 'roboto-bold';
  font-size: 15px;
  text-align: center;
  margin-bottom: 15px;
`;

export const ListContainer = styled.View`
  margin-top: 20px;
  max-height: 250px;
`;

export const ListItem = styled.Text`
  font-family: 'roboto-regular';
  font-size: 14px;
  text-align: center;
  margin-bottom: 7px;
`;