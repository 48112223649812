import styled from 'styled-components/native';


export const ObservacoesContainer = styled.View`
  font-family: 'roboto-regular';
  text-align: center;
  margin-bottom: 15px;
`;

export const ObservacoesTexto = styled.Text`
 font-size: 18px;
`;
