import styled from 'styled-components/native';

export const Container = styled.View`
 flex: 1;
 justify-content: center;
 align-items: center;
`;

export const Descricao = styled.Text`
  font-family: 'roboto-regular';
  font-size: 16px;
  text-align: center;
  max-width: 80%;
  color: #292929;
`;
