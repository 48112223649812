import React from 'react';
import { Container, TextoUserName, TextoSaudacao } from './styles';
import { useAuth } from '../../../../contexts/auth';

const SubHeader: React.FC = () => {

  const { user } = useAuth();

  return (
    <Container>
      <TextoUserName>Olá, <TextoUserName destacado>{user?.name}</TextoUserName></TextoUserName>
      <TextoSaudacao>Seja bem-vindo(a)</TextoSaudacao>
    </Container>
  );
};

export default SubHeader;