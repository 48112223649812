import styled from 'styled-components/native';
import Theme from '../../constants/Theme';

interface TextoProps {
  destacado?: boolean
}

export const TituloAjuda = styled.Text`
  text-align: center;
  font-family: 'roboto-bold';
  font-size: 18px;
  color: ${Theme.COLORS.TXT_LEITURA_BRANCO};
  padding: 7px;
  border-radius: 30px;
  background-color: ${Theme.COLORS.BG_LINK_BG};
  margin-bottom: 20px;
`;

export const TextoAjuda = styled.Text`
  font-family: 'roboto-bold';
  font-size: 14px;
  color: ${Theme.COLORS.TXT_LEITURA};
  margin-bottom: 20px;
`;

export const Link = styled.TouchableOpacity`
`;

export const TextLink = styled.Text`
  color: ${Theme.COLORS.TXT_LEITURA_DOURADO};
`;

export const Texto = styled.Text<TextoProps>`
  font-size: ${props => props.destacado ? '18px' : '14px' };
  font-family: ${props => props.destacado ? 'roboto-bold': 'roboto-regular'};
  margin-bottom: 7px;
  margin-top: 7px;
`;

export const Sessao = styled.View`
`;

export const Divider = styled.View`
  height: 1px;
  background-color: #cccccc;
  margin-bottom: 20px;
  margin-top: 20px;
`;
