import React from 'react';
import Moment from 'moment';
import {
  Container,
  ContainerTexto,
  Texto,
  ContainerBotao,
  BotaoTexto
} from './styles';

interface SolicitacaoRejeitadaAvisoProps {
  motivo: string;
  id: string;
  acaoOcultar: Function;
  data: string;
  status: number;
}

const SolicitacaoRejeitadaAviso: React.FC<SolicitacaoRejeitadaAvisoProps> = ({ acaoOcultar, motivo, id, mostraBtnOcultar, data, status }) => (
  <Container>
    <ContainerTexto>
      <Texto bold>Solicitação de atendimento finalizada</Texto>
    </ContainerTexto>
    <ContainerTexto>
      <Texto bold>Motivo: <Texto>{motivo}</Texto></Texto>
    </ContainerTexto>
    <ContainerTexto>
      <Texto bold>N° da solicitação: <Texto>{id}</Texto></Texto>
    </ContainerTexto>
    <ContainerTexto>
      <Texto bold>Data da solicitação: <Texto>{Moment(data).format('DD/MM/YYYY')}</Texto></Texto>
    </ContainerTexto>
    {(status === -4) && 
      <ContainerBotao onPress={() => acaoOcultar()}>
        <BotaoTexto>Ciente</BotaoTexto>
      </ContainerBotao>
    }
  </Container>
);

export default SolicitacaoRejeitadaAviso;