import React, { createContext, useState, useContext } from 'react';
import { useAuth } from './auth';
import * as notification from '../services/notification';

interface NotificationContextData {
  naoLidaCount: number;
  fetchNaoLidaCount: Function;
};

const NotificationContext = createContext<NotificationContextData>({} as NotificationContextData);

export const NotificationProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const [ naoLidaCount, setNaoLidaCount ] = useState<number>(0);

  function fetchNaoLidaCount() {
    const params = {
      params: {
        destinatario_user_app_id: user?.id,
        is_lida: false
      }
    };
    notification.fetchNaoLidaCount(params)
    .then((response) => {
      const { data } = response;
      
      (data && data.count > 0) ?
        setNaoLidaCount(data.count):
        setNaoLidaCount(0);
    })
  };

  return (
    <NotificationContext.Provider 
      value={{ 
        naoLidaCount,
        fetchNaoLidaCount
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};


export function useNotification() {
  const context = useContext(NotificationContext);

  return context;
};