import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ScrollView } from 'react-native';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import Input from '../../../../components/Input';
import FabButton from '../../../../components/FabButton';
import Loading from '../../../../components/Loading';
import { AlertaSimples } from '../../../../components/Alert';
import useBackKey from '../../../../hooks/backKey';

import api from '../../../../services/apiNossaDefensoria';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { getMessageErroSistemaCustom } from '../../../../utils/getShowMessage';
import { CAMPO_OBRIGATORIO } from '../../../../constants/Validation';

import { Solicitacao } from '../../../../types/Agenda';

import { Background, TextContainer } from '../../../../styles/globals';
import  { ObservacoesContainer, ObservacoesTexto, ObservacoesMotivo } from './styles';

interface formData {
  descricao_do_problema: string;
}

const RevisarAgendamento: React.FC = () => {
  const route = useRoute();
  const formRef = useRef<FormHandles>(null);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ showAlert, setShowAlert ] = useState<boolean>(false);
  const { goBack } = useNavigation();
  const [ solicitacaoId, setSolicitacaoId ] = useState<number | null>(null);
  const [ motivoTexto, setMotivoTexto ] = useState<string>('');
  
  useBackKey(goBack);

  useEffect(() => {
    if(route.params) {
      const { solicitacaoReclassificada } = route.params;

      setSolicitacaoId(solicitacaoReclassificada?.id);
      setMotivoTexto(solicitacaoReclassificada?.motivo_reclassificacao);

      if(solicitacaoReclassificada?.descricao_do_problema) {
        formRef.current?.setFieldValue('descricao_do_problema', solicitacaoReclassificada.descricao_do_problema);
      }
    }
  }, [route])

  const handleForm = useCallback(
    async (data: formData) => {

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descricao_do_problema: Yup.string().required(CAMPO_OBRIGATORIO)
        });

        await schema.validate(data, { abortEarly: false });
        const { descricao_do_problema } = data;

        const params = {
          descricao_do_problema,
          status: -2
        }

        setIsLoading(true);
        api.patch(`/app-assistido/api/solicitacao-agendamento/${solicitacaoId}/`, params)
          .then(() => setShowAlert(true))
          .catch((err) => {
            if(err?.response?.status === 500) {
              getMessageErroSistemaCustom('Erro!', 'Não foi possivel enviar a revisão do agendamento. Tente novamente')
            }
          })
          .finally(() => setIsLoading(false))

      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    }, [solicitacaoId]);
  
  return (
    <Background>
      <ScrollView>
        <TextContainer>  
          <Form ref={formRef} onSubmit={handleForm}>
            <ObservacoesContainer>
              <ObservacoesTexto>
                Recebemos a sua solicitação de atendimento mas não conseguimos classifica-la com base na sua informação.
              </ObservacoesTexto>
              <ObservacoesMotivo>
                {motivoTexto}
              </ObservacoesMotivo>
            </ObservacoesContainer>
            <Input
              name="descricao_do_problema"
              placeholder="Mensagem"
              isTextArea
              numberOfLines={7}
              multiline={true}
              // maxLength={800}
            />
          </Form>
        </TextContainer>
      </ScrollView>
      <FabButton iconName='check' onPress={() => formRef.current?.submitForm()} />
      <Loading show={isLoading}/>
      <AlertaSimples
        show={showAlert}
        titulo='Revisão de atendimento'
        mensagem='Revisão enviada. Por favor aguarde a re-classificação do atendimento.'
        onConfirm={() => {
          setShowAlert(false);
          goBack();
        }}
      />
    </Background>
  );
}

export default RevisarAgendamento;