import React from 'react';
import Icon from 'react-native-vector-icons/Feather';
import { Container, ContainerTexto, Descricao, Bloco, Wrapper } from './styles';

interface SolicitacaoAvisoProps {
  idsEmClassificacao: Array<number>;
  idsEmAgendamento: Array<number>;
}

const SolicitacaoAviso: React.FC<SolicitacaoAvisoProps> = ({ idsEmClassificacao, idsEmAgendamento }) => (
  <Wrapper>
    {(idsEmClassificacao && idsEmClassificacao.length) ? (
      <Container>
        <Icon  
          name='check-circle'
          size={40}
          color="#292929"
          style={{ marginRight: 10}}
        />
        <Bloco>
          <ContainerTexto>
            {idsEmClassificacao.length > 1 ? (
              <Descricao>Suas solicitações estão sendo classificadas.</Descricao>
            ) : (
              <Descricao>Sua solicitação está sendo classificada.</Descricao>
            )}
          </ContainerTexto>
          <ContainerTexto>
            <Descricao>
                O processo de classificação será feito durante nosso horário de funcionamento em dias úteis. (Segunda a sexta das 8h às 17h).
            </Descricao>
          </ContainerTexto>
          <ContainerTexto>
            <Descricao>N° da solicitação: {idsEmClassificacao.toLocaleString()}</Descricao>
          </ContainerTexto>
        </Bloco>
      </Container>
    ) : (
      null
    )}
    
    {(idsEmAgendamento && idsEmAgendamento.length) ? (
      <Container>
        <Icon  
          name='clock'
          size={40}
          color="#292929"
          style={{ marginRight: 10}}
        />
        <Bloco>
          <ContainerTexto>
            {idsEmAgendamento.length > 1 ? (
              <Descricao>Suas solicitações foram classificadas e estão aguardando os agendamentos com os Defensores.</Descricao>
            ) : (
              <Descricao>Sua solicitação foi classificada e está aguardando o agendamento com o Defensor.</Descricao>
            )}
          </ContainerTexto>
          <ContainerTexto>
            <Descricao>
              O processo de agendamento será feito durante nosso horário de funcionamento em dias úteis. (Segunda a sexta das 8h às 17h).
            </Descricao>
          </ContainerTexto>
          <ContainerTexto>
            <Descricao>N° da solicitação: {idsEmAgendamento.toLocaleString()}</Descricao>
          </ContainerTexto>
        </Bloco>
      </Container>
    ) : (
      null
    )}
  </Wrapper>
);

export default SolicitacaoAviso;