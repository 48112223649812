import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useImperativeHandle,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';
import { TextInputProps } from 'react-native';
import { useField } from '@unform/core';

import { Container, TextInput, Icon, MessageErro, Contador, Footer } from './styles';
import Theme from '../../constants/Theme';

interface InputProps extends TextInputProps {
  name: string;
  icon?: string;
  rawValue?: any;
  isTextArea?: boolean;
  fundoEscuro?: boolean;
  passwordInput?: boolean;
}

interface InputValueReference {
  value: string;
}

interface InputRef {
  focus(): void;
}

const Input: ForwardRefRenderFunction<InputRef, InputProps> = (
  { name, icon, onChangeText, rawValue, isTextArea, fundoEscuro, passwordInput, maxLength, ...rest },
  ref,
) => {
  const inputElementRef = useRef<any>(null);
  const [ inputLength, setInputLength ] = useState<number>(0);

  const { registerField, defaultValue = '', fieldName, error } = useField(name);

  const inputValueRef = useRef<InputValueReference>({ value: defaultValue });

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [ secretValue, setSecretValue ] = useState(passwordInput)

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputValueRef.current.value);
  }, []);

  const handleOnChange = useCallback(
    text => {
      if (inputValueRef.current) inputValueRef.current.value = text;
      if (onChangeText) onChangeText(text);
      setInputLength(text.length)
    },
    [onChangeText],
  );

  useImperativeHandle(ref, () => ({
    focus() {
      inputElementRef.current.focus();
    },
  }));

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: inputValueRef.current,
      // path: 'value',

      setValue(ref: any, value) {
        inputValueRef.current.value = value;
        inputElementRef.current.setNativeProps({ text: value });
        setInputLength(value.length)
      },
      getValue(ref) {
        return rawValue || ref.value;
      },
      clearValue() {
        inputValueRef.current.value = '';
        inputElementRef.current.clear();
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Container 
        isFocused={isFocused} 
        isErrored={!!error} 
        fundoEscuro={fundoEscuro} 
        isTextArea={isTextArea}>
          <TextInput
            ref={inputElementRef}
            placeholderTextColor={Theme.COLORS.PLACE_DEFAULT_INPUT}
            defaultValue={defaultValue}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChangeText={handleOnChange}
            secureTextEntry={secretValue}
            maxLength={maxLength}
            {...rest}
          />
          { passwordInput && 
            <Icon 
              name={secretValue ? 'eye-off' : 'eye'}
              size={16}
              color={Theme.COLORS.PLACE_DEFAULT_INPUT}
              onPress={() => setSecretValue(!secretValue)} 
            />
          }
      </Container>
      <Footer>
        <MessageErro fundoEscuro={fundoEscuro}>{error}</MessageErro>
        {isTextArea && maxLength && 
          <Contador>{inputLength}/{maxLength}</Contador>
        }
      </Footer>
    </>
  );
};

export default forwardRef(Input);
