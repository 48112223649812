import React from 'react';
import { Image } from 'react-native';
import { Container, ViewImage, Titulo, ViewTitulo } from './styles'

interface LogoProps {
  horizontal?: boolean;
  tamanhoImagem: number;
  tamanhoTexto: string;
};

const LogoNossaDefensoria: React.FC<LogoProps> = ({ horizontal, tamanhoImagem, tamanhoTexto }) => (
  <Container horizontal={horizontal}>
    <ViewImage>
      <Image
        resizeMode="contain"
        style={{ width: tamanhoImagem, height: tamanhoImagem }}
        source={require('../../../assets/icon-header.png')}
      />
    </ViewImage>
    <ViewTitulo horizontal={horizontal}>
      <Titulo destacado tamanhoTexto={tamanhoTexto}>Nossa</Titulo>
      <Titulo tamanhoTexto={tamanhoTexto}>Defensoria</Titulo>
    </ViewTitulo>
  </Container>
);

export default LogoNossaDefensoria;