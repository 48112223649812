import React, { useState, useEffect } from 'react';
import { Image, ScrollView } from 'react-native';
import SafeAreaView from 'react-native-safe-area-view';
import { useAuth } from '../../contexts/auth';

import Button from '../../components/Button';
import Loading from '../../components/Loading';
import api from '../../services/apiNossaDefensoria';

import { 
  Container,
  Title,
  ListContainer,
  Subtitle,
  ListItem
} from './styles';

interface Nucleo {
  id: number;
  titulo: string;
  municipio_txt: string;
  nome: string;
};

const LocalAtendimento : React.FC = () => {

  const [ nucleos, setNucleos ] = useState<Nucleo[]>([]);
  const [ loading, setLoading ] = useState<boolean>(false);
  const { validateCode } = useAuth()

  useEffect(() => {
    setLoading(true)
    api.get('/contrib/api/nucleos', {
      params: {
        is_exibe_app:true
      }
    })
    .then((response) => {
      const { data: { results } } = response;
      setNucleos(results)
    })
    .finally(() => setLoading(false))
  }, [])

  const renderItem: React.FC<Nucleo> = ({id, titulo, municipio_txt}) => {
    return (
      <ListItem key={id}>{titulo} - {municipio_txt}</ListItem>
    )
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView 
        contentContainerStyle={{ 
          flexGrow: 1, 
          flexDirection: 'column',
          paddingVertical: 20,
          paddingHorizontal: 25
        }}
      >
        <Container>
          <Image
            resizeMode="contain"
            style={{ width: 100, height: 100, marginBottom: 10}}
            source={require('../../../assets/place.png')}
            />
          <Title>O Nossa Defensoria está trabalhando para viabilizar o atendimento da população em <Title bold>todas as regiões do estado do Ceará.</Title></Title>
          {nucleos && nucleos.length ? (
            <ListContainer>
              <Subtitle>No momento, os núcleos que atendem pelo aplicativo são:</Subtitle>
              <ScrollView 
                contentContainerStyle={{ 
                  paddingVertical: 10,
                  paddingHorizontal: 10
                }}
              >
              { nucleos.map((nucleo) => renderItem(nucleo))}
              </ScrollView>
            </ListContainer>
          ) : null}
          <Button
              onPress={() => validateCode()}
              >
              Entendi
          </Button>
        </Container>
      </ScrollView>
     <Loading show={loading}/>
    </SafeAreaView>
  )
};

export default LocalAtendimento;