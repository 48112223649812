import React, { useRef, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as Yup from 'yup';
import useBackKey from '../../hooks/backKey';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import LogoRodape from '../../components/LogoRodape';
import { AlertaSimples } from '../../components/Alert';
import LogoNossaDefensoria from '../../components/LogoNossaDefensoria';
import BackgroundInicio from '../../components/BackgroundInicio';
import getValidationErrors from '../../utils/getValidationErrors';
import {
  ContainerForm,
  TituloForm,
  ContainerTituloForm,
  Icon,
  BackButton,
  TextObservacao,
  ContainerTextObservacao,
  DinamicSection,
  ContainerLogo
} from './styles';
import { CAMPO_OBRIGATORIO, MIN_CARAC_CODIGO, MIN_CARAC_PASSWORD, SENHAS_DIFERENTES } from '../../constants/Validation';

import { useAuth } from '../../contexts/auth';


interface TokenFormData {
  token: string;
};

interface SenhaFormData {
  password: string;
  confirm_password: string;
};

const ConfirmarRecuperarSenha: React.FC = () => {
  const { loading, resetPasswordTestToken, resetPasswordConfirm } = useAuth();
  const [ token, setToken ] = useState<string | null>(null)
  const formTokenRef = useRef<FormHandles>(null);
  const formSenhaRef = useRef<FormHandles>(null);
  const [ showAlert, setShowAlert] = useState<boolean>(false)
  const { goBack, navigate } = useNavigation();

  useBackKey(goBack);

  const handleValidarToken = async (data: TokenFormData) => {
    try {
      formTokenRef.current?.setErrors({});

      const schema = Yup.object().shape({
        token: Yup.string().min(6, MIN_CARAC_CODIGO).required(CAMPO_OBRIGATORIO),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { token } = data;

      const params = { token };

      resetPasswordTestToken(params)
        .then(() => setToken(token))
      
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formTokenRef.current?.setErrors(errors);
        return;
      };
    };
  };


  const handleEnviarNovaSenha = async (data: SenhaFormData) => {
    try {
      formSenhaRef.current?.setErrors({});

      const schema = Yup.object().shape({
        password: Yup.string().min(8, MIN_CARAC_PASSWORD).required(CAMPO_OBRIGATORIO),
        confirm_password: Yup.string().min(8, MIN_CARAC_PASSWORD).required(CAMPO_OBRIGATORIO).oneOf([Yup.ref('password'), null], SENHAS_DIFERENTES),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { password } = data;

      const params = { password, token };
      resetPasswordConfirm(params).then(() => setShowAlert(true))
      
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formSenhaRef.current?.setErrors(errors);
        return;
      };
    };
  };

  return (
    <>
      <BackgroundInicio>
        <ContainerForm>
          <BackButton onPress={() => goBack() }>
            <Icon name="arrow-left" size={25} color="#fff" />
          </BackButton>
          
          <ContainerLogo>
            <LogoNossaDefensoria horizontal tamanhoImagem={62}  tamanhoTexto='20px'/>
          </ContainerLogo>
          
          <ContainerTituloForm>
            <TituloForm>Recuperação de <TituloForm destacado>senha</TituloForm></TituloForm>
          </ContainerTituloForm>

          <DinamicSection hide={token !== null}>
            <ContainerTextObservacao>
              <TextObservacao>Informe no campo abaixo o código que enviamos para o seu email.</TextObservacao>
            </ContainerTextObservacao>

            <Form ref={formTokenRef} onSubmit={handleValidarToken}>
              <Input
                autoCorrect={false}
                autoCapitalize="none"
                keyboardType='number-pad'
                name="token"
                placeholder="Código"
                fundoEscuro
              />
              <Button
                onPress={() => {
                  formTokenRef.current?.submitForm();
                }}
              >
                Enviar
              </Button>
            </Form>
          </DinamicSection>
          <DinamicSection hide={!token}>
            <ContainerTextObservacao>
              <TextObservacao>Agora informe a sua nova senha.</TextObservacao>
            </ContainerTextObservacao>

            <Form ref={formSenhaRef} onSubmit={handleEnviarNovaSenha}>
              <Input
                autoCorrect={false}
                autoCapitalize="none"
                name="password"
                passwordInput
                placeholder="Nova senha"
                fundoEscuro
              />
              <Input
                autoCorrect={false}
                autoCapitalize="none"
                name="confirm_password"
                passwordInput
                placeholder="Repita a senha"
                fundoEscuro
              />
              <Button
                onPress={() => {
                  formSenhaRef.current?.submitForm();
                }}
              >
                Enviar
              </Button>
            </Form>
          </DinamicSection>
        </ContainerForm>
        <LogoRodape />
        <Loading show={loading}/>
      
    </BackgroundInicio>
    <AlertaSimples
      titulo="Recuperação de senha"
      mensagem="Tudo certo. Agora você pode logar com a nova senha."
      show={showAlert}
      onConfirm={() => {
        setShowAlert(false);
        navigate('Entrar');
      }}
    />
   </>
  )
}

export default ConfirmarRecuperarSenha;