import React from 'react';
import { ScrollView } from 'react-native';
import SafeAreaView from 'react-native-safe-area-view';
import BotaoPerfilMenu from './components/BotaoPerfilMenu';
import Theme from '../../constants/Theme'
import { Background, FakeNavBar, FakeNavBarText } from '../../styles/globals';
import { ContainerButton } from './styles';

import { useAuth } from '../../contexts/auth';

const Perfil: React.FC = () => {
  const { logout } = useAuth();

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Theme.COLORS.BG_VERDE_ESCURO }}>
      <Background>
        <FakeNavBar>
          <FakeNavBarText>Perfil</FakeNavBarText>
        </FakeNavBar>
        <ContainerButton>
          <ScrollView>
            <BotaoPerfilMenu titulo='Alterar senha' pagina='MudarSenha' />
            <BotaoPerfilMenu titulo='Avalie o nosso aplicativo' pagina='Feedback' />
            <BotaoPerfilMenu titulo='Ajuda' pagina='Ajuda' />
            <BotaoPerfilMenu titulo='Sobre' pagina='Sobre' />
            <BotaoPerfilMenu titulo='Política de Privacidade' pagina='TermosDeUso' />
            <BotaoPerfilMenu titulo='Sair' handle={() => logout()} />
          </ScrollView>
        </ContainerButton>
      </Background>
    </SafeAreaView>
  );
};

export default Perfil;
