import React, { useRef, useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Checkbox from 'expo-checkbox';

import useBackKey from '../../hooks/backKey';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import LogoRodape from '../../components/LogoRodape';
import LogoNossaDefensoria from '../../components/LogoNossaDefensoria';
import BackgroundInicio from '../../components/BackgroundInicio';
import Theme from '../../constants/Theme';

import getValidationErrors from '../../utils/getValidationErrors';
import { useAuth } from '../../contexts/auth';

import {
  EMAIL_INVALIDO,
  CAMPO_OBRIGATORIO,
  MIN_CARAC_PASSWORD,
  SENHAS_DIFERENTES,
  MAX_CARAC_EMAIL,
  MAX_CARAC_NOME,
  MAX_CARAC_PRIMEIRO_NOME,
  PATERN_LETRAS,
  STRING_APENAS_LETRAS
} from '../../constants/Validation';
import {
  ContainerForm,
  BackButton,
  Icon,
  ContainerTituloForm,
  TituloForm,
  ContainerCheckBox,
  LinkTermos,
  TextLinkTermos,
  TextTermos,
  ContainerTermos,
  ContainerLogo,
  TextoCheckBox
} from './styles';

interface SignUpFormData {
  name: string;
  email: string;
  username: string;
  password: string;
  password_confirm: string;
  is_receber_notificacao: boolean;
  is_termo_aceito: boolean;
};


const Cadastrar: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { goBack, navigate } = useNavigation();
  const { signUp, loading, pendingValidationCode } = useAuth();
  const [ checkBoxNotif, setCheckBoxNotif ] = useState<boolean>(false)

  useBackKey(goBack);
  
  useEffect(() => {
    if(pendingValidationCode) {
      navigate('LoginCodigo')
    }
  }, [pendingValidationCode])

  const handleSignUp = async (data: SignUpFormData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().max(255, MAX_CARAC_NOME).matches(PATERN_LETRAS, STRING_APENAS_LETRAS).test("len", MAX_CARAC_PRIMEIRO_NOME, val => {
          const firstName = val?.split(' ')[0]
          if(firstName && firstName.length > 30) return false
          return true
        }).required(CAMPO_OBRIGATORIO),
        email: Yup.string().max(150, MAX_CARAC_EMAIL).email(EMAIL_INVALIDO).required(CAMPO_OBRIGATORIO),
        password: Yup.string().min(8, MIN_CARAC_PASSWORD).required(CAMPO_OBRIGATORIO),
        password_confirm: Yup.string().min(8, MIN_CARAC_PASSWORD).required(CAMPO_OBRIGATORIO).oneOf([Yup.ref('password'), null], SENHAS_DIFERENTES),
      });
      
      await schema.validate(data, {
        abortEarly: false,
      });
      
      const { name, email, password} = data;
      
      const params = {
        name,
        email,
        username: email,
        password,
        is_receber_notificacao: checkBoxNotif,
        is_termo_aceito: true,
        papel: 31
      };  

      signUp(params);
    
    } catch (err) {

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
    }
  };

  return (
    <BackgroundInicio>
      <ContainerForm>
        <BackButton onPress={() => goBack() }>
          <Icon name="arrow-left" size={25} color="#fff" />
        </BackButton>

        <ContainerLogo>
          <LogoNossaDefensoria horizontal tamanhoImagem={62}  tamanhoTexto='20px'/>
        </ContainerLogo>
        
        <ContainerTituloForm>
          <TituloForm>Criar <TituloForm destacado>Nova Conta</TituloForm></TituloForm>
        </ContainerTituloForm>

        <Form ref={formRef} onSubmit={handleSignUp}>
          <Input
            returnKeyType="next"
            autoCorrect={false}
            autoCapitalize="none"
            name="name"
            placeholder="Nome"
            accessible
            fundoEscuro
          />
          <Input
            returnKeyType="next"
            autoCorrect={false}
            autoCapitalize="none"
            name="email"
            placeholder="Email"
            fundoEscuro
            onChangeText={(text) => formRef.current?.setFieldValue('email', text.trim())}
          />
          <Input
            returnKeyType="next"
            autoCorrect={false}
            autoCapitalize="none"
            name="password"
            placeholder="Senha"
            fundoEscuro
            passwordInput
          />
          <Input
            returnKeyType="next"
            autoCorrect={false}
            autoCapitalize="none"
            name="password_confirm"
            placeholder="Repita a senha"
            fundoEscuro
            passwordInput
          />
          
          <Button onPress={() => formRef.current?.submitForm()} >
            Cadastrar-se
          </Button>

          <ContainerCheckBox>
            <Checkbox
              style={{ 
                marginRight: 8, 
                borderColor: Theme.COLORS.BORDER_BG_LEITURA,
                borderWidth: 0.5,
              }}
              value={checkBoxNotif}
              onValueChange={setCheckBoxNotif}
              color={checkBoxNotif ? Theme.COLORS.TXT_LEITURA_DOURADO : Theme.COLORS.ACTIVITY}
            />
            <TextoCheckBox>Desejo receber notificações por e-mail</TextoCheckBox>
          </ContainerCheckBox>
          <ContainerTermos>
            <TextTermos>Ao clicar em Cadastrar-se, você concorda com a nossa 
              <LinkTermos  onPress={() => navigate('TermosDeUso')}>
                <TextLinkTermos> Política de Privacidade</TextLinkTermos>
              </LinkTermos>
            </TextTermos>
          </ContainerTermos>
        </Form>
      </ContainerForm>
      <LogoRodape />
      <Loading show={loading}/>
    </BackgroundInicio>
  );
};

export default Cadastrar;
