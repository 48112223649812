import React from 'react';
import { StyleSheet } from 'react-native';
import AwesomeAlert from 'react-native-awesome-alerts';
import Theme from '../../constants/Theme'

interface AlertaMultiplo {
  show:boolean;
  titulo:string;
  mensagem: string;
  onCancel: Function;
  onConfirm: Function;
};

interface AlertaSimples {
  show:boolean;
  titulo:string;
  mensagem: string;
  onConfirm: Function;
};

export const AlertaConfirmacao: React.FC<AlertaMultiplo> = ({ show, onCancel, onConfirm, mensagem, titulo }) => (
    <AwesomeAlert
      show={show}
      showProgress={false}
      title={titulo}
      message={mensagem}
      closeOnTouchOutside={false}
      closeOnHardwareBackPress={false}
      showCancelButton={true}
      showConfirmButton={true}
      titleStyle={styles.titulo}
      messageStyle={styles.mensagem}
      cancelText="Não"
      confirmText="Sim"
      confirmButtonColor={Theme.COLORS.BG_BTN_DEFAULT}
      onCancelPressed={() => onCancel()}
      onConfirmPressed={() => onConfirm()}
    />
);

export const AlertaSimples: React.FC<AlertaSimples> = ({ show, onConfirm, mensagem, titulo }) => (
    <AwesomeAlert
      show={show}
      showProgress={false}
      title={titulo}
      message={mensagem}
      closeOnTouchOutside={false}
      closeOnHardwareBackPress={false}
      showCancelButton={false}
      showConfirmButton={true}
      titleStyle={styles.titulo}
      messageStyle={styles.mensagem}
      confirmText="Ok"
      confirmButtonColor={Theme.COLORS.BG_BTN_DEFAULT}
      onConfirmPressed={() => onConfirm()}
    />
);

const styles = StyleSheet.create({
  titulo: { fontFamily: 'roboto-bold' },
  mensagem: { textAlign: 'center', fontFamily: 'roboto-regular' },
});