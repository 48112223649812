import styled from 'styled-components/native';

export const ContainerRating = styled.View`
  margin-bottom: 15px;
`;

export const ObservacoesContainer = styled.View`
  text-align: justify;
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const ObservacoesTexto = styled.Text`
 font-size: 14px; 
 font-family: 'roboto-regular';
 margin-bottom: 10px;
 color: #707070;
`;
