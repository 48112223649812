import React, { useRef, useCallback, useState } from 'react';
import { ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import useBackKey from '../../hooks/backKey';

import Input from '../../components/Input';
import FabButton from '../../components/FabButton';
import Loading from '../../components/Loading';
import { AlertaSimples } from '../../components/Alert';

import getValidationErrors from '../../utils/getValidationErrors';
import { Background, TextContainer } from '../../styles/globals';
import { CAMPO_OBRIGATORIO, MIN_CARAC_PASSWORD, SENHAS_DIFERENTES } from '../../constants/Validation';

import { useAuth } from '../../contexts/auth';

interface MudarSenhaData {
  old_password: string;
  new_password: string;
  new_password_confirm: string;
};


const MudarSenha: React.FC = () => {
  const { goBack } = useNavigation();
  const formRef = useRef<FormHandles>(null);
  const { loading, changePassword } = useAuth();
  const [ showAlert, setShowAlert ] = useState<boolean>(false)

  useBackKey(goBack);

  const handleForm = useCallback(
    async (data: MudarSenhaData) => {
     
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          old_password: Yup.string().min(8, MIN_CARAC_PASSWORD).required(CAMPO_OBRIGATORIO),
          new_password: Yup.string().min(8, MIN_CARAC_PASSWORD).required(CAMPO_OBRIGATORIO),
          new_password_confirm: Yup.string().min(8, MIN_CARAC_PASSWORD).required(CAMPO_OBRIGATORIO).oneOf([Yup.ref('new_password'), null], SENHAS_DIFERENTES),
        });

        await schema.validate(data, { abortEarly: false });

        const { old_password, new_password } = data;

        const params = { old_password, new_password };

        changePassword(params)
          .then(() => setShowAlert(true));


      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    }, []);

  return (
    <Background>
      <TextContainer>  
        <ScrollView>
          <Form ref={formRef} onSubmit={handleForm}>
            <Input
              returnKeyType="next"
              autoCorrect={false}
              autoCapitalize="none"
              name="old_password"
              placeholder="Senha atual"
              passwordInput
            />
            <Input
              returnKeyType="next"
              autoCorrect={false}
              autoCapitalize="none"
              name="new_password"
              placeholder="Nova senha"
              passwordInput
            />
            <Input
              returnKeyType="next"
              autoCorrect={false}
              autoCapitalize="none"
              name="new_password_confirm"
              placeholder="Repita a nova senha"
              passwordInput
            />
          </Form>
        </ScrollView>
      </TextContainer>
      <AlertaSimples 
        titulo="Mudar senha"
        mensagem="Senha alterada com sucesso. Na próxima vez que logar use a nova senha."
        show={showAlert}
        onConfirm={() => {
          setShowAlert(false);
          goBack();
        }}
      />
      <FabButton iconName='check' onPress={() => formRef.current?.submitForm()} />
      <Loading show={loading}/>
    </Background>
  );
};

export default MudarSenha;
