import styled from 'styled-components/native';
import Theme from '../../../../constants/Theme';


interface TextoTopoProps {
  bold?: boolean;
};

interface ContainerProps {
  isLida?: boolean;
};

export const Container = styled.View<ContainerProps>`
  min-height: 100px;
  margin: 7px 0px;
  border-radius: 10px;
  background-color: ${props => props.isLida ? Theme.COLORS.BG_CARD_CINZA_CLARO : Theme.COLORS.BG_CARD_VERDE_BEBE};
  padding: 15px;
  justify-content: space-between;
`;

export const Topo = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const ViewTextoTopoTitulo = styled.View`
  flex: 1.7;
  margin-right: 20px;
`;

export const ViewTextoTopoData = styled.View`
`;

export const ViewTextoMeio = styled.View``;

export const Texto = styled.Text<TextoTopoProps>`
  font-family: ${props => props.bold ? 'roboto-bold' : 'roboto-regular'};
  font-size: 15px;
  color: ${Theme.COLORS.TXT_LEITURA};
`;


export const ViewClick = styled.TouchableOpacity`

`;
