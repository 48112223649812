import React from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import { AntDesign } from '@expo/vector-icons';

import { ButtonCantainer, ButtonView } from './styles';

interface Props {
  iconName: string;
  onPress: any;
}

const FabButon: React.FC<Props> = ({ iconName, onPress }) => {
  return (
    <ButtonCantainer>
      <TouchableWithoutFeedback onPress={onPress}>
       <ButtonView>
         <AntDesign name={iconName} size={24} color='#FFF' />
       </ButtonView>
      </TouchableWithoutFeedback>
    </ButtonCantainer>
  )
}

export default FabButon;