import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ScrollView } from 'react-native';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useAuth } from '../../../../contexts/auth';
import useBackKey from '../../../../hooks/backKey';
import getValidationErrors from '../../../../utils/getValidationErrors';
import api from '../../../../services/apiNossaDefensoria';
import Input from '../../../../components/Input';
import FabButton from '../../../../components/FabButton';
import Loading from '../../../../components/Loading';
import { Background, TextContainer } from '../../../../styles/globals';
import { getMessageErroSistemaCustom } from '../../../../utils/getShowMessage';
import { CAMPO_OBRIGATORIO } from '../../../../constants/Validation';
import  { ObservacoesContainer, ObservacoesTexto } from './styles';
import { AlertaSimples } from '../../../../components/Alert';

interface formData {
  justificativa: string;
}

const RemarcarAgendamento: React.FC = () => {
  const { user } = useAuth();
  const route = useRoute();
  const formRef = useRef<FormHandles>(null);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ showAlert, setShowAlert ] = useState<boolean>(false);
  const { goBack } = useNavigation();
  const [ agendamentoId, setAgendamentoId ] = useState(null);
  
  useBackKey(goBack);
  
  useEffect(() => {
    if(route.params) {
      const { agendamentoId } = route.params;
      if(agendamentoId) {
        setAgendamentoId(agendamentoId);
      }
    }
  }, [route])

  const handleForm = useCallback(
    async (data: formData) => {

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          justificativa: Yup.string().required(CAMPO_OBRIGATORIO)
        });

        await schema.validate(data, { abortEarly: false });
        const { justificativa } = data;

        setIsLoading(true);
        await api.post(`/atendimento/api/agendamento-processo/${agendamentoId}/remarcar_agendamento/?criado_por=${user?.id}&justificativa=${justificativa}`)
          .then((response) => setShowAlert(true))
          .catch((err) => {
            if(err?.response?.status === 500) {
              getMessageErroSistemaCustom('Erro!', 'Não foi possivel remarcar o agendamento. Tente novamente')
            }
          })
          .finally(() => setIsLoading(false))

      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    }, [agendamentoId]);
  
  return (
    <Background>
      <ScrollView>
        <TextContainer>  
          <Form ref={formRef} onSubmit={handleForm}>
            <ObservacoesContainer>
              <ObservacoesTexto>
                Nos diga o motivo porque você deseja reagendar.
              </ObservacoesTexto>
            </ObservacoesContainer>
            <Input
              name="justificativa"
              placeholder="Mensagem"
              isTextArea
              numberOfLines={7}
              multiline={true}
              // maxLength={400}
            />
          </Form>
        </TextContainer>
      </ScrollView>
      <FabButton iconName='check' onPress={() => formRef.current?.submitForm()} />
      <Loading show={isLoading}/>
      <AlertaSimples
        show={showAlert}
        titulo='Remarcar agendamento'
        mensagem='Solicitação de reagendamento enviada.'
        onConfirm={() => {
          setShowAlert(false);
          goBack();
        }}
      />
    </Background>
  );
}

export default RemarcarAgendamento;