import styled from 'styled-components/native';
import Theme from '../../constants/Theme';

interface TituloProps {
  destacado?: boolean
}

export const Container = styled.View`
  flex-direction: row;
  height: 70px;
  background: ${Theme.COLORS.BG_HEADER};
  justify-content: center;
  align-items: center;
`;

export const ViewImage = styled.View`
  margin-right: 10px;
`;

export const ViewTituloHeader = styled.View``;

export const TituloHeader = styled.Text<TituloProps>`
  font-size: 20px;
  color: ${Theme.COLORS.TXT_HEADER};
  font-family: ${props => props.destacado ? 'roboto-bold': 'roboto-thin'};
  margin-top: ${props => props.destacado ? '-2px': '0px'};
`;



