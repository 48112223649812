import React, { useCallback, useState } from 'react';
import { ScrollView } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import SafeAreaView from 'react-native-safe-area-view';
import { useNavigation } from '@react-navigation/native';

import { useNotification } from '../../contexts/notification';
import { useAuth } from '../../contexts/auth';
import api from '../../services/apiNossaDefensoria';
import BotaoDash from './components/BotaoDash';
import Header from '../../components/Header';
import SubHeader from './components/SubHeader';
import Theme from '../../constants/Theme';
import { AlertaSimples } from '../../components/Alert';
import { Background } from '../../styles/globals';
import { ButtonGroup, ContainerButton } from './styles';

const Dashboard: React.FC = () => {
  
  const { user } = useAuth();
  const { fetchNaoLidaCount } = useNotification();
  const [ showAlerta, setShowAlerta ] = useState<boolean>(false);
  const [ hasSolicitacoes, setHasSolicitacoes ] = useState(false);
  const { navigate } = useNavigation();
  
  const fetchSolicitacaoAgendamento = () => {
    api.get('/app-assistido/api/solicitacao-agendamento/', {
      params: {
        criado_por: user?.id,
        status__in: '-3,-2,-1'
      },
    })
    .then(response => {
      const { data } = response;
      setHasSolicitacoes(data?.count > 0);
    })
  };

  useFocusEffect(useCallback(() => { 
    fetchNaoLidaCount();
    fetchSolicitacaoAgendamento();
  }, []));

  return (
    <SafeAreaView  style={{ flex: 1, backgroundColor: Theme.COLORS.BG_VERDE_ESCURO }}>
      <>
        <Background>
          <Header />
          <SubHeader />
          <ContainerButton>
            <ScrollView>
              <BotaoDash titulo='Novo Atendimento' icone='novagenda' handle={() => hasSolicitacoes ? setShowAlerta(true) : navigate('NovoAgendamento')} />
              <ButtonGroup>
                <BotaoDash btnPequeno titulo='Meus Atendimentos' pagina='Agenda' icone='agenda' />
                <BotaoDash btnPequeno titulo='Meus Processos' pagina='Processos' icone='processo'/>
                <BotaoDash btnPequeno titulo='Meus Documentos' pagina='Documentos' icone='documento'/>
              </ButtonGroup>
            </ScrollView>
          </ContainerButton>
        </Background>
        <AlertaSimples 
          show={showAlerta}
          titulo="Atenção"
          mensagem="Você já tem uma solicitação de atendimento em andamento. Por favor aguarde o processo de classificação."
          onConfirm={() => setShowAlerta(false)}
        />
      </>
    </SafeAreaView>
  );
};

export default Dashboard;
