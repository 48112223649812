import styled from 'styled-components/native';
import { RectButton } from 'react-native-gesture-handler';
import Theme from '../../constants/Theme';

interface BotaoProps {
  btnSecundario?: boolean;
}

export const Container = styled(RectButton)<BotaoProps>`
  width: 100%;
  height: 60px;
  background: ${props => props.btnSecundario ? Theme.COLORS.BG_BTN_SECONDARY : Theme.COLORS.BG_BTN_DEFAULT};
  border-radius: 30px;
  margin-top: 20px;
  border: ${props => props.btnSecundario ? '1px solid #fff' : 'none'};
  justify-content: center;
  align-items: center;
`;

export const ButtonText = styled.Text`
  font-family: 'roboto-regular';
  color: ${Theme.COLORS.TXT_BTN_HOME};
  font-size: 18px;
`;
