import styled from 'styled-components/native';
import { TouchableWithoutFeedback } from 'react-native';
import Theme from '../../constants/Theme';

export const ContainerForm = styled.View``;

export const ContainerOpcoes = styled.View`
  margin-top: 20px;
`;

export const LinkEsqueciSenha = styled(TouchableWithoutFeedback)``;

export const ViewEsqueciSenha = styled.View`
  align-items: center;
`;

export const TextEsqueciSenha = styled.Text`
  align-items: center;
  font-family: 'roboto-regular';
  text-align: center;
  font-size: 14px;
  padding-bottom: 3px;
  color: ${Theme.COLORS.TXT_BTN_DEFAULT};
  text-decoration: underline ${Theme.COLORS.TXT_BTN_DEFAULT};
`;

export const LinkFeedback = styled(TouchableWithoutFeedback)``;

export const ViewFeedback = styled.View`
  align-items: center;
  margin-top: 25px;
`;

export const TextFeedback = styled.Text`
  align-items: center;
  font-family: 'roboto-regular';
  text-align: center;
  font-size: 14px;
  padding-bottom: 3px;
  color: ${Theme.COLORS.TXT_BTN_DEFAULT};
  text-decoration: underline ${Theme.COLORS.TXT_BTN_DEFAULT}
`;


