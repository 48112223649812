import api from '../services/apiNossaDefensoria';

export function login(username:string, password:string) {
  return api.post('/api/token/', {username, password});
};

export function getUser(userId:number) {
  return api.get(`/app-assistido/api/user/${userId}/`);
};

export function signUp(params:object) {
  return api.post('/app-assistido/api/user/', params)
}
export function resendCode(userId:number) {
  return api.patch(`/app-assistido/api/user/${userId}/`, {	is_enviar_codigo: true })
}

export function changePassword(params:object) {
  return api.post('/app-assistido/api/alterar-senha/', params)
}

export function resetPassword(params:object) {
  return api.post('/api/password_reset/', params)
}

export function resetPasswordTestToken(params:object) {
  return api.post('/api/password_reset/validate_token/', params)
}

export function resetPasswordConfirm(params:object) {
  return api.post('/api/password_reset/confirm/', params)
}