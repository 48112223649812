import React, { useCallback, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { ActivityIndicator } from 'react-native';
import SafeAreaView from 'react-native-safe-area-view';
import { useAuth } from '../../../contexts/auth';
import api from '../../../services/apiNossaDefensoria';
import { useNotification } from '../../../contexts/notification';

import { Background, FakeNavBar, FakeNavBarText } from '../../../styles/globals';
import { NotificacaoList } from './styles';
import { getMessageErroSistemaCustom } from '../../../utils/getShowMessage';

import ItemNotificacao from '../components/ItemNotificacao';
import ListaVazia from '../../../components/ListaVazia';
import Loading from '../../../components/Loading';
import Theme from '../../../constants/Theme';

export interface Notificacao {
  id: string;
  assunto: string;
  is_lida: boolean;
  texto_envio: string
  criado_em: string;
  tipo: number;
}

const Notificacao: React.FC = () => {
  const { user } = useAuth();
  const { fetchNaoLidaCount } = useNotification();
  const [ notificacoes, setNotificacoes ] = useState<Notificacao[]>([]);
  const [loading, setLoading ] = useState<boolean>(false);
  const [ refreshLoading, setRefreshLoading ] = useState<boolean>(false);

  const [loadingMore, setLoadingMore ] = useState<boolean>(false);
  const [page, setPage ] = useState<number>(1);

  const updateStatusNotificacao = (notificacoesRecebidas:Notificacao[]) => {
    const notificacoesIds = notificacoesRecebidas.filter((not) => not.is_lida === false).map((not) => not.id);

    if(notificacoesIds.length) {
      api.post('app-assistido/api/notificacao-atualizar-status/', {
        lista_ids: notificacoesIds
      })
      .then(() => {
        fetchNaoLidaCount()
      });
    };
  };

  const fetchInitialNotificacoes = (refreshReload: boolean) => {

    if(refreshReload) {
      setRefreshLoading(true) 
    } else {
      setNotificacoes([])
      setLoading(true)
    }
    
    api.get('/app-assistido/api/notificacao-user/', {
      params: {
        destinatario_user_app_id: user?.id
      }
    })
    .then(response => {
      const {data: { results, next } } = response;
      (next && !refreshReload) ? setPage(page + 1) : setPage(0);
      setNotificacoes(results);
      updateStatusNotificacao(results);
    })
    .catch(err => {
      if(err?.response?.status === 500) {
        getMessageErroSistemaCustom('Erro', 'Falha ao obter as notificações.')
      }
    })
    .finally(() => {
      setLoading(false);
      setRefreshLoading(false)
    });
  };

  const fetchMaisNotificacoes = () => {
    setLoadingMore(true)
    api.get('/app-assistido/api/notificacao-user/', {
      params: {
        destinatario_user_app_id: user?.id,
        page:page
      }
    })
    .then(response => {
      const {data: { results, next } } = response;
      next ? setPage(page + 1) : setPage(0)
      setNotificacoes([...notificacoes, ...results]);
      updateStatusNotificacao(results);
    })
    .catch(err => {
      if(err?.response?.status === 500) {
        getMessageErroSistemaCustom('Erro', 'Falha ao obter as notificações.')
      }
    })
    .finally(() => setLoadingMore(false));
  };

  useFocusEffect(useCallback(() => { 
    fetchInitialNotificacoes(false);
  }, []));

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Theme.COLORS.BG_VERDE_ESCURO }}>
      <Background>
        <FakeNavBar>
          <FakeNavBarText>Notificações</FakeNavBarText>
        </FakeNavBar>

        {notificacoes.length ? (
          <NotificacaoList
            data={notificacoes}
            keyExtractor={notificacao => String(notificacao.id)}
            onRefresh={() => fetchInitialNotificacoes(true)}
            refreshing={refreshLoading}
            renderItem={({ item: notificacao }) => (
              <ItemNotificacao 
                isLida={notificacao.is_lida}
                assunto={notificacao.assunto}
                mensagem={notificacao.texto_envio}
                dataCriacao={notificacao.criado_em}
                tipo={notificacao.tipo}
              />
            )}
            onEndReached={() => {
              if(page !== 0 && !loadingMore) fetchMaisNotificacoes()
            }}
            onEndReachedThreshold={0.1}
            ListFooterComponent={() => (
              loadingMore ? 
                <ActivityIndicator 
                  size="large"
                  color={Theme.COLORS.TXT_LEITURA_DOURADO} 
                  /> : null
            )}
          />
          ) : (
            !loading && !refreshLoading &&
              <ListaVazia 
                descricao=" Até o momento você não tem notificações." 
              />
            )}
        <Loading show={loading} />
      </Background>
    </SafeAreaView>
  );
};

export default Notificacao;
