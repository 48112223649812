import React, { useState, useCallback } from 'react';
import { useNavigation, useFocusEffect } from '@react-navigation/native';

import api from '../../../../services/apiNossaDefensoria';
import { useAuth } from '../../../../contexts/auth';

import FabButton from '../../../../components/FabButton';
import ListaVazia from '../../../../components/ListaVazia';
import Loading from '../../../../components/Loading';
import ItemAgenda from '../../components/ItemAgenda';
import SolicitacaoAviso from '../../components/SolicitacaoAviso';
import SolicitacaoReclassificadaAviso from '../../components/SolicitacaoReclassificadaAviso';
import SolicitacaoRejeitadaAviso from '../../components/SolicitacaoRejeitadaAviso';
import { AlertaConfirmacao, AlertaSimples } from '../../../../components/Alert';

import { AgendamentoProcesso, Solicitacao } from '../../../../types/Agenda';

import { getMessageErroSistemaCustom, getMessageSucessSaveCustom } from '../../../../utils/getShowMessage';
import { Background } from '../../../../styles/globals';
import { AgendamentoProcessoList } from './styles';
import useBackKey from '../../../../hooks/backKey';

interface StatusSolicitacao {
  emAgendamento: Array<number>;
  emClassificacao: Array<number>;
};

const ListaAgendamentos: React.FC = () => {
  const textoSemAgendamentos = 'No momento você não tem nenhum agendamento.';
  const { user } = useAuth();
  const { navigate, goBack } = useNavigation();
  const [ solicitacoes, setSolicitacoes ] = useState<Solicitacao[]>([]);
  const [ agendamentoProcessos, setAgendamentoProcessos ] = useState<AgendamentoProcesso[]>([]);
  const [ solicitacaoReclassificada, setSolicitacaoReclassificada ] = useState<Solicitacao | null>();
  const [ solicitacaoRejeitada, setSolicitacaoRejeitada ] = useState<Solicitacao | null>(null);
  const [ idAgendamento, setIdAgendamento ] = useState<number>();
  
  const [ showAlertaCancelar, setShowAlertaCancelar ] = useState<boolean>(false);
  const [ showAlertaSimples, setShowAlertaSimples ] = useState<boolean>(false);
  const [ statusSolicitacao, setStatusSolicitacao ] = useState<StatusSolicitacao>({} as StatusSolicitacao);
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ refreshLoading, setRefreshLoading ] = useState<boolean>(false);
  
  useBackKey(goBack);
  
  const fetchSolicitacaoAgendamento = () => {
    api.get('/app-assistido/api/solicitacao-agendamento/', {
      params: {
        criado_por: user?.id,
        status__in: '-4,-3,-2,-1'
      },
    })
    .then(response => {
      const { data } = response;
        setSolicitacoes(data.results);
        
        const solicitacaoReclassificadaFilter = data.results.filter((i:any) => i?.status === -3)[0];
        const solicitacaoRejeitadaFilter = data.results.filter((i:any) => i?.status === -4)[0];

        setSolicitacaoReclassificada(solicitacaoReclassificadaFilter);
        setSolicitacaoRejeitada(solicitacaoRejeitadaFilter);

        const status = {
          emAgendamento: data.results.filter((i:any) => i?.status === -1).map((e:any) => String(e.id).padStart(6, '0')),
          emClassificacao: data.results.filter((i:any) => i?.status === -2).map((e:any) => String(e.id).padStart(6, '0'))
        };
        setStatusSolicitacao(status)
    })
    .catch(err => {})
  };
  
  const fetchAgendamentoProcesso = (refreshReload: boolean) => {

    refreshReload ? setRefreshLoading(true) : setLoading(true);

    api.get('/atendimento/api/agendamento-processo/', {
      params: {
        users_app__id__in: user?.id
      },
    })
    .then(response => {
      const { data } = response;
      let ordenado: AgendamentoProcesso[] = []
      let agendados: AgendamentoProcesso[] = []
      let outros: AgendamentoProcesso[] = []

      data.results.forEach((e:AgendamentoProcesso) => {
        (e.status === 0) ? agendados.push(e) : outros.push(e)
      })
      ordenado = agendados.concat(outros)
      setAgendamentoProcessos(ordenado)
    })
    .catch(err => {
      if(err?.response?.status === 500) {
        getMessageErroSistemaCustom('Erro!', 'Não foi possivel obter os agendamentos.');
      }
    })
    .finally(() => {
      setLoading(false)
      setRefreshLoading(false)
    });
  };

  useFocusEffect(useCallback(() => { 
    fetchAgendamentoProcesso(false);
    fetchSolicitacaoAgendamento();
  }, []));


  const cancelar = useCallback(
    async() => {
      setShowAlertaCancelar(false)
      if(idAgendamento) {
        setLoading(true);
        await api.patch(`/atendimento/api/agendamento-processo/${idAgendamento}/`, { status: 5 })
          .then(() =>{
            getMessageSucessSaveCustom('Agendamento cancelado!');
            fetchAgendamentoProcesso(false);
          })
          .catch(err => {
            if(err?.response?.status === 500) {
              getMessageErroSistemaCustom('Erro!', 'Não foi possivel cancelar o agendamento.');
            }
          })
          .finally(() => setLoading(false))
      }
    }, [idAgendamento])

  const ocultarSolicitacaoRejeitada = useCallback(
    async() => {
        setLoading(true);
        await api.patch(`/app-assistido/api/solicitacao-agendamento/${solicitacaoRejeitada?.id}/`, { status: -5 })
          .then(() =>{
            fetchSolicitacaoAgendamento();
          })
          .finally(() => setLoading(false))
    }, [solicitacaoRejeitada])
  

  return (
    <Background>
      <>
        { solicitacoes.length ? 
          <SolicitacaoAviso
            idsEmAgendamento={statusSolicitacao.emAgendamento}
            idsEmClassificacao={statusSolicitacao.emClassificacao} 
          /> : null }
          
        { solicitacaoReclassificada ?
          <SolicitacaoReclassificadaAviso 
            acaoRevisar={() => {
              navigate('RevisarAgendamento', { 
                solicitacaoReclassificada,
              })
            }}
          /> : null }

        { solicitacaoRejeitada ? 
          <SolicitacaoRejeitadaAviso 
            motivo={solicitacaoRejeitada.motivo_reclassificacao}
            id={ String(solicitacaoRejeitada.id).padStart(6, '0')}
            acaoOcultar={ocultarSolicitacaoRejeitada}
            status={solicitacaoRejeitada.status}
            data={solicitacaoRejeitada.criado_em}
          /> : null }
        
        {agendamentoProcessos.length ? (
          <AgendamentoProcessoList
            data={agendamentoProcessos}
            keyExtractor={agenda => String(agenda.id)}
            onRefresh={() => {
              fetchAgendamentoProcesso(true)
              fetchSolicitacaoAgendamento()
            }}
            refreshing={refreshLoading}
            renderItem={({ item: agenda }) => (
              <ItemAgenda
                id={agenda.id}
                acaoCancelar={(id: number) => {
                  setShowAlertaCancelar(true);
                  setIdAgendamento(id);
                }}
                acaoRemarcar={(id:number) => {
                  navigate('RemarcarAgendamento', { 
                    agendamentoId: id,
                  })
                }} 
                data_agendamento={agenda?.data_agendamento}
                hora_agendamento={agenda?.hora_agendamento}
                status={agenda?.status}
                lotacao_fk={agenda?.lotacao_fk}
                acao_tipo_fk={agenda?.acao_tipo_fk}
                processo_fk={agenda?.processo_fk}
                solicitacao_agendamento_fk={agenda?.solicitacao_agendamento_fk}
              />
            )}
          />
        ) : (
          null
        )}

        { !loading && !solicitacoes.length && !agendamentoProcessos.length && <ListaVazia descricao={textoSemAgendamentos} icone="alert-circle" /> }

        <FabButton iconName='plus' onPress={() =>  solicitacoes.length ? setShowAlertaSimples(true) : navigate('NovoAgendamento')} />
        <Loading show={loading} />
        <AlertaConfirmacao 
          show={showAlertaCancelar}
          titulo="Cancelar Agendamento"
          mensagem="Você tem certeza que deseja cancelar o agendamento?"
          onCancel={() => setShowAlertaCancelar(false)}
          onConfirm={cancelar}
        />
        <AlertaSimples 
          show={showAlertaSimples}
          titulo="Atenção"
          mensagem="Você já tem uma solicitação de atendimento em andamento. Por favor aguarde o processo de classificação."
          onConfirm={() => setShowAlertaSimples(false)}
        />
      </>
    </Background>
  );
};

export default ListaAgendamentos;
