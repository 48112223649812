import React from 'react';
import {
  Container,
  ContainerTexto,
  Descricao,
  ContainerBotao,
  BotaoTexto
} from './styles';

interface SolicitacaoReclassificadaAvisoProps {
  acaoRevisar: Function;
}

const SolicitacaoReclassificadaAviso: React.FC<SolicitacaoReclassificadaAvisoProps> = ({ acaoRevisar }) => (
  <Container>
    <ContainerTexto>
      <Descricao>Há uma solicitação de atendimento que necessita de revisão!</Descricao>
    </ContainerTexto>
    <ContainerBotao onPress={() => acaoRevisar()}>
      <BotaoTexto>Revisar</BotaoTexto>
    </ContainerBotao>
  </Container>
);

export default SolicitacaoReclassificadaAviso;