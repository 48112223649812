import React, { useEffect, useState} from 'react';
import { useNavigation } from '@react-navigation/native';
import { ScrollView } from 'react-native'
import * as DocumentPicker from 'expo-document-picker';
import { useDocument } from '../../../../contexts/document';
import useBackKey from '../../../../hooks/backKey';
import Loading from '../../../../components/Loading';
import Button from '../../../../components/Button';
import DataCommon from '../../../../constants/DataCommon';
import { AlertaSimples } from '../../../../components/Alert';
import { getMessageErroSistemaCustom } from '../../../../utils/getShowMessage';

import { Background, TextContainer } from '../../../../styles/globals';

import {
  ArquivoList,
  BotaoAnexar,
  BotaoAnexarTexto,
  ContainerAnexar,
  LabelAnexar,
  Titulo,
  TituloContainer,
  ContainerItemArquivo,
  LabelItemArquivo,
  BotaoDeletar,
  IconBotaoDeletar,
  ContainerBotaoEntregar
} from './styles';

import { Arquivo } from '../../../../types/Documento';
import Theme from '../../../../constants/Theme';

interface AlertContext {
  titulo:string;
  mensagem: string;
}

const AnexarDocumentos: React.FC = () => {
  
  const { loading, refreshLoading, documentoSelecionadoId, enviarDocumento, documentos, removerDocumento, entregarDocumentos, fetchDocumentos } = useDocument();
  const [ arquivos, setArquivos ] = useState<Array<Arquivo>>([])
  const [ titulo, setTitulo] = useState<String>('');
  const [ showAlert, setShowAlert ] = useState<boolean>(false);
  const [ avisoMostrado, setAvisoMostrao ] = useState<boolean>(false);

  const [ alertContext, setAlertContext] = useState<AlertContext>({} as AlertContext);
  const { goBack } = useNavigation();
  
  useBackKey(goBack);

  useEffect(() => {
    const doc = documentos.find((doc) => doc.id === documentoSelecionadoId);
    
    if(doc) {
      
      const {
        arquivos,
        nome,
        acao,
        reprovacao_mensagem,
        reprovacao_titulo,
        solicitacao_mensagem
      } = doc;
      
      setArquivos(arquivos);
      setTitulo(nome);

      if(!avisoMostrado && 
        acao === DataCommon.ACAO_DOCUMENTO.REPROVADO && 
        reprovacao_titulo &&
        reprovacao_mensagem
      ) {
        const alertContext:AlertContext = {
          titulo: reprovacao_titulo,
          mensagem: reprovacao_mensagem
        }
        setAlertContext(alertContext);
        setShowAlert(true);

      } else if(!avisoMostrado && solicitacao_mensagem) {
        const alertContext:AlertContext = {
          titulo: 'Instruções',
          mensagem: solicitacao_mensagem
        }
        setAlertContext(alertContext);
        setShowAlert(true);
      }
    }
  }, [documentos, documentoSelecionadoId, avisoMostrado])

  const selecionarArquivo = () => {
    DocumentPicker.getDocumentAsync({ type: '.png,.jpg,.jpeg,.pdf' })
    .then((data:any) => {
      if(data && data.file) {
        const fileSize = (data?.size) ? data?.size / 1024 / 1024 : null;
        if(fileSize && fileSize < DataCommon.UPLOAD.TAMANHO_MAX_MIB) {
          enviarDocumento(data);
        } else {
          getMessageErroSistemaCustom('Erro!', `O arquivo deve ter no máximo ${DataCommon.UPLOAD.TAMANHO_MAX_MIB} Mb de tamanho.`);
        };
      } else {
        getMessageErroSistemaCustom('Erro!', 'Houve uma falha ao obter o documento. Por favor tente novamente');
      }
    }).catch(() => {
      getMessageErroSistemaCustom('Erro!', 'Houve uma falha ao obter o documento. Por favor tente novamente');
    })
  };
  
  const entregar = () => {
    entregarDocumentos().then(() => goBack())
  };

  return (
    <Background>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <TextContainer>
          <TituloContainer>
            <Titulo>{titulo}</Titulo>
          </TituloContainer>
          <ContainerAnexar>
            <LabelAnexar>Anexar os arquivos ao lado...</LabelAnexar>
            <BotaoAnexar onPress={selecionarArquivo}>
              <BotaoAnexarTexto>
                ANEXAR
              </BotaoAnexarTexto>
            </BotaoAnexar>
          </ContainerAnexar>
          {arquivos.length ? (
            <>
              <ArquivoList
                data={arquivos}
                keyExtractor={arquivo => String(arquivo.id)}
                refreshing={refreshLoading}
                onRefresh={() => fetchDocumentos(true)}
                renderItem={({ item: arquivo }) => (
                  <ContainerItemArquivo>
                    <LabelItemArquivo>{arquivo.arquivo}</LabelItemArquivo>
                    <BotaoDeletar onPress={() => removerDocumento(arquivo.id)}>
                      <IconBotaoDeletar name="x" size={25} color={Theme.COLORS.TXT_LEITURA} />
                    </BotaoDeletar>
                  </ContainerItemArquivo>
                )}
              />
              <ContainerBotaoEntregar>
                <Button onPress={entregar}>
                  ENTREGAR DOCUMENTOS
                </Button>
              </ContainerBotaoEntregar>
            </>
          ) : ( null )}
          
        </TextContainer>
      </ScrollView>
      <AlertaSimples
        show={showAlert}
        titulo={alertContext.titulo}
        mensagem={alertContext.mensagem}
        onConfirm={() => {
          setShowAlert(false);
          setAvisoMostrao(true);
        }}
      />
      <Loading show={loading} />
    </Background>
  );
};

export default AnexarDocumentos;


