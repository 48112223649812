import React, { useRef } from 'react';

import { useNavigation } from '@react-navigation/native';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as Yup from 'yup';
import useBackKey from '../../hooks/backKey';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import LogoRodape from '../../components/LogoRodape';
import LogoNossaDefensoria from '../../components/LogoNossaDefensoria';
import BackgroundInicio from '../../components/BackgroundInicio';
import getValidationErrors from '../../utils/getValidationErrors';
import {
  ContainerForm,
  TituloForm,
  ContainerTituloForm,
  Icon,
  BackButton,
  TextObservacao,
  ContainerTextObservacao,
  ContainerLogo
} from './styles';
import { EMAIL_INVALIDO, CAMPO_OBRIGATORIO } from '../../constants/Validation';

import { useAuth } from '../../contexts/auth';


interface SignInFormData {
  email: string;
};

const RecuperarSenha: React.FC = () => {
  const { loading, resetPassword } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const { goBack, navigate } = useNavigation();

  useBackKey(goBack);

  const handleEnviar = async (data: SignInFormData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string().email(EMAIL_INVALIDO).required(CAMPO_OBRIGATORIO),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { email } = data;

      const params = { email };

      resetPassword(params).then(() => navigate('ConfirmarRecuperarSenha'));
      
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      };
    };
  };


  return (
    <BackgroundInicio>
      <ContainerForm>
        <BackButton onPress={() => goBack() }>
          <Icon name="arrow-left" size={25} color="#fff" />
        </BackButton>
        <ContainerLogo>
          <LogoNossaDefensoria horizontal tamanhoImagem={62}  tamanhoTexto='20px'/>
        </ContainerLogo>
        
        <ContainerTituloForm>
          <TituloForm>Esqueci a <TituloForm destacado>senha</TituloForm></TituloForm>
        </ContainerTituloForm>

        <ContainerTextObservacao>
          <TextObservacao>Enviaremos um código para o e-mail informado abaixo para a recuperação do acesso</TextObservacao>
        </ContainerTextObservacao>

        <Form ref={formRef} onSubmit={handleEnviar}>
          <Input
            autoCorrect={false}
            autoCapitalize="none"
            name="email"
            placeholder="Email"
            fundoEscuro
            onChangeText={(text) => formRef.current?.setFieldValue('email', text.trim())}
          />
          <Button
            onPress={() => {
              formRef.current?.submitForm();
            }}
          >
            Enviar
          </Button>
        </Form>
      </ContainerForm>
      <LogoRodape />
      <Loading show={loading}/>
   </BackgroundInicio>
  )
}

export default RecuperarSenha;