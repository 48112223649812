import React from 'react';
import { Image } from 'react-native';
import { Container, Wrapper, Texto, Fechar, IconFechar, Indicador } from './styles';

interface ToastIOSProps {
  show: boolean;
  handle: Function;
}

const ToastIOS: React.FC<ToastIOSProps> = ({ show, handle }) => (
  show ? (
    <Container>
      <Wrapper>
        <Texto>Instale o app no seu Iphone: Clicando em 
        <Image
            resizeMode="contain"
            style={{ width: 20, height: 20, marginHorizontal: 5 }}
            source={require('../../../assets/icon-ios-upload.png')}
          /> 
          e depois clicando em 
          <Image
            resizeMode="contain"
            style={{ width: 20, height: 20, marginHorizontal: 5 }}
            source={require('../../../assets/icon-ios-install.png')}
          />
          para adicionar a sua tela de início.
        </Texto>
        <Fechar onPress={() => handle()}>
          <IconFechar name='x' size={20}/>
        </Fechar>
      </Wrapper>
      <Indicador></Indicador>
    </Container>
  ) : null
);

export default ToastIOS;
