import styled from 'styled-components/native';
import Theme from '../../constants/Theme';
import IconButton from 'react-native-vector-icons/Feather';

interface TextoProps {
  destacado?: boolean; 
}

export const ContainerForm = styled.View``;

export const BackButton = styled.TouchableWithoutFeedback``;

export const Icon = styled(IconButton)`
 z-index: 10;
`;

export const ContainerImagemCadeado = styled.View`
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const ImagemCadeado = styled.Image`
  width: 140px;
  height: 140px;
`;

export const ContainerObservacao = styled.View`
  margin-bottom: 15px;
`;

export const TextoObservacao = styled.Text<TextoProps>`
  font-family: 'roboto-regular';
  font-size: 14px;
  text-align: center;
  color: ${props => props.destacado ? Theme.COLORS.TXT_LEITURA_DOURADO : Theme.COLORS.TXT_LEITURA_BRANCO};
`;

export const ContainerReenviar = styled.View`
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const BtnReenviar = styled.TouchableOpacity``;

export const TxtBtnReenviar = styled.Text`
  font-family: 'roboto-regular';
  font-size: 14px;
  color: ${Theme.COLORS.TXT_LEITURA_DOURADO};
`;

export const TextoReenviar = styled.Text`
  font-family: 'roboto-regular';
  font-size: 14px;
  margin-right: 5px;
  color: ${Theme.COLORS.TXT_LEITURA_BRANCO};
`;

export const ContainerLogo = styled.View`
  margin-top: -25px;
`;