import React from 'react'
import { Platform } from 'react-native';

import BackHandler from '../utils/BackHandler';

/** This helper will call onBackPress when we press the hardware back button */
const useBackKey = (onBackPress?: () => void) => {
  React.useEffect(() => {
    if (!onBackPress || Platform.OS !== 'web') return
    const onBack = () => {
      onBackPress()
      return true
    }
    BackHandler.addEventListener('hardwareBackPress', onBack)
    return () => BackHandler.removeEventListener('hardwareBackPress', onBack)
  }, [onBackPress])
}

export default useBackKey
