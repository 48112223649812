import React, { useState, useCallback } from 'react';
import { useNavigation, useFocusEffect } from '@react-navigation/native';

import api from '../../../../services/apiNossaDefensoria';
import { useAuth } from '../../../../contexts/auth';

import ListaVazia from '../../../../components/ListaVazia';
import Loading from '../../../../components/Loading';
import SolicitacaoRejeitadaAviso from '../../components/SolicitacaoRejeitadaAviso';

import { Solicitacao } from '../../../../types/Agenda';

import { Background } from '../../../../styles/globals';
import { SolicitacoesFinalizadasList } from './styles';
import useBackKey from '../../../../hooks/backKey';

const ListaSolicitacoesFinalizadas: React.FC = () => {
  const textoSemAvisos = 'No momento você não tem nenhuma solicitação finalizada.';
  const { user } = useAuth();
  const { goBack } = useNavigation();
  const [ solicitacaoRejeitada, setSolicitacaoRejeitada ] = useState<Solicitacao[] >([]);
  
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ refreshLoading, setRefreshLoading ] = useState<boolean>(false);
  
  useBackKey(goBack);
  
  const fetchSolicitacaoAgendamento = (refreshReload: boolean) => {
    
    refreshReload ? setRefreshLoading(true) : setLoading(true);

    api.get('/app-assistido/api/solicitacao-agendamento/', {
      params: {
        criado_por: user?.id,
        status__in: '-5,-4'
      },
    })
    .then(response => {
      if(response?.data?.results) {
        setSolicitacaoRejeitada(response.data.results);
      }
    })
    .catch(err => {})
    .finally(() => {
      setLoading(false)
      setRefreshLoading(false)
    });
  };
  

  useFocusEffect(useCallback(() => { 
    fetchSolicitacaoAgendamento(false);
  }, []));


  const ocultarSolicitacaoRejeitada = useCallback(
    async(solicitacao:Solicitacao) => {
        setLoading(true);
        await api.patch(`/app-assistido/api/solicitacao-agendamento/${solicitacao.id}/`, { status: -5 })
          .then(() =>{
            fetchSolicitacaoAgendamento(false);
          })
          .finally(() => setLoading(false))
    }, [solicitacaoRejeitada])
  

  return (
    <Background>
      <>
        {solicitacaoRejeitada.length ? (
          <SolicitacoesFinalizadasList
            data={solicitacaoRejeitada}
            keyExtractor={solicitacao => String(solicitacao.id)}
            onRefresh={() => {
              fetchSolicitacaoAgendamento(true)
            }}
            refreshing={refreshLoading}
            renderItem={({ item: solicitacao }) => (
              <SolicitacaoRejeitadaAviso 
                motivo={solicitacao.motivo_reclassificacao}
                id={ String(solicitacao.id).padStart(6, '0')}
                data={solicitacao.criado_em}
                status={solicitacao.status}
                acaoOcultar={() => ocultarSolicitacaoRejeitada(solicitacao)}
              />
            )}
          />
        ) : (
          null
        )}

        { !loading && !solicitacaoRejeitada.length && <ListaVazia descricao={textoSemAvisos} icone="alert-circle" /> }

        <Loading show={loading} />
      </>
    </Background>
  );
};

export default ListaSolicitacoesFinalizadas;
