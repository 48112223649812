import React from 'react';
import { ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Constants from 'expo-constants';
import useBackKey from '../../hooks/backKey';
import { Background, TextContainer } from '../../styles/globals';
import { Texto, Rodape } from './styles';

const Sobre: React.FC = () => {
  const { goBack } = useNavigation();

  useBackKey(goBack);
  
  return (
    <Background>
      <ScrollView>
        <TextContainer>
          <Texto destacado>Olá, você conhece a Defensoria Pública do Estado do Ceará?</Texto>
          <Texto>
            Somos a instituição mais nova do Sistema de Justiça, criada e regulamentada pela 
            Lei Complementar Estadual nº 06/97, com o objetivo de prestar assistência jurídica gratuita e 
            educação em direito à população mais vulnerável.
          </Texto>
          <Texto>
            A Defensoria Pública é permanente e essencial à função jurisdicional do Estado, reforçando o exercício 
            do regime democrático por meio do serviço de orientação jurídica, promoção dos direitos humanos e efetivação 
            dos direitos fundamentais, individuais e coletivos, tanto de modo judicial como extrajudicial, de forma integral, 
            tendo assim um papel social cada vez mais relevante.
          </Texto>
          <Texto>
            Ao longo dos  24 anos de existência, preza pelo fortalecimento institucional a fim de assegurar e ampliar o acesso 
            de todos à justiça, reconhecendo a necessidade de estar cada vez mais perto da população.
          </Texto>
          <Texto>
            O aplicativo Nossa Defensoria é mais um canal de comunicação e otimização do acesso aos nossos serviços. 
            Com ele é possível que o usuário compartilhe suas demandas, solicitando orientações jurídicas, informações 
            processuais, realização de agendamentos e interaja com a Defensoria Pública Geral do Estado do Ceará.
          </Texto>
          <Texto>
            Para viabilizar os serviços, a Defensoria Pública Geral do Estado do Ceará poderá solicitar informações 
            pessoais como nome completo, data de nascimento, CPF, e-mail, número de telefone ou geolocalização. 
          </Texto>
          <Texto>
            É bom ter você conosco! Aproveite as funcionalidades do Nossa Defensoria.
          </Texto>
          <Rodape>
            <Texto destacado>Versão do aplicativo: {Constants.manifest.version}</Texto>
          </Rodape>
        </TextContainer>
      </ScrollView>
    </Background>
  );
};


export default Sobre;




