import React, { useRef, useCallback, useState, useEffect } from 'react';
import { ScrollView } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useAuth } from '../../../../contexts/auth';
import useBackKey from '../../../../hooks/backKey';

import Input from '../../../../components/Input';
import InputAutoComplete from '../../../../components/InputAutoComplete';
import FabButton from '../../../../components/FabButton';
import Loading from '../../../../components/Loading';

import { getMessageErroSistemaCustom } from '../../../../utils/getShowMessage';
import { CAMPO_OBRIGATORIO, EMAIL_INVALIDO, DATA_INVALIDA, PATERN_DATA } from '../../../../constants/Validation';
import { AlertaConfirmacao, AlertaSimples } from '../../../../components/Alert';
import { formataDataServer } from '../../../../utils/utils';
import getValidationErrors from '../../../../utils/getValidationErrors';
import api from '../../../../services/apiNossaDefensoria';

import { 
  maskCep,
  maskPhone,
  maskDate,
  maskCPF,
  maskProcesso,
  maskNumero,
  maskProtocolo
} from '../../../../utils/masks';

import {
  DinamicSection,
  ObservacoesContainer,
  ObservacoesTexto,
  SeparadorContainer,
  SeparadorTexto,
  ItemListaBotao,
  ItemListaBotaoText,
  ObsText,
  ContainerObsText,
  SpaceBlanck
} from './styles';

import { Background, TextContainer } from '../../../../styles/globals';

interface NovoAgendamentoData {
  nome_user_app: string;
  email: string;
  data_nascimento: string;
  cpf?: string;
  telefone: string;
  cep: string;
  bairro: string;
  descricao_do_problema: string;
  num_processo: string;
  municipio:string;
  estado_civil: string;
  profissao: string;
  num_protocolo: string;
  endereco: string;
  rg: string;
  // nome_social: string;
  nacionalidade: string;
  nome_mae: string;
};
interface Municipio {
  id: number;
  nome: string;
  municipio_estado: string;
};
interface EstadoCivil {
  id: number;
  titulo: string;
};
interface Profissao {
  id: number;
  titulo: string;
};

const NovoAgendamento: React.FC = () => {
  const navigation = useNavigation();
  const formRef = useRef<FormHandles>(null);
  const route = useRoute();
  const { user } = useAuth();
  const [ firstStepCompleted, setFirstStepCompleted ] = useState<boolean>(false);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ showAlerta, setShowAlerta ] = useState<boolean>(false);
  const [ sairMesmoAssim, setSairMesmoAssim ] = useState<boolean>(false);
  const [ showAlertaFinal, setShowAlertaFinal ] = useState<boolean>(false);
  const [ processoId, setProcessoId ] = useState(null)
  const [ municipios, setMunicipios ] = useState<Array<Municipio>>([]);
  const [ estadosCivis, setEstadosCivis ] = useState<Array<EstadoCivil>>([]);
  const [ profissoes, setProfissoes ] = useState<Array<Profissao>>([]);
  const [ municipioSelecionado, setMunicipioSelecionado ] = useState<Municipio>({} as Municipio);
  const [ estadoCivilSelecionado, setEstadoCivilSelecionado ] = useState<EstadoCivil>({} as EstadoCivil);
  const [ profissaoSelecionada, setProfissaoSelecionada ] = useState<Profissao>({} as Profissao);
  const [ municipioQuery, setMunicipioQuery ] = useState<string>('');
  const [ estadoCivilQuery, setEstadoCivilQuery ] = useState<string>('');
  const [ profissaoQuery, setProfissaoQuery ] = useState<string>('');
  
  useBackKey(navigation.goBack);

  useEffect(() => {
    navigation.addListener('beforeRemove', (e) => {
      if(localStorage.getItem('@NossaDefensoria:firstStepCompleted')) {
        e.preventDefault();
        setFirstStepCompleted(false);
        localStorage.removeItem('@NossaDefensoria:firstStepCompleted');
        return;
      }
      if(sairMesmoAssim) {
        navigation.dispatch(e.data.action);
        return;
      };
      
      const formData = formRef.current?.getData();
      const camposPreenchidos = Object.values(formData).some((item) => item !== '');

      if(camposPreenchidos) {
        e.preventDefault();
        setShowAlerta(true);
      };

      return function clean() {
        localStorage.removeItem('@NossaDefensoria:firstStepCompleted');
        navigation.removeListener('beforeRemove', () => {})
      };
    });
  }, [navigation, sairMesmoAssim])

  useEffect(() => {
    if(route.params) {
      const { numProcesso, processoId } = route.params;
      if(numProcesso) {
        formRef.current?.setFieldValue('num_processo', maskProcesso(numProcesso));
      }
      if(processoId) {
        setProcessoId(processoId);
      }
    }
  }, [route])

  const handleForm = useCallback(
    async (data: NovoAgendamentoData) => {
      if(firstStepCompleted) {
        const { num_processo, descricao_do_problema, num_protocolo } = data;
        if(descricao_do_problema.trim() === '') {
          getMessageErroSistemaCustom('Erro!', 'Preencha o campo mensagem e nos conte o que aconteceu com vocẽ.');
        } else if(num_protocolo.trim() !== '' && num_protocolo.trim().length < 13) {
          getMessageErroSistemaCustom('Erro!', 'O número do protocolo deve ter 13 caracteres.');
        } else if(num_processo.trim() !== '' && num_processo.trim().length < 25) {
          getMessageErroSistemaCustom('Erro!', 'O número de processo deve ter 25 caracteres.'); 
        } else {
          enviar(data);
        }
        return;
      };

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome_user_app: Yup.string().required(CAMPO_OBRIGATORIO),
          email: Yup.string().email(EMAIL_INVALIDO).required(CAMPO_OBRIGATORIO),
          data_nascimento: Yup.string().matches(PATERN_DATA, DATA_INVALIDA).required(CAMPO_OBRIGATORIO)
            .test('data_nascimento', 'Ano de nascimento inválido', (value) => {
              if(!!value) {
                const ano = value.split('/');
                const schema = Yup.number().min(1900).max(2020);
                return schema.isValidSync(Number(ano[2]));
              }
              return true;
            }),
          cpf: Yup.string().notRequired().test('cpf', 'CPF inválido', (value) => {
            if(!!value) {
              const schema = Yup.string().min(14);
              return schema.isValidSync(value);
            }
            return true;
          }),
          telefone: Yup.string().required(CAMPO_OBRIGATORIO).test('telefone', 'Telefone inválido', (value) => {
            if(!!value) {
              const schema = Yup.string().min(10);
              return schema.isValidSync(value.replace(/\D/g, ''));
            }
            return true;
          }),
          cep: Yup.string().notRequired().test('cep', 'CEP inválido', (value) => {
            if(!!value) {
              const schema = Yup.string().min(8);
              return schema.isValidSync(value.replace(/\D/g, ''));
            }
            return true;
          }),
          bairro: Yup.string().required(CAMPO_OBRIGATORIO),
          municipio: Yup.string().required(CAMPO_OBRIGATORIO),
        });

        await schema.validate(data, { abortEarly: false });
        setFirstStepCompleted(true);
        localStorage.setItem('@NossaDefensoria:firstStepCompleted', 'true')

      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    }, [firstStepCompleted]);

  const enviar = useCallback(
    async (data) => {
      const params = {
        ...data,
        tipo_atendimento: 1,
        is_termo_aceito: true,
        criado_por: user?.id,
        data_nascimento: formataDataServer(data.data_nascimento),
        telefone: data.telefone.replace(/\D/g, ''),
        cpf: data.cpf.replace(/\D/g, ''),
        cep: data.cep.replace(/\D/g, ''),
        num_processo: (data.num_processo.trim() === "") ? null : data.num_processo.replace(/\D/g, ''),
        processo: processoId ? processoId : null,
        municipio: municipioSelecionado.id,
        profissao: profissaoSelecionada?.id ? profissaoSelecionada.id : null,
        estado_civil: estadoCivilSelecionado.id ? estadoCivilSelecionado.id : null
      };
      setIsLoading(true)
      await api.post('/app-assistido/api/solicitacao-agendamento/', params)
        .then(response => {
          if (response.status === 201) {
            localStorage.removeItem('@NossaDefensoria:firstStepCompleted');
            setSairMesmoAssim(true);
            setShowAlertaFinal(true);
          }
        })
        .catch(err => {
          if(err?.response?.status === 500) {
            getMessageErroSistemaCustom('Erro!', 'Não foi possivel realizar o agendamento. Tente novamente.');
          }
        })
        .finally(() => setIsLoading(false));
      },
    [processoId, municipioSelecionado, estadoCivilSelecionado, profissaoSelecionada]
  );

  const aplicarMascara = (campo:string, texto:string) => {
    formRef.current?.setFieldValue(campo, texto);
  };

  const fetchMunicipio = (term:string) => {
    setMunicipioQuery(term);

    if(municipioSelecionado) {
      setMunicipioSelecionado({} as Municipio);
    };
    
    if(term) {
      api.get(`/contrib/api/municipios/?municipio_estado__icontains=${municipioQuery}`)
      .then((response) => {
        const { data: { results } } = response;
        setMunicipios(results)
      })
    } else {
      setMunicipios([]);
    }
  };

  const fetchEstadoCivil = (term:string) => {
    setEstadoCivilQuery(term);

    if(estadoCivilSelecionado) {
      setEstadoCivilSelecionado({} as EstadoCivil);
    };
    
    if(term) {
      api.get(`/contrib/api/estados-civis/?titulo__icontains=${estadoCivilQuery}`)
      .then((response) => {
        const { data: { results } } = response;
        setEstadosCivis(results)
      })
    } else {
      setEstadosCivis([]);
    }
  };

  const fetchProfissao = (term:string) => {
    setProfissaoQuery(term);

    if(profissaoSelecionada) {
      setProfissaoSelecionada({} as Profissao);
    };
    
    if(term) {
      api.get(`/contrib/api/profissoes/?titulo__icontains=${profissaoQuery}`)
      .then((response) => {
        const { data: { results } } = response;
        setProfissoes(results)
      })
    } else {
      setProfissoes([]);
    }
  };

  const renderMunicipioListItem = (item:any) => {
    return (
      <ItemListaBotao onPress={() => {
        setMunicipios([])
        setMunicipioSelecionado(item)
      }}>
        <ItemListaBotaoText>
          {item.municipio_estado}
        </ItemListaBotaoText>
      </ItemListaBotao>
    );
  };

  const renderEstadoCivilListItem = (item:any) => {
    return (
      <ItemListaBotao onPress={() => {
        setEstadosCivis([])
        setEstadoCivilSelecionado(item)
      }}>
        <ItemListaBotaoText>
          {item.titulo}
        </ItemListaBotaoText>
      </ItemListaBotao>
    );
  };
  
  const renderProfissaoListItem = (item:any) => {
    return (
      <ItemListaBotao onPress={() => {
        setProfissoes([])
        setProfissaoSelecionada(item)
      }}>
        <ItemListaBotaoText>
          {item.titulo}
        </ItemListaBotaoText>
      </ItemListaBotao>
    );
  };
  
  return (
    <Background>
      <ScrollView>
        <TextContainer>  
          <Form ref={formRef} onSubmit={handleForm}>
            <DinamicSection hide={firstStepCompleted}>
              <ContainerObsText>
                <ObsText>
                  * Campos obrigatórios
                </ObsText>
              </ContainerObsText>
              <Input
                returnKeyType="next"
                autoCorrect={false}
                autoCapitalize="none"
                name="nome_user_app"
                placeholder="Nome Completo*"
                maxLength={200}
              />
              {/* <Input
                returnKeyType="next"
                autoCorrect={false}
                autoCapitalize="none"
                name="nome_social"
                placeholder="Nome Social"
                maxLength={200}
              /> */}
              <Input
                returnKeyType="next"
                autoCorrect={false}
                autoCapitalize="none"
                name="nome_mae"
                placeholder="Nome da mãe"
                maxLength={200}
              />
              <Input
                returnKeyType="next"
                autoCorrect={false}
                autoCapitalize="none"
                name="email"
                placeholder="Email*"
                onChangeText={(text) => aplicarMascara('email', text.trim())}
              />
              <Input
                returnKeyType="next"
                autoCorrect={false}
                autoCapitalize="none"
                name="data_nascimento"
                keyboardType='number-pad'
                placeholder="Data de nascimento*"
                maxLength={10}
                onChangeText={(text) => aplicarMascara('data_nascimento', maskDate(text))}
              />
              <Input
                returnKeyType="next"
                autoCorrect={false}
                autoCapitalize="none"
                name="cpf"
                keyboardType='number-pad'
                placeholder="CPF"
                maxLength={14}
                onChangeText={(text) => aplicarMascara('cpf', maskCPF(text))}
              />
              <Input
                returnKeyType="next"
                autoCorrect={false}
                autoCapitalize="none"
                name="rg"
                keyboardType='number-pad'
                placeholder="RG"
                maxLength={25}
                onChangeText={(text) => aplicarMascara('rg', maskNumero(text))}
              />
              <Input
                returnKeyType="next"
                autoCorrect={false}
                autoCapitalize="none"
                name="nacionalidade"
                placeholder="Nacionalidade"
                maxLength={200}
              />
              <Input
                returnKeyType="next"
                autoCorrect={false}
                autoCapitalize="none"
                name="telefone"
                keyboardType='number-pad'
                placeholder="Telefone*"
                maxLength={14}
                onChangeText={(text) => aplicarMascara('telefone', maskPhone(text))}
              />
              <InputAutoComplete 
                autoCapitalize="none"
                autoCorrect={false}
                data={estadosCivis}
                rawValue={
                  estadoCivilSelecionado?.titulo ?
                  estadoCivilSelecionado?.titulo :
                  estadoCivilQuery
                }
                placeholder="Estado Civil"
                name="estado_civil"
                indexPosition={3}
                onChangeText={(text) => fetchEstadoCivil(text)}
                renderListItem={renderEstadoCivilListItem}
              />
              <InputAutoComplete 
                autoCapitalize="none"
                autoCorrect={false}
                data={profissoes}
                rawValue={
                  profissaoSelecionada?.titulo ?
                  profissaoSelecionada?.titulo :
                  profissaoQuery
                }
                placeholder="Profissão"
                indexPosition={2}
                name="profissao"
                onChangeText={(text) => fetchProfissao(text)}
                renderListItem={renderProfissaoListItem}
              />
               <InputAutoComplete 
                autoCapitalize="none"
                autoCorrect={false}
                data={municipios}
                rawValue={
                  municipioSelecionado?.municipio_estado ?
                  municipioSelecionado.municipio_estado :
                  municipioQuery
                }
                placeholder="Municipio*"
                name="municipio"
                indexPosition={1}
                onChangeText={(text) => fetchMunicipio(text)}
                renderListItem={renderMunicipioListItem}
              />
              <Input
                returnKeyType="next"
                autoCorrect={false}
                autoCapitalize="none"
                name="endereco"
                placeholder="Endereço completo com n° e complemento"
                maxLength={200}
              />
              <Input
                returnKeyType="next"
                autoCorrect={false}
                autoCapitalize="none"
                name="bairro"
                placeholder="Bairro*"
                maxLength={200}
              />
              <Input
                returnKeyType="next"
                autoCorrect={false}
                autoCapitalize="none"
                name="cep"
                keyboardType='number-pad'
                placeholder="CEP"
                maxLength={9}
                onChangeText={(text) => aplicarMascara('cep', maskCep(text))}
              />
              <SpaceBlanck></SpaceBlanck>
            </DinamicSection>
            <DinamicSection hide={!firstStepCompleted}>
              <ObservacoesContainer>
                <ObservacoesTexto>
                  Digite o número do protocolo que deseja o atendimento.
                </ObservacoesTexto>
              </ObservacoesContainer>
              <Input
                returnKeyType="next"
                keyboardType='number-pad'
                name="num_protocolo"
                placeholder="Número do protocolo"
                onChangeText={(text) => aplicarMascara('num_protocolo', maskProtocolo(text))}
                maxLength={13}
              />
              <SeparadorContainer>
                <SeparadorTexto>OU</SeparadorTexto>
              </SeparadorContainer>
              <ObservacoesContainer>
                <ObservacoesTexto>
                  Digite o número do processo que deseja o atendimento.
                </ObservacoesTexto>
              </ObservacoesContainer>
              <Input
                returnKeyType="next"
                keyboardType='number-pad'
                name="num_processo"
                placeholder="Número do processo"
                onChangeText={(text) => aplicarMascara('num_processo', maskProcesso(text))}
                maxLength={25}
              />
              <SeparadorContainer>
                <SeparadorTexto></SeparadorTexto>
              </SeparadorContainer>
              <ObservacoesContainer>
                <ObservacoesTexto>
                  Nos conte o que aconteceu com você.
                </ObservacoesTexto>
              </ObservacoesContainer>
              <Input
                name="descricao_do_problema"
                placeholder="Mensagem*"
                isTextArea
                numberOfLines={7}
                multiline={true}
              />
            </DinamicSection>
          </Form>
        </TextContainer>
      </ScrollView>
      <FabButton iconName='check' onPress={() => formRef.current?.submitForm()} />
      <Loading show={isLoading}/>
      <AlertaConfirmacao 
        titulo="Atenção"
        mensagem="Você realmente deseja retornar? Caso retorne irá perder os campos informados."
        show={showAlerta}
        onCancel={() => setShowAlerta(false)}
        onConfirm={() => {
          setShowAlerta(false);
          setSairMesmoAssim(true);
          setTimeout(() => navigation.goBack(), 100);
        }}
      />
      <AlertaSimples 
        titulo="Atenção"
        mensagem="Solicitação de agendamento enviada. Por favor aguarde o processo de triagem e classificação. Esse processo será feito durante nosso horário de funcionamento em dias úteis. (Segunda a sexta das 8h às 17h)."
        show={showAlertaFinal}
        onConfirm={() => {
          setShowAlertaFinal(false);
          navigation.goBack();
        }}
      />
    </Background>
  );
}

export default NovoAgendamento;