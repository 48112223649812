import React, { createContext, useState, useEffect, useContext } from 'react';
import { getMessageErroSistemaCustom, getMessageSucessSaveCustom } from '../utils/getShowMessage';
import { useAuth } from './auth';
import * as document from '../services/document';
import { Documento, FileSystem } from '../types/Documento'

import DataCommon from '../constants/DataCommon';

interface DocumentContextData {
  loading: boolean;
  refreshLoading: boolean;
  showAlertaEnviar: boolean;
  documentos: Array<Documento>;
  documentoSelecionadoId: number | null;
  fetchDocumentos: Function;
  setShowAlertaEnviar: Function;
  enviarDocumento: Function;
  setDocumentoSelecionadoId: Function;
  removerDocumento: Function;
  entregarDocumentos: Function;
};

const DocumentContext = createContext<DocumentContextData>({} as DocumentContextData);

export const DocumentProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const [ loading, setLoading ] = useState(false);
  const [ documentos, setDocumento ] = useState<Documento[]>([]);
  const [ refreshLoading, setRefreshLoading ] = useState<boolean>(false);
  const [ showAlertaEnviar, setShowAlertaEnviar ] = useState(false);
  const [ documentoSelecionadoId, setDocumentoSelecionadoId ] = useState<number | null>(null);

  function fetchDocumentos(refreshReload: boolean) { 
    const params = {
      params: {
        users_app__id__in: user?.id,
      },
    }
    refreshReload ? setRefreshLoading(true) : setLoading(true);
    document.fetchDocumentos(params)
      .then(response => {
        setDocumento(response.data.results);
      })
      .catch(err => {
        if(err?.response?.status === 500) {
          getMessageErroSistemaCustom('Erro!', 'Houve uma falha ao obter as solicitações de documentos. Por favor tente mais tarde.')
        }
      })
      .finally(() => {
        setLoading(false)
        setRefreshLoading(false)
      });
  };

  function enviarDocumento(documentoFileSystem:FileSystem) {
    if(documentoSelecionadoId) {
      const formData = new FormData();

      formData.append('documento', String(documentoSelecionadoId))
      formData.append('arquivo', documentoFileSystem?.file);

      setLoading(true);
      document.enviarDocumento(formData)
      .then(result => {
        // getMessageSucessSaveCustom('Documento anexado com sucesso.');
        fetchDocumentos(false);
      })
      .catch((err) => {
        if(err?.response?.status === 500) {
          getMessageErroSistemaCustom('Erro!', 'Houve uma falha ao anexar o documento. Por favor tente novamente.')
        }
      })
      .finally(() => setLoading(false))
    } 
  };

  function entregarDocumentos() {
    return new Promise<boolean>((resolve, reject) => {
      const params = {
        acao: DataCommon.ACAO_DOCUMENTO.ANEXADO_PELO_ASSISTIDO
      };
      
      if(documentoSelecionadoId) {
        setLoading(true);
        document.entregarDocumentos(documentoSelecionadoId, params)
        .then(result => {
          resolve(true)
        })
        .catch((err) => {
          if(err?.response?.status === 500) {
            getMessageErroSistemaCustom('Erro!', 'Houve uma falha ao anexar o documento. Por favor tente novamente.');
          }
        })
        .finally(() => setLoading(false))
      }
    });
    
  };

  function removerDocumento(id:number) {
    if(id) {
      setLoading(true);
      document.removerDocumento(id)
      .then(result => {
        fetchDocumentos(false);
      })
      .catch((err) => {
        if(err?.response?.status === 500) {
          getMessageErroSistemaCustom('Erro!', 'Houve uma falha ao remover o documento. Por favor tente novamente.');
        }
      })
      .finally(() => setLoading(false))
    };
  };


  return (
    <DocumentContext.Provider 
    value={{ 
      loading,
      refreshLoading,
      showAlertaEnviar,
      documentoSelecionadoId,
      documentos,
      fetchDocumentos,
      setShowAlertaEnviar,
      enviarDocumento,
      entregarDocumentos,
      setDocumentoSelecionadoId,
      removerDocumento,
    }}>
      {children}
    </DocumentContext.Provider>
  );
};


export function useDocument() {
  const context = useContext(DocumentContext)

  return context;
};