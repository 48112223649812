export const CAMPO_OBRIGATORIO: string = 'Campo obrigatório';
export const EMAIL_INVALIDO: string = 'Email inválido';
export const DATA_INVALIDA: string = 'Data inválida';
export const MIN_CARAC_PASSWORD: string = 'A senha deve ter no mínimo 8 caracteres';
export const MIN_CARAC_CODIGO: string = 'O código deve ter no mínimo 6 caracteres';
export const TERMO_OBRIGATORIO: string = 'Você deve aceitar os termos';
export const CODIGO_INVALIDO: string = 'Código inválido';
export const SENHAS_DIFERENTES: string = 'As senhas devem ser iguais';
export const MAX_CARAC_EMAIL: string = 'Máximo 150 caracteres';
export const MAX_CARAC_NOME: string = 'Máximo 255 caracteres';
export const MAX_CARAC_PRIMEIRO_NOME: string = 'O primeiro nome não pode ter mais de 30 caracteres';
export const STRING_APENAS_LETRAS: string = 'Não é permitido símbolos ou pontuações. Apenas letras';


export const PATERN_DATA: RegExp = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
export const PATERN_LETRAS: RegExp = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/;