import styled, { css } from 'styled-components/native';
import IconButton from 'react-native-vector-icons/Feather';
import Theme from '../../constants/Theme';

interface ContainerProps {
  isFocused: boolean;
  isErrored: boolean;
  isTextArea?: boolean;
  fundoEscuro?: boolean;
}

interface MessageErrorProps {
  fundoEscuro?: boolean;
}

export const Container = styled.View<ContainerProps>`
  width: 100%;
  height: 60px;
  padding: 0 16px;
  border-radius: ${props => props.isTextArea ? '10px' : '30px'};
  margin-bottom: 5px;
  margin-top: 10px;
  background: ${props => props.fundoEscuro ? Theme.COLORS.BG_WHITE_INPUT : Theme.COLORS.BG_DEFAULT_INPUT};

  flex-direction: row;
  align-items: center;
  
  ${props =>
    props.isTextArea &&
    css`
      height: 150px;
    `}
`;

export const TextInput = styled.TextInput`
  flex: 1;
  font-family: 'roboto-regular';
  color: ${Theme.COLORS.TXT_DEFAULT_INPUT};
  font-size: 16px;
`;

export const Icon = styled(IconButton)`
  margin-right: 5px;
`;

export const MessageErro = styled.Text<MessageErrorProps>`
  font-family: 'roboto-regular';
  color: ${props => props.fundoEscuro ? Theme.COLORS.MESSAGE_ERROR_INPUT_LIGHT : Theme.COLORS.MESSAGE_ERROR_INPUT};
  font-size: 12px;
  font-weight: bold;
`;

export const Contador = styled.Text`
  font-family: 'roboto-regular';
  font-size: 12px;
`;

export const Footer = styled.View`
  flex-direction: row;
  padding: 2px 15px;
  justify-content: space-between;
`;

