import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Image } from 'react-native';
import { Container, Titulo, ViewTitulo } from './styles';

interface Props {
  icone: string;
  titulo: string;
  pagina?: string;
  btnPequeno?: boolean;
  handle?: Function;
}

const BotaoDash: React.FC<Props> = ({ icone, titulo, pagina, btnPequeno, handle }) => {
  const { navigate } = useNavigation();
  const [preTitulo, posTitulo] = titulo.split(" ")

  const obterIcone = () => {
    const icones = {
      agenda: require('../../../../../assets/icon-calendar.png'),
      novagenda: require('../../../../../assets/icon-calendar-plus.png'),
      documento: require('../../../../../assets/icon-folder.png'), 
      processo: require('../../../../../assets/icon-law.png'),
      default: require('../../../../../assets/icon-folder.png')
    }
    return icones[icone] || icones['default']
  };

  const handleOnPress = () => {
    if(handle) {
      handle();
      return;
    }
    if(pagina) navigate(pagina);
  };

  return (
    <Container btnPequeno={btnPequeno} onPress={handleOnPress}>
      <Image
        resizeMode='contain'
        source={obterIcone()}
        style={{ width: 55, height: 55, marginRight: btnPequeno ? 0 : 20 }}
      />
      <ViewTitulo btnPequeno={btnPequeno}>
        <Titulo btnPequeno={btnPequeno}>{preTitulo} <Titulo btnPequeno={btnPequeno} destacado>{posTitulo}</Titulo></Titulo>
      </ViewTitulo>
    </Container>
  );
};

export default BotaoDash;

