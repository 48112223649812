import styled from 'styled-components/native';

interface DinamicSectionProps {
  hide: boolean;
}

export const ObservacoesContainer = styled.View`
  font-family: 'roboto-regular';
  text-align: center;
  margin-bottom: 15px;
`;

export const ObservacoesTexto = styled.Text`
 font-size: 18px;
`;

export const SeparadorContainer = styled.View`
  font-family: 'roboto-regular';
  text-align: center;
  margin-bottom: 15px;
`;

export const SeparadorTexto = styled.Text`
 font-size: 20px;
`;

export const DinamicSection = styled.View<DinamicSectionProps>`
  display: ${props => props.hide ? "none" : "block" };
`;


export const ItemListaBotao = styled.TouchableOpacity`
  height: 30px;
`;

export const ItemListaBotaoText = styled.Text`
   font-family: 'roboto-regular';
   font-size: 14px;
   padding: 5px;
`;

export const ObsText = styled.Text`
   font-family: 'roboto-regular';
   font-size: 14px;
`;

export const ContainerObsText = styled.View`
  padding: 0px 5px 10px 5px;
`;

export const SpaceBlanck = styled.View`
  padding: 40px 0px 40px 0px;
`;