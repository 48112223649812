import styled from 'styled-components/native';
import Theme from '../../constants/Theme';

interface TituloProps {
  destacado?: boolean;
  tamanhoTexto: string;
};

interface ContainerProps {
  horizontal?: boolean;
};

export const Container = styled.View<ContainerProps>`
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  flex-direction:  ${props => props.horizontal ? 'row' : 'column'};
`;

export const ViewImage = styled.View`
  padding: 5px;
`;

export const ViewTitulo = styled.View<ContainerProps>`
  justify-content: center;
  align-items: ${props => props.horizontal ? 'flex-start' : 'center'};
`;

export const Titulo = styled.Text<TituloProps>`
  font-size: ${props => props.tamanhoTexto};
  color: ${Theme.COLORS.TXT_HEADER};
  font-family: ${props => props.destacado ? 'roboto-bold': 'roboto-thin'};
  margin-top: ${props => props.destacado ? '-4px': '0px'};
`;