import React from 'react';

import { maskProcesso } from '../../../../utils/masks'

import {
  Container,
  Rodape,
  Topo,
  Botao,
  BotaoTexto,
  ContainerBotoes,
  ViewTextoTopo,
  TextoTopo
} from './styles';

interface ItemProcessoProps {
  id: number;
  numeroProcesso: string;
  numeroProtocolo: string;
  acaoTipo: string;
  possuiSolicitacaoCod:number;
  handleSolicitar: Function;
}

const ItemProcesso: React.FC<ItemProcessoProps> = ({ 
  id,
  numeroProcesso,
  numeroProtocolo,
  acaoTipo,
  possuiSolicitacaoCod,
  handleSolicitar,
}) => (
  <Container>
    <Topo>
      <ViewTextoTopo>
        <TextoTopo bold>{acaoTipo}</TextoTopo>
      </ViewTextoTopo>
      <ViewTextoTopo>
        <TextoTopo bold>Protocolo: <TextoTopo>{numeroProtocolo}</TextoTopo></TextoTopo>
      </ViewTextoTopo>
      <ViewTextoTopo>
        {numeroProcesso ? (
          <TextoTopo bold>Processo: <TextoTopo>{maskProcesso(numeroProcesso)}</TextoTopo></TextoTopo>
        ) : (
          <TextoTopo bold>Processo: <TextoTopo>Sem número vinculado</TextoTopo></TextoTopo>
        )}
      </ViewTextoTopo>
    </Topo>
    <Rodape>
      {possuiSolicitacaoCod === 1 ? 
        (
          <ViewTextoTopo>
            <TextoTopo bold>Atendimento em andamento</TextoTopo>
          </ViewTextoTopo>
        ) : 
        (
          <ContainerBotoes>
            <Botao onPress={() => handleSolicitar(id)}>
              <BotaoTexto>Solicitar atendimento</BotaoTexto>
            </Botao>
          </ContainerBotoes>
        ) 
      }
    </Rodape>
  </Container>
);

export default ItemProcesso;