import styled from 'styled-components/native';
import Theme from '../../../../constants/Theme';


export const ObservacoesContainer = styled.View`
  text-align: center;
  margin-bottom: 15px;
`;

export const ObservacoesTexto = styled.Text`
 font-size: 18px; 
 font-family: 'roboto-regular';
 margin-bottom: 10px;
`;

export const ObservacoesMotivo = styled.Text`
 font-size: 18px;
 color: ${Theme.COLORS.BG_CARD_DOURADO};
 font-family: 'roboto-bold';
`;
