import React from 'react';
import LoggedRoutes from '../routes/logged.routes';
import LoggedOutRoutes from '../routes/loggedOut.routes';
import { useAuth } from '../contexts/auth';
import { View, ActivityIndicator } from 'react-native';
import Theme from '../constants/Theme';

const Routes: React.FC = () => {
  const { signed, loadingStore } = useAuth();

  if(loadingStore) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <ActivityIndicator size="large" color={Theme.COLORS.TXT_LEITURA_DOURADO} />
      </View>
    );
  };

  return signed ? <LoggedRoutes /> : <LoggedOutRoutes />;
};

export default Routes;
