export const maskCep = (value: string) => value.replace(/\D/g, '').replace(/^(\d{5})(\d)/, "$1-$2");

export const maskPhone = (value: string) => value.replace(/\D/g, '').replace(/^(\d{2})(\d)/, "($1) $2");

export const maskDate = (value: string) => value.replace(/\D/g, '').replace(/(\d{2})(\d)/, "$1/$2").replace(/(\d{2})(\d)/, "$1/$2");

export const maskCPF = (value: string) =>  value.replace(/\D/g, '').replace(/(\d{3})(\d)/,"$1.$2").replace(/(\d{3})(\d)/,"$1.$2").replace(/(\d{3})(\d{1,2})$/,"$1-$2");

export const maskNumero = (value: string) =>  value.replace(/\D/g, '');

export const maskProcesso = (value: string) => value.replace(/\D/g, '').replace(/(\d{7})(\d{2})(\d{4})(\d{1})(\d{2})(\d)/,"$1-$2.$3.$4.$5.$6");

export const maskProtocolo = (value: string) => value.replace(/\D/g, '').replace(/(\d{8})(\d)/,"$1/$2");