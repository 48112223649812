import React, { useRef } from 'react';
import { useNavigation } from '@react-navigation/native';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import getValidationErrors from '../../utils/getValidationErrors';
import { CODIGO_INVALIDO } from '../../constants/Validation';
import { useAuth } from '../../contexts/auth';
import useBackKey from '../../hooks/backKey';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import LogoRodape from '../../components/LogoRodape';
import LogoNossaDefensoria from '../../components/LogoNossaDefensoria';
import BackgroundInicio from '../../components/BackgroundInicio';

import {
  ContainerForm,
  BackButton,
  Icon,
  ContainerObservacao,
  TextoObservacao,
  ContainerReenviar,
  TextoReenviar,
  BtnReenviar,
  TxtBtnReenviar,
  ImagemCadeado,
  ContainerImagemCadeado,
  ContainerLogo
} from './styles';

interface SignUpFormData {
  codigo: string;
};

const LoginCodigo: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { goBack, navigate } = useNavigation();
  const { pendingValidationCode, validateCode, user, loading, resendCode } = useAuth()

  useBackKey(goBack);

  const handleVerificar = async (data: SignUpFormData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        codigo: Yup.string().oneOf([pendingValidationCode, null], CODIGO_INVALIDO)
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      navigate('LocalAtendimento');
      
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
    }
  };

  return (
    <BackgroundInicio>
      <ContainerForm>
        <BackButton onPress={() => goBack() }>
          <Icon name="arrow-left" size={25} color="#fff" />
        </BackButton>
        <ContainerLogo>
          <LogoNossaDefensoria horizontal tamanhoImagem={62} tamanhoTexto='20px'/>
        </ContainerLogo>
        <ContainerImagemCadeado>
          <ImagemCadeado source={require('../../../assets/padlock.png')} />
        </ContainerImagemCadeado>
        <ContainerObservacao>
          <TextoObservacao>Importante! Confira o código de validação enviado para o email <TextoObservacao destacado>{`"${user?.email}"`}</TextoObservacao> e informe no campo abaixo.</TextoObservacao>
          <TextoObservacao>O acesso só será possível após validação:</TextoObservacao>
        </ContainerObservacao>
        <Form ref={formRef} onSubmit={handleVerificar}>
          <Input
            returnKeyType="next"
            autoCorrect={false}
            autoCapitalize="none"
            name="codigo"
            maxLength={6}
            placeholder="Código de validação"
            keyboardType='number-pad'
            fundoEscuro
            accessible
            accessibilityHint="Campo para código validador"
          />
          <Button onPress={() => formRef.current?.submitForm()}>
            Validar código
          </Button>
          <ContainerReenviar>
            <TextoReenviar>
              Não recebeu o código?
            </TextoReenviar>
            <BtnReenviar onPress={() => resendCode()}>
              <TxtBtnReenviar>
                REENVIAR
              </TxtBtnReenviar>
            </BtnReenviar>
          </ContainerReenviar>
        </Form>
      </ContainerForm>
      <LogoRodape />
      <Loading show={loading}/>
    </BackgroundInicio>
  );
};

export default LoginCodigo;
