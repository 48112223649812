import React from 'react';
import Moment from 'moment';
import { useNavigation } from '@react-navigation/native';
import DataCommon from '../../../../constants/DataCommon';

import {
  Container,
  Topo,
  ViewTextoTopoTitulo,
  ViewTextoTopoData,
  Texto,
  ViewTextoMeio,
  ViewClick
} from './styles';

export interface NotificacaoProps {
  assunto: string;
  isLida: boolean;
  mensagem: string
  dataCriacao: string;
  tipo: number;
}

const ItemNotificacao: React.FC<NotificacaoProps> = ({
  assunto,
  isLida,
  mensagem,
  dataCriacao,
  tipo
}) => {
  const { navigate } = useNavigation()
  const atendimentoFinalizadoStr = 'Solicitação de atendimento finalizada';

  const handle = (tipo:number, asunto:string) => {
    switch(tipo) {
      case DataCommon.TIPO_NOTIFICACAO.SOLICITACAO_FINALIZADA:
        navigate('SolicitacoesFinalizadas');
        break;
      case DataCommon.TIPO_NOTIFICACAO.ATENDIMENTOS:
        (assunto == atendimentoFinalizadoStr) ? navigate('SolicitacoesFinalizadas') : navigate('Agenda');
        break;
      case DataCommon.TIPO_NOTIFICACAO.DOCUMENTOS:
        navigate('Documentos');
        break;
      case DataCommon.TIPO_NOTIFICACAO.PROCESSOS:
        navigate('Processos');
        break;
      default:
        break;
    };
  };

  return (
    <Container isLida={isLida}>
      <ViewClick onPress={() => handle(tipo, assunto)}>
        <Topo>
          <ViewTextoTopoTitulo>
            <Texto bold>{assunto}</Texto>
          </ViewTextoTopoTitulo>
          <ViewTextoTopoData>
            <Texto bold>{Moment(dataCriacao).format('DD/MM/YYYY')}</Texto>
          </ViewTextoTopoData>
        </Topo>
        <ViewTextoMeio>
          <Texto>{mensagem}</Texto>
        </ViewTextoMeio>
      </ViewClick>
    </Container>
  );
}

export default ItemNotificacao;