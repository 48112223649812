import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Container, Titulo, ViewTitulo } from './styles';

interface Props {
  titulo?: string;
  pagina?: string;
  handle?: Function;
}

const BotaoPerfilMenu: React.FC<Props> = ({ titulo, pagina, handle }) => {
  const { navigate } = useNavigation();

  const handleOnPress = () => {
    if(handle) {
      handle();
      return;
    }
    if(pagina && pagina === 'Feedback') {
      navigate(pagina, { local: 1 })
      return
    }
    if(pagina) navigate(pagina);
  };

  return (
    <Container onPress={handleOnPress}>
      <ViewTitulo>
        <Titulo>{titulo}</Titulo>
      </ViewTitulo>
    </Container>
  );
};

export default BotaoPerfilMenu;

