import styled from 'styled-components/native';

export const Container = styled.TouchableOpacity`
  width: 100%;
  height: 56px;
  justify-content: center;
`;

export const ViewTitulo = styled.View`
  border-bottom-width: 1px;
  border-bottom-color: #e0e0e0;
  height: 56px;
  justify-content: center;
`;

export const Titulo = styled.Text`
  font-size: 16px;
  font-family: 'roboto-bold'  
`;
