import styled from 'styled-components/native';
import Theme from '../../../../constants/Theme';

interface TextoProps {
  destacado?: boolean;
}

export const Container = styled.View`
  width: 100%;
  height: 83px;
  margin-bottom: 7px;
  justify-content: center;
  background: ${Theme.COLORS.BG_BTN_HOME};
  padding: 0 17px;
`;

export const TextoUserName = styled.Text<TextoProps>`
  font-size: 25px;
  color: ${Theme.COLORS.TXT_BTN_HOME};
  font-family: ${ props => props.destacado ? 'roboto-bold': 'roboto-thin' };
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TextoSaudacao = styled.Text`
  font-size: 12px;
  color: ${Theme.COLORS.TXT_BTN_HOME};
  font-family: 'roboto-thin';  
`;

