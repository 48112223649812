import styled from 'styled-components/native';
import Theme from '../../../../constants/Theme';

export const Wrapper = styled.View`
  padding-top: 15px;
`;

export const Container = styled.View`
  margin: 0px 15px 7px 15px;
  border-radius: 10px;
  background-color: ${Theme.COLORS.BG_CARD_DOURADO};
  padding: 15px 15px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Descricao = styled.Text`
  font-family: 'roboto-regular';
  font-size: 14px;
  color: #292929;
`;

export const ContainerTexto = styled.View`
  margin-bottom: 5px;
`;

export const Bloco = styled.View`
  flex: 1;
`;
