import api from '../services/apiNossaDefensoria';

export function fetchDocumentos(params:Object) {
  return api.get('/atendimento/api/documento-assistido/', params);
};

export function entregarDocumentos(id:number, params:Object) {
  return api.patch(`/atendimento/api/documento-assistido/${id}/`, params);
};

export function enviarDocumento(body:Object) {
  return api.post('/atendimento/api/multiplos-documentos/', body);
};

export function removerDocumento(id:number) {
  return api.delete(`/atendimento/api/multiplos-documentos/${id}`);
};