import styled from 'styled-components/native';
import Theme from '../constants/Theme';

export const SpinReload = styled.View`
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;

export const Background = styled.View`
  flex: 1;
  background: ${Theme.COLORS.BG_DEFAULT};
`;

export const BackgroundTexto = styled.View`
  flex: 1;
  background: ${Theme.COLORS.BG_LEITURA};
  padding: 10px;
  border: 2px solid ${Theme.COLORS.BORDER_BG_LEITURA};
`;

export const TextContainer = styled.View`
  flex: 1;
  padding: ${Theme.SIZES.PD_SCREEN_DEFAULT};
`;

export const TextContainerInicio = styled.View`
  flex: 1;
  padding: ${Theme.SIZES.PD_SCREEN_INICIO};
`;

export const TextDefault = styled.Text`
  font-family: 'roboto-regular';
  color: ${Theme.COLORS.TXT_LEITURA};
  font-size: 17px;
  margin-bottom: 10px;
  text-align: justify;
`;

export const TextDefaultBold = styled(TextDefault)`
  font-weight: bold;
`;

export const TextDefaulTittle = styled(TextDefault)`
  text-align: left;
  font-weight: bold;
`;

export const TextDefaultLeft = styled(TextDefault)`
  text-align: left;
`;

export const TextDefaulTittleCenter = styled(TextDefaulTittle)`
  text-align: center;
  text-decoration: underline;
  margin-top: 20px;
  margin-bottom: 15px;
`;

export const TextHugeTittleCenter = styled.Text`
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  color: ${Theme.COLORS.TXT_LINK_BG};
  padding: 5px 0 5px 0;
  background-color: ${Theme.COLORS.BG_LINK_BG};
  margin-bottom: 5px;
`;

export const TextHugeTittle = styled(TextHugeTittleCenter)`
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 0;
  font-size: 18px;
`;

export const FakeNavBar = styled.View`
  background-color: ${Theme.COLORS.BG_HEADER};
  height: 64px;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`;

export const FakeNavBarText = styled.Text`
  font-family: 'roboto-bold';
  font-size: 18px;
  color: ${Theme.COLORS.TXT_HEADER};
`;