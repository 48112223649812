/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { Image } from 'react-native';
import { Container, ViewImage, ViewTituloHeader, TituloHeader } from './styles';

const Header: React.FC = () => {
  return (
    <Container>
      <ViewImage>
        <Image
          resizeMode="contain"
          style={{ width: 50, height: 50 }}
          source={require('../../../assets/icon-header.png')}
        />
      </ViewImage>
      <ViewTituloHeader>
        <TituloHeader destacado>Nossa</TituloHeader>
        <TituloHeader>Defensoria</TituloHeader>
      </ViewTituloHeader>
    </Container>
  );
};

export default Header;
