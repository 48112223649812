import React from "react";
import { ActivityIndicator, StyleSheet, Text, View } from "react-native";
import { Container, Titulo } from './styles';


interface Props {
  show: boolean;
  title?: string;
}

const Loading: React.FC<Props> = ({ show, title='Carregando...' }) => (
  show ? (
    <Container>
      <ActivityIndicator size={60} color="#E0C387" />
      <Titulo>{title}</Titulo>
    </Container>
  ) : null
);

export default Loading;