import styled from 'styled-components/native';

export const ButtonCantainer = styled.View`
  align-items: center;
  position: absolute;
  bottom: 70px;
  right: 45px;
  z-index: 9999;
`;

export const ButtonView = styled.View`
  position: absolute;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: #007B50;
  shadow-radius: 10px;
  shadow-color: #002138;
  shadow-opacity: 0.3;
`;