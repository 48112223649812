import React, { createContext, useState, useEffect, useContext } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import jwt_decode from 'jwt-decode';
import { getMessageErroSistemaCustom, getMessageSucessSaveCustom } from '../utils/getShowMessage';
import * as auth from '../services/auth';
import api from '../services/apiNossaDefensoria';

interface AuthContextData {
  signed: boolean;
  user: User | null;
  loadingStore: boolean;
  loading: boolean;
  firstTimeInApp: boolean;
  pendingValidationCode: string | null;
  login: Function;
  logout: Function;
  signUp: Function;
  validateCode: Function;
  resendCode: Function;
  changePassword: Function;
  resetPassword: Function;
  resetPasswordTestToken: Function;
  resetPasswordConfirm: Function;
};

interface Payload {
  exp: string;
  jti: string;
  token_type: string;
  user_id: number;
};

interface User {
  id: number;
  name: string;
  email: string;
};

interface signUpData {
  name: string;
  email: string;
  username: string;
  password: string;
  is_receber_notificacao: boolean,
  is_termo_aceito: boolean,
};

interface Passwords {
  old_password: string;
  new_password: string;
};


const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [ user, setUser ] = useState<User | null>(null)
  const [ loadingStore, setLoadingStore ] = useState(true);
  const [ loading, setLoading ] = useState(false);
  const [ firstTimeInApp, setFirstTimeInApp ] = useState(false);
  const [ pendingValidationCode, setPendingValidationCode ] = useState<string | null>(null);

  useEffect(() => {
    async function loadStorage() {
      const storeToken = await AsyncStorage.getItem('@NossaDefensoria:token');
      const storeUser = await AsyncStorage.getItem('@NossaDefensoria:user');
      const storeCode = await AsyncStorage.getItem('@NossaDefensoria:code');
      const storeAppWasOpen = await AsyncStorage.getItem('@NossaDefensoria:appWasOpen');

      if(!storeAppWasOpen) {
        setFirstTimeInApp(true);
      }

      if(storeCode) {
        setPendingValidationCode(storeCode)
      };

      if(storeToken && storeUser) {
        setUser(JSON.parse(storeUser));

        api.defaults.headers.Authorization = `Bearer ${storeToken}`
        
        api.interceptors.response.use(
          response => responseSuccessHandler(response),
          error => responseErrorHandler(error)
        );
      };
      setLoadingStore(false);
    };

    loadStorage();
  }, []);

  function responseSuccessHandler(response) {
    return response;
  }

  function responseErrorHandler(error) {
    if (error?.response?.status === 401) {
      getMessageErroSistemaCustom('Erro', 'Sua sessão expirou. Por favor entre novamente.')
      logout();
    }
    return Promise.reject(error);
  }
  
  function getUser(user_id:number) {
    auth.getUser(user_id).then((responseUser) => {
      const { id,  name, email } = responseUser.data;
      setUser({id, name, email});
      AsyncStorage.setItem('@NossaDefensoria:user', JSON.stringify({id, name, email}));
    })
    .catch(() => {
      setUser({id: user_id, name: 'Visitante', email: ''})
      AsyncStorage.setItem('@NossaDefensoria:user', JSON.stringify({id: user_id, name: 'Visitante', email:''}));
    })
    .finally(() => setLoading(false));
  }

  function login(username:string, password:string) {
    setLoading(true);
    auth.login(username, password)
      .then((response) => {
        const { access } = response.data;
        const decode_payload: Payload = jwt_decode(access);
        const { user_id } = decode_payload;
    
        AsyncStorage.setItem('@NossaDefensoria:token', access);
    
        api.defaults.headers.Authorization = `Bearer ${access}`;
        
        getUser(user_id);

      }).catch((error) => {
        (error?.response?.status === 401 ) ?
          getMessageErroSistemaCustom('Erro', 'Não foi encontrado usuário com essas credenciais.'):
          getMessageErroSistemaCustom('Erro', 'Falha ao fazer o login tente novamente mais tarde.');
        
        setLoading(false);
      });
  };

  function logout() {
    AsyncStorage.clear().then(() => setUser(null));
    api.defaults.headers.Authorization = ''
  };

  function signUp(data:signUpData) {
    const { username, password } = data;
    setLoading(true);
    auth.signUp(data)
      .then((response) => {
        setLoading(false);
        const { codigo_validacao } = response.data;
        AsyncStorage.setItem('@NossaDefensoria:code', codigo_validacao);
        setPendingValidationCode(codigo_validacao);
        login(username, password);
      })
      .catch((err) => {
        if(err?.response?.status === 400 ) {
          const { username } = err.response.data
          if(username) {
            getMessageErroSistemaCustom('Erro', 'Já existe um usuário cadastrado com esse email');
          }
        } else if(err?.response?.status === 500){
          getMessageErroSistemaCustom('Erro', 'Houve um erro ao cadastrar o usuário');
        } else {
          getMessageErroSistemaCustom('Erro', 'Falha em conectar com o servidor');
        }
      })
      .finally(() => setLoading(false))
  };

  function validateCode() {
    setPendingValidationCode(null);
    AsyncStorage.removeItem('@NossaDefensoria:code');
  };

  function resendCode() {
    if(user?.id) {
      setLoading(true)
      auth.resendCode(user?.id)
        .then(() => {
          getMessageSucessSaveCustom('Código reenviado para o email cadastrado!')
        })
        .catch(err => {
          if(err?.response?.status === 500) {
            getMessageErroSistemaCustom('Erro!', 'Falha ao reenviar código. Tente novamente.');
          }
        })
        .finally(() => setLoading(false));
    }
  };

  function changePassword(passwords:Passwords) {
    return new Promise<boolean>((resolve, reject) => {
      setLoading(true)
      auth.changePassword(passwords)
        .then(() => {
          if(user?.email) login(user?.email, passwords.new_password);
          resolve(true);
        })
        .catch((err) => {
          if(err?.response?.status === 400) {
            const messageOldPassword = err?.response?.data?.old_password;
            if(messageOldPassword) getMessageErroSistemaCustom('Erro!', messageOldPassword);
          } else if(err?.response?.status === 500) {
            getMessageErroSistemaCustom('Erro!', 'Falha ao alterar a senha. Tente novamente.');
          }
        })
        .finally(() => setLoading(false));
    })
  };

  function resetPassword(passwords:Passwords) {
    return new Promise<boolean>((resolve, reject) => {
      setLoading(true)
      auth.resetPassword(passwords)
        .then(() => {
          getMessageSucessSaveCustom('Enviamos para o seu email o código de verificação.');
          resolve(true);
        })
        .catch((err) => {
          console.log(err.response)
          if(err?.response?.status === 400) {
            const email = err?.response?.data?.email;
            if(email) getMessageErroSistemaCustom('Erro!', 'Não encontramos uma conta associada a esse email.');
          } else if(err?.response?.status === 500) {
            getMessageErroSistemaCustom('Erro!', 'Falha ao enviar o email. Tente novamente.');
          }
        })
        .finally(() => setLoading(false));
    })
  };

  function resetPasswordTestToken(params:object) {
    return new Promise<boolean>((resolve) => {
      setLoading(true)
      auth.resetPasswordTestToken(params)
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          if(err?.response?.status === 404) {
            const status = err?.response?.data?.status;
            if(status === 'expired') {
              getMessageErroSistemaCustom('Erro!', 'Código expirado. Por favor recomece o processo.');
            } else if(status === 'notfound') {
              getMessageErroSistemaCustom('Erro!', 'Código inválido.');
            }
          }
        })
        .finally(() => setLoading(false));
    })
  };

  function resetPasswordConfirm(params:object) {
    return new Promise<boolean>((resolve) => {
      setLoading(true)
      auth.resetPasswordConfirm(params)
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          if(err?.response?.status === 400) {
            const message = err?.response?.data?.password;
            if(message) {
              getMessageErroSistemaCustom('Erro!', message[0]);
            } 
          } else if(err?.response?.status === 500){
            getMessageErroSistemaCustom('Erro!', 'Falha ao enviar a nova senha. Tente novamente.');
          }
        })
        .finally(() => setLoading(false));
    })
  };


  return (
    <AuthContext.Provider 
    value={{ 
      signed: !!user && !pendingValidationCode,
      user,
      loading,
      firstTimeInApp,
      loadingStore,
      pendingValidationCode,
      login,
      logout,
      signUp,
      validateCode,
      resendCode,
      changePassword,
      resetPassword,
      resetPasswordTestToken,
      resetPasswordConfirm
    }}>
      {children}
    </AuthContext.Provider>
  );
};


export function useAuth() {
  const context = useContext(AuthContext)

  return context;
};