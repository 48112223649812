import axios from 'axios';
import { API_NOSSA_DEFENSORIA } from "react-native-dotenv";

const api = axios.create({
  baseURL: API_NOSSA_DEFENSORIA,
  headers: {
    Accept: 'application/json',
  },
});

export default api;
