import styled from 'styled-components/native';
import Theme from '../../../../constants/Theme';
import { RectButton } from 'react-native-gesture-handler';

interface TituloProps {
  destacado?: boolean;
  btnPequeno?: boolean;
};

interface ContainerProps {
  btnPequeno?: boolean;
}

interface ViewTituloProps {
  btnPequeno?: boolean;
}

export const Container = styled(RectButton)<ContainerProps>`
  align-items: center;
  flex-direction: ${props => props.btnPequeno ? 'column' : 'row'};
  justify-content: ${props => props.btnPequeno ? 'center' : 'flex-start'};
  width: ${props => props.btnPequeno ? '31.5%' : '100%'};
  min-height: ${props => props.btnPequeno ? '120px' : '90px'};
  background: ${Theme.COLORS.BG_BTN_HOME};
  border-radius: 10px;
  margin-bottom: 7px;
  margin-top: 7px;
  padding: 0px 20px;
  border: none;
`;

export const Titulo = styled.Text<TituloProps>`
  color: ${Theme.COLORS.TXT_BTN_HOME};
  font-size: ${props => props.btnPequeno ? '12px' : '18px'};
  font-family: ${props => props.destacado ? 'roboto-bold': 'roboto-thin' };
  text-align: center;
`;

export const ViewTitulo = styled.View<ViewTituloProps>`
  margin-top: ${props => props.btnPequeno ? '10px' : '0px'};
`;