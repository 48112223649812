import { showMessage } from 'react-native-flash-message';

export function getMessageErroSistema() {
  return showMessage({
    message: 'Ocorreu um Erro!',
    description: 'Tente novamente mais tarde',
    type: 'danger',
    duration: 4500,
  });
}

export function getMessageErroSistemaCustom(titulo: string, descricao: string) {
  return showMessage({
    message: titulo,
    description: descricao,
    type: 'danger',
    duration: 4500,
  });
}

export function getMessageSucessSaveCustom(descricao: string) {
  return showMessage({
    message: 'Sucesso!',
    description: descricao,
    type: 'success',
    duration: 4500,
  });
}
