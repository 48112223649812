import React from 'react';
import { ScrollView, Linking } from 'react-native';
import useBackKey from '../../hooks/backKey';
import { Background, TextContainer } from '../../styles/globals';
import { useNavigation } from '@react-navigation/native';
import { TituloAjuda, TextoAjuda, Link, TextLink, Texto, Sessao, Divider } from './styles';
 
const Ajuda: React.FC = () => {
  const { goBack } = useNavigation();
  
  useBackKey(goBack);
  
  const openPhone = (phone: string) => {
    Linking.openURL(`tel:${phone}`)
  }
  

  return (
    <Background>
      <ScrollView>
        <TextContainer>
            {/* <Sessao>
              <Texto destacado>Não existe atendimento para o meu município, O que posso fazer?</Texto>
              <Texto>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque at elementum sem, sed gravida mi. Donec cursus eu dolor at placerat. 
                Cras id ullamcorper diam, vel venenatis odio. Nam commodo ut elit nec mollis.
                Phasellus non sapien posuere, dignissim urna ac, ultrices nunc. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer</Texto>
            </Sessao>
            <Divider></Divider> */}
            <TituloAjuda>Telefones Úteis</TituloAjuda>
            <TextoAjuda>Alô Defensoria: <Link onPress={() => openPhone('129')}><TextLink>129</TextLink></Link></TextoAjuda>
            <TextoAjuda>Alô Cidadão: <Link onPress={() => openPhone('0800857777')}><TextLink>0800-85-7777</TextLink></Link></TextoAjuda>
            <TextoAjuda>Alô Idoso: <Link onPress={() => openPhone('0800850022')}><TextLink>0800-85-0022</TextLink></Link></TextoAjuda>
            <TextoAjuda>Ouvidoria: <Link onPress={() => openPhone('32787307')}><TextLink>3278-7307</TextLink></Link></TextoAjuda>
        </TextContainer>
      </ScrollView>
    </Background>
  );
};

export default Ajuda;
