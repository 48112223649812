import styled from 'styled-components/native';
import IconButton from 'react-native-vector-icons/Feather';
import Theme from '../../constants/Theme';


export const Container = styled.View`
  position: absolute;
  bottom: 10px;
  width: 100%;
  padding: 10px;
`;


export const Wrapper = styled.View`
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  width: 100%;
  border-radius: 15px;
  background-color: ${Theme.COLORS.BG_CARD_CINZA_CLARO};
`;

export const Texto = styled.Text`
  font-family: 'roboto-regular';
  font-size: 16px;
  text-align: center;
`;

export const Fechar = styled.TouchableWithoutFeedback`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
`;

export const IconFechar = styled(IconButton)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const Indicador = styled.View`
  width: 0;
  height: 0;
  align-self: center;
  background-color: transparent;
  border-style: solid;
  border-left-width: 20px;
  border-right-width: 20px;
  border-bottom-width: 20px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: ${Theme.COLORS.BG_CARD_CINZA_CLARO};
  transform: rotate(180deg);
`;
