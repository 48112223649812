import styled from 'styled-components/native';

interface TextoProps {
  destacado?: boolean
};

export const Texto = styled.Text<TextoProps>`
  font-size: 14px;
  font-family: ${props => props.destacado ? 'roboto-bold': 'roboto-regular'};
  text-align: justify;
  margin-bottom: 7px;
  margin-top: 7px;
`;

export const Rodape = styled.View`
  margin-bottom: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  align-items: center;
`;