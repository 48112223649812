import styled from 'styled-components/native';
import Theme from '../../constants/Theme';

interface ContainerProps {
  index?: number,
  fundoEscuro?: boolean;
}

export const Container = styled.View<ContainerProps>`
  width: 100%;
  height: 60px;
  padding: 0 16px;
  border-radius: 30px;
  margin-bottom: 5px;
  margin-top: 10px;
  background: ${props => props.fundoEscuro ? Theme.COLORS.BG_WHITE_INPUT : Theme.COLORS.BG_DEFAULT_INPUT};
  flex-direction: row;
  align-items: center;
  z-index: ${props => props.index ? props.index : 1};
`;

export const MessageErro = styled.Text`
  font-family: 'roboto-regular';
  color: ${Theme.COLORS.MESSAGE_ERROR_INPUT};
  font-size: 12px;
  font-weight: bold;
`;

export const Footer = styled.View`
  flex-direction: row;
  padding: 2px 15px;
  justify-content: space-between;
`;
