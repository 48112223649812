import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useFocusEffect } from '@react-navigation/native';
import { DocumentoList } from './styles';
import { useDocument } from '../../../../contexts/document';
import useBackKey from '../../../../hooks/backKey';
import ListaVazia from '../../../../components/ListaVazia';
import Loading from '../../../../components/Loading';
import ItemDocumento from '../../components/ItemDocumento';

import { Background } from '../../../../styles/globals';

const ListaDocumentos: React.FC = () => {
  
  const { documentos, loading, refreshLoading, fetchDocumentos } = useDocument();
  const { goBack } = useNavigation();
  useBackKey(goBack);
  
  useFocusEffect(useCallback(() => { 
    fetchDocumentos(false);
  }, []));

  return (
    <Background>
      {documentos.length ? (
        <DocumentoList
          data={documentos}
          keyExtractor={documento => String(documento.id)}
          onRefresh={() => fetchDocumentos(true)}
          refreshing={refreshLoading}
          renderItem={({ item: documento }) => (
            <ItemDocumento 
              id={documento.id}
              acao={documento.acao}
              nome={documento.nome}
              arquivosCount={documento.arquivos.length}
            />
          )}
        />
      ) : (
        !loading && 
          <ListaVazia 
            descricao="Não existe solicitação para envio de documentos." 
          />
      )}
      <Loading show={loading} />
    </Background>
  );
};

export default ListaDocumentos;
