import React from 'react';
import { ImageBackground, ScrollView } from 'react-native';
import SafeAreaView from 'react-native-safe-area-view';
import Theme from '../../constants/Theme'

const BackgroundInicio: React.FC = ({ children }) => (
  <SafeAreaView style={{ flex: 1, backgroundColor: Theme.COLORS.BG_CARD_VERDE }}>
    <ImageBackground 
      source={require('../../../assets/background-inicio.png')} 
      style={{
        flex: 1,
        resizeMode: 'cover',
        justifyContent: 'center' 
      }}
      >
      <ScrollView 
        contentContainerStyle={{ 
          flexGrow: 1, 
          flexDirection: 'column', 
          justifyContent: 'space-between',
          paddingVertical: '20px',
          paddingHorizontal: '25px'
        }}
      >
        { children }
      </ScrollView>
    </ImageBackground>
  </SafeAreaView>
);

export default BackgroundInicio;
