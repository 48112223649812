import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import CadastrarScreen from '../pages/Cadastrar/index';
import EntrarScreen from '../pages/Entrar/index';
import LoginCodigoScreen from '../pages/LoginCodigo';
import RecuperarSenhaScreen from '../pages/RecuperarSenha';
import ConfirmarRecuperarSenha from '../pages/ConfirmarRecuperarSenha';
import TermosDeUsoScreen from '../pages/TermosDeUso';
import FeedbackScreen from '../pages/Feedback';
import LocalAtendimentoScreen from '../pages/LocalAtendimento';
import Theme from '../constants/Theme';

const InicioStack = createStackNavigator();

function InicioStackScreen() {
  return (
      <InicioStack.Navigator
        screenOptions={{
          headerTitleAllowFontScaling: false,
          headerStyle: {
            backgroundColor: Theme.COLORS.BG_HEADER,
          },
          headerTintColor: Theme.COLORS.TXT_HEADER,
          headerTitleStyle: {
            fontFamily: 'roboto-bold',
            alignSelf: 'center',
            marginLeft: -45
          },
        }}
      >
        <InicioStack.Screen
          name="Entrar"
          options={{ headerShown: false }} 
          component={EntrarScreen}
        />
        <InicioStack.Screen
          name="Cadastrar"
          options={{ headerShown: false }} 
          component={CadastrarScreen}
        />
        <InicioStack.Screen
          name="LoginCodigo"
          options={{ headerShown: false }} 
          component={LoginCodigoScreen}
        />
         <InicioStack.Screen
          name="RecuperarSenha"
          options={{ headerShown: false }} 
          component={RecuperarSenhaScreen}
        />
        <InicioStack.Screen
          name="ConfirmarRecuperarSenha"
          options={{ headerShown: false }} 
          component={ConfirmarRecuperarSenha}
        />
        <InicioStack.Screen
          name="TermosDeUso"
          component={TermosDeUsoScreen}
          options={{
            title:'Política de Privacidade',
          }}
        />
        <InicioStack.Screen
          name="Feedback"
          component={FeedbackScreen}
          options={{
            title:'Avalie o nosso aplicativo',
          }}
        />
        <InicioStack.Screen
          name="LocalAtendimento"
          component={LocalAtendimentoScreen}
          options={{ headerShown: false }} 
        />
      </InicioStack.Navigator>
  );
}

export default InicioStackScreen;
