import styled from 'styled-components/native';
import Theme from '../../constants/Theme';
import IconButton from 'react-native-vector-icons/Feather';

interface TituloFormProps {
  destacado?: boolean;
}

export const ContainerForm = styled.View``;

export const BackButton = styled.TouchableWithoutFeedback``;

export const Icon = styled(IconButton)`
 z-index: 10;
`;

export const ContainerTituloForm = styled.View`
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 15px;
`;

export const TituloForm = styled.Text<TituloFormProps>`
  font-size: 25px;
  font-family: ${props => props.destacado ? 'roboto-bold' : 'roboto-thin'};
  color: ${Theme.COLORS.TXT_LEITURA_BRANCO};
`;

export const ContainerCheckBox = styled.View`
  margin-top: 20px;
  flex-direction: row;
  padding-left: 20px;
`;

export const TextoCheckBox = styled.Text`
  font-size: 14px;
  font-family: 'roboto-thin';
  color: ${Theme.COLORS.TXT_LEITURA_BRANCO};
`;

export const ContainerTermos = styled.View`
  margin-top: 15px;
  padding-left: 20px;
`;

export const TextTermos = styled.Text`
  font-size: 14px;
  font-family: 'roboto-thin';
  color: ${Theme.COLORS.TXT_LEITURA_BRANCO};
`;

export const TextLinkTermos = styled.Text`
  font-size: 14px;
  font-family: 'roboto-bold';
  color: ${Theme.COLORS.TXT_LEITURA_DOURADO};
`;

export const LinkTermos = styled.TouchableWithoutFeedback``;

export const ContainerLogo = styled.View`
  margin-top: -25px;
`;

