export default {
  URLS: {
    SITE_DEFENSORIA: 'http://www.defensoria.ce.def.br/',
    CHATBOT: 'http://chatbot.defensoria.ce.def.br/',
    YOUTUBE_DEFENSORIA: 'https://www.youtube.com/channel/UCD55ThWdjyLSms5aguDI4rw',
    FACEBOOK_DEFENSORIA: 'https://www.facebook.com/DefensoriaCeara/',
    TWITTER_DEFENSORIA: 'https://twitter.com/defensoriaceara',
  },
  TEXTO_NOVO_USUARIO:
    'Olá! Bem vindo ao aplicativo Nossa Defensoria. Para a continuidade no uso do aplicativo, será necessário clicar em "Finalizar Cadastro".',
  ROUTES: {},
  IMAGES: {},
  ID_RG: '1',
  ID_CPF: '2',
  ID_ENDERECO: '3',
  NOME_RG: 'RG',
  NOME_CPF: 'CPF',
  NOME_ENDERECO: 'Comprovante de endereço',
  MSG_FINALIZAR_CADASTRO: 'Validar documentos cadastrais para acesso completo ao sistema',

  SOLICITACAO_ORIGEM_ASSISTIDO_ID: 1,
  SOLICITACAO_DSC_FINALIZAR_CAD: 'Finalizar Cadastro',
  SOLICITACAO_DSC_ORIENTACAO: 'Orientação',
  AVATAR:
    'https://mltmpgeox6sf.i.optimole.com/w:761/h:720/q:auto/https://redbanksmilesnj.com/wp-content/uploads/2015/11/man-avatar-placeholder.png',

  SLUG_DOC_INVALIDO: 'Inválido',
  ACAO_DOCUMENTO: {
    SOLICITAR_AO_ASSITIDO: 1,
    ANEXADO_PELO_ASSISTIDO: 2,
    ANEXADO_PELA_DPGE: 3,
    APROVADO: 4,
    REPROVADO: 5,
  },
  UPLOAD: {
    TAMANHO_MAX_MIB: 5
  },
  STATUS_AGENDAMENTO: {
    SOLICITADO: -2,
    CLASSIFICADO: -1,
    AGENDADO: 0,
    REALIZADO: 1,
    ASSISTIDO_NAO_COMPARECEU: 3,
    NAO_FOI_POSSIVEL_LOCALIZAR_ASSISTIDO: 4,
    CANCELADO: 5,
    REMARCADO: 6
  },
  TIPO_NOTIFICACAO: {
    ATENDIMENTOS: 1,
    DOCUMENTOS: 2,
    PROCESSOS: 3,
    OUTROS: 4,
    SOLICITACAO_FINALIZADA: 5
  },
  FORMA_ATENDIMENTO: {
    PRESENCIAL: 1,
    REMOTO: 2,
  }
};