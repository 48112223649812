import styled from 'styled-components/native';
import { FlatList } from 'react-native';
import { RectButton } from 'react-native-gesture-handler';
import IconButton from 'react-native-vector-icons/Feather';

import Theme from '../../../../constants/Theme';

import { Arquivo } from '../../../../types/Documento';


export const Titulo = styled.Text`
  font-family: 'roboto-bold';
  font-size: 20px;
  color: ${Theme.COLORS.TXT_LEITURA};
  text-align: center;
  max-width: 80%;
`;

export const TituloContainer = styled.View`
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
`;

export const ContainerAnexar = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const BotaoAnexar = styled(RectButton)`
  width: 100px;
  height: 40px;
  background: ${Theme.COLORS.BG_BTN_DEFAULT};
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`;

export const BotaoAnexarTexto = styled.Text`
  font-family: 'roboto-regular';
  color: ${Theme.COLORS.TXT_BTN_HOME};
  font-size: 14px;
`;

export const LabelAnexar = styled.Text`
  font-family: 'roboto-bold';
  font-size: 14px;
  color: ${Theme.COLORS.TXT_LEITURA};
`;

export const ArquivoList = styled(FlatList as new () => FlatList<Arquivo>)`
  padding: 15px;
  max-height: 450px;
  background-color: ${Theme.COLORS.BG_CARD_CINZA_CLARO};
`;

export const ContainerItemArquivo = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const LabelItemArquivo = styled.Text`
  font-family: 'roboto-bold';
  font-size: 14px;
  color: ${Theme.COLORS.TXT_LEITURA};
  max-width: 80%;
`;

export const BotaoDeletar = styled.TouchableWithoutFeedback`
  flex: 1;
`;

export const IconBotaoDeletar = styled(IconButton)``;

export const ContainerBotaoEntregar = styled.View`
  margin-top: 10px;
  margin-bottom: 20px;
`;