import React, { useEffect, useState } from 'react';
import { Container, Descricao} from './styles';
import Icon from 'react-native-vector-icons/Feather';
import * as Network from 'expo-network';

interface ListaVaziaProps {
  descricao: string;
  icone?: string;
}

const ListaVazia: React.FC<ListaVaziaProps> = ({ descricao, icone }) => {
  const [ noConnection, setNoConnection ] = useState(false);
  
  useEffect(() => {
    Network.getNetworkStateAsync().then((resp) => {
      if(resp && resp?.isConnected === false) {
        setNoConnection(true);
      }
    })
  },[])
  return (
    <Container>
      {noConnection ? (
        <>
          <Icon name='cloud-off' size={110} color='#292929' />
          <Descricao>Sem acesso a internet</Descricao>
       </>
      ) : (
        <>
          <Icon  
            name={icone ? icone : 'alert-circle'}
            size={110}
            color='#292929'
          />
          <Descricao>{ descricao }</Descricao>
        </>
      )}
    </Container>
  );
}

export default ListaVazia;