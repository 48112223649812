import React from 'react';
import { Image } from 'react-native';
import { Container } from './styles'


const LogoRodape: React.FC = () => (
  <Container>
    <Image
      resizeMode="contain"
      style={{ width: 244, height: 60 }}
      source={require('../../../assets/logo-horizontal-branca.png')}
    />
  </Container>
);

export default LogoRodape;