import styled from 'styled-components/native';
import { RectButton } from 'react-native-gesture-handler';
import Theme from '../../../../constants/Theme';

interface BotaoProps {
  btnSecundario?: boolean;
};

interface TextoTopoProps {
  bold?: boolean;
};

export const Container = styled.View`
  min-height: 160px;
  margin: 7px 0px;
  border-radius: 10px;
  background-color: ${Theme.COLORS.BG_CARD_VERDE};
  padding: 15px;
  justify-content: space-between;
`;

export const Titulo = styled.Text`
  font-family: 'roboto-regular';
  font-size: 12px;
  color: ${Theme.COLORS.TXT_LEITURA_BRANCO};
`;

export const Topo = styled.View`
`;

export const Rodape = styled.View`
  flex-direction: row;
`;

export const ContainerAcao = styled.View`
  justify-content: center;
  align-items: flex-end;
  flex: 1;
`;
export const ViewTextoTopo = styled.View`
  margin-bottom: 5px;
`;

export const TextoTopo = styled.Text<TextoTopoProps>`
  font-family: ${props => props.bold ? 'roboto-bold' : 'roboto-regular'};
  font-size: 16px;
  color: ${Theme.COLORS.TXT_LEITURA_BRANCO};
`;

export const Botao = styled(RectButton)<BotaoProps>`
  width: 180px;
  height: 40px;
  background: ${props => props.btnSecundario ? 'transparent' : Theme.COLORS.BG_BTN_DEFAULT};
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border: ${props => props.btnSecundario ? '1px solid #fff' : 'none'};
`;

export const ContainerBotoes = styled.View`
  align-items: flex-start;
  flex-direction: row;
`;

export const BotaoTexto = styled.Text`
  font-family: 'roboto-regular';
  color: ${Theme.COLORS.TXT_BTN_HOME};
  font-size: 14px;
`;
