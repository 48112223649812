import React from 'react';
import Moment from 'moment';
import DataCommon from '../../../../constants/DataCommon'
import {
  Container,
  Descricao,
  Topo,
  ContainerTexto,
  ContainerTextoTopo,
  Rodape,
  Meio,
  Label,
  ContainerBotao,
  BotaoTexto,
  Principal
} from './styles';

interface ItemListaProps {
  id: number;
  data_agendamento: string;
  hora_agendamento: string;
  status: number;
  lotacao_fk: { 
    defensoria: string; 
    nucleo_txt: string;
    nucleo_fk: {
      tel: string;
      endereco: string;
    }
  };
  acao_tipo_fk: {
    titulo: string;
  };
  processo_fk: {
    num_protocolo: string;
  };
  solicitacao_agendamento_fk: {
    forma_atendimento: number,
    descricao_forma_atendimento: string
  };
  acaoCancelar: Function;
  acaoRemarcar: Function;
};

const obterFormaAtendimento = (forma:number) => {
  const formaAtendimento = {
    [DataCommon.FORMA_ATENDIMENTO.PRESENCIAL]: 'Presencial',
    [DataCommon.FORMA_ATENDIMENTO.REMOTO]: 'Remoto',
  }

  return formaAtendimento[forma] || 'Sem forma de atendimento';
};

const ItemLista: React.FC<ItemListaProps> = ({ 
  id,
  data_agendamento,
  hora_agendamento,
  status,
  lotacao_fk,
  acao_tipo_fk,
  processo_fk,
  solicitacao_agendamento_fk,
  acaoCancelar,
  acaoRemarcar,
}) => (
  <Container ativo={status === DataCommon.STATUS_AGENDAMENTO.AGENDADO}>
    <Principal>
      <ContainerTexto>
          <Label>{ acao_tipo_fk.titulo }</Label>
      </ContainerTexto>
      <Topo>
        <ContainerTextoTopo>
          <Label>Data: <Descricao>{Moment(data_agendamento).format('DD/MM/YYYY')}</Descricao></Label>
        </ContainerTextoTopo> 
        <ContainerTextoTopo>
          <Label> Protocolo: <Descricao>{processo_fk?.num_protocolo}</Descricao></Label>
        </ContainerTextoTopo>
      </Topo>
      <Meio>
        <ContainerTexto>
          <Label>Hora: <Descricao>{ hora_agendamento }</Descricao></Label>
        </ContainerTexto>
        <ContainerTexto>
          <Label>Local: <Descricao>{ lotacao_fk?.nucleo_txt }</Descricao></Label>
        </ContainerTexto>
        {solicitacao_agendamento_fk?.forma_atendimento !== DataCommon.FORMA_ATENDIMENTO.REMOTO && 
          <ContainerTexto>
            <Label>Endereço: <Descricao>{ lotacao_fk?.nucleo_fk.endereco }</Descricao></Label>
          </ContainerTexto>
        }
        <ContainerTexto>
          <Label>Telefone: <Descricao>{ lotacao_fk?.nucleo_fk.tel }</Descricao></Label>
        </ContainerTexto>
        {solicitacao_agendamento_fk?.forma_atendimento && 
          <ContainerTexto>
            <Label>Forma de atendimento: <Descricao>{ obterFormaAtendimento(solicitacao_agendamento_fk?.forma_atendimento) }</Descricao></Label>
          </ContainerTexto>
        }
        {solicitacao_agendamento_fk?.descricao_forma_atendimento && 
          <ContainerTexto>
            <Label>Detalhes: <Descricao>{ solicitacao_agendamento_fk?.descricao_forma_atendimento }</Descricao></Label>
          </ContainerTexto>
        }
        {status === DataCommon.STATUS_AGENDAMENTO.AGENDADO ? (
            <ContainerTexto>
              <Label>Situação: <Descricao>EM ABERTO</Descricao></Label>
            </ContainerTexto>
          ) : (
            <ContainerTexto>
              <Label>Situação: <Descricao>ENCERRADO</Descricao></Label>
            </ContainerTexto>
          )
        }
      </Meio>
    </Principal>
    {status === DataCommon.STATUS_AGENDAMENTO.AGENDADO &&
      <Rodape>
        <ContainerBotao onPress={() => acaoRemarcar(id)}>
          <BotaoTexto>Remarcar</BotaoTexto>
        </ContainerBotao>
        <ContainerBotao btnSecundario onPress={() => acaoCancelar(id)}>
          <BotaoTexto>Cancelar</BotaoTexto>
        </ContainerBotao>
      </Rodape>
    }
  </Container>
);

export default ItemLista;