import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useDocument } from '../../../../contexts/document';

import {
  Container,
  Cabecalho,
  Titulo,
  Rodape,
  Topo,
  Botao,
  BotaoTexto,
  ContainerAcao,
  ContainerBotoes,
  TextoAcao,
  LabelAcao,
  ViewTextoTopo,
  BlocoBotao,
  TextoTipos,
} from './styles';

import DataCommon from '../../../../constants/DataCommon';

interface ItemDocumento {
  id: number;
  acao: number;
  nome: string;
  arquivosCount: number;
}

const obterStatus = (stat:number) => {
  const status = {
    [DataCommon.ACAO_DOCUMENTO.SOLICITAR_AO_ASSITIDO]: 'Pendente',
    [DataCommon.ACAO_DOCUMENTO.ANEXADO_PELO_ASSISTIDO]: 'em análise',
    [DataCommon.ACAO_DOCUMENTO.ANEXADO_PELA_DPGE]: 'Anexado pela DPGE',
    [DataCommon.ACAO_DOCUMENTO.APROVADO]: 'Aprovado',
    [DataCommon.ACAO_DOCUMENTO.REPROVADO]: 'Reprovado',
  }

  return status[stat] || 'Sem status';
};

const acoesBloqueadas = [ 
  DataCommon.ACAO_DOCUMENTO.APROVADO,
  DataCommon.ACAO_DOCUMENTO.ANEXADO_PELA_DPGE,
  DataCommon.ACAO_DOCUMENTO.ANEXADO_PELO_ASSISTIDO,
];


const ItemDocumento: React.FC<ItemDocumento> = ({ id, acao, nome, arquivosCount }) => {

  const { setDocumentoSelecionadoId } = useDocument();
  const { navigate } = useNavigation();

  const handleBotaoAnexar = (id:number) => {
    setDocumentoSelecionadoId(id);
    navigate('AnexarDocumentos')
  }

  return (
    <Container acao={acao}>
      <Topo>
        <ViewTextoTopo>
          <Cabecalho>{nome}</Cabecalho>
        </ViewTextoTopo>
        {arquivosCount > 0 && acao === DataCommon.ACAO_DOCUMENTO.SOLICITAR_AO_ASSITIDO &&
          <ViewTextoTopo>
            {arquivosCount > 1 ? (
              <Titulo> Você anexou {arquivosCount} arquivos que estão pendentes de entrega.</Titulo>
            ) : (
              <Titulo> Você anexou {arquivosCount} arquivo que está pendente de entrega.</Titulo>
            )}
          </ViewTextoTopo>
        }
      </Topo>
      <Rodape>
          <ContainerBotoes>
            {!acoesBloqueadas.includes(acao) && 
              <BlocoBotao>
                <Botao onPress={() => handleBotaoAnexar(id)}>
                  <BotaoTexto>Anexar</BotaoTexto>
                </Botao>
                <TextoTipos>JPEG, PNG OU PDF</TextoTipos>
              </BlocoBotao>
            }
            <ContainerAcao>
              <LabelAcao>Status: <TextoAcao acao={acao}>{obterStatus(acao)}</TextoAcao> </LabelAcao>
            </ContainerAcao>
          </ContainerBotoes>
      </Rodape>
    </Container>
  );
}

export default ItemDocumento;